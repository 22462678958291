import { createThunkAction } from '../../thunks/actions';

export const targetList = createThunkAction('targets/list', 'targets/list/');
export const targetAdd = createThunkAction('targets/add', 'targets/add/', 'post');
export const targetDetails = createThunkAction('targets/details', (id) => `targets/detail/${id}/`);
export const targetEdit = createThunkAction('targets/edit', (id) => `targets/detail/${id}/update/`, 'put');
export const targetDelete = createThunkAction('targets/delete', 'targets/delete/', 'delete');
export const targetTypeOptions = createThunkAction('targets/type/options', 'system/simple-serializer/target-type-translatted/');
export const targetMain = createThunkAction('targets/main', 'targets/list/?is_main=1');
export const targetRefresh = createThunkAction('targets/refresh', 'targets/refresh/', 'post');
