import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dynamicUrlActionDetails } from '../../../services/actions/dynamicUrl/actions';
import { List, ListItem, ListItemText, Typography, Divider } from '@mui/material';

const renderListFromObject = (data) => {
    if (!data || typeof data !== 'object') {
        return <Typography color="error">Invalid data</Typography>;
    }

    const renderValue = (key, value) => {
        if (Array.isArray(value)) {
            // Obsługa tablic
            return (
                <List dense>
                    {value.map((item, index) => (
                        <ListItem key={index}>
                            {typeof item === 'object' ? (
                                renderListFromObject(item) // Rekurencyjne renderowanie dla obiektów w tablicy
                            ) : (
                                <ListItemText primary={`${key} [${index}]`} secondary={String(item)} />
                            )}
                        </ListItem>
                    ))}
                </List>
            );
        }

        if (typeof value === 'object' && value !== null) {
            // Obsługa zagnieżdżonych obiektów
            return renderListFromObject(value);
        }

        // Obsługa prostych wartości
        return String(value);
    };

    return (
        <List>
            {Object.entries(data).map(([key, value]) => (
                <React.Fragment key={key}>
                    <ListItem>
                        <ListItemText primary={<Typography fontWeight="bold">{key}</Typography>} secondary={renderValue(key, value)} />
                    </ListItem>
                    <Divider />
                </React.Fragment>
            ))}
        </List>
    );
};

const replaceSeparatorInUrl = (row, data) => {
    const { url, separator } = data;

    if (row && separator in row) {
        return url.replace(`{{${separator}}}`, row[separator]);
    }

    return url;
};

const DynamicUrlDetails = ({ data, token }) => {
    const dispatch = useDispatch();
    const { data: details, loading } = useSelector((state) => state.dynamicUrl.actionDetails);

    useEffect(() => {
        if (data?.row && data?.data) {
            const updatedUrl = replaceSeparatorInUrl(data.row, data.data);

            dispatch(
                dynamicUrlActionDetails({
                    id: updatedUrl,
                    isAuth: data.data.auth_method === 'token',
                    token: token
                })
            );
        }
    }, [data, dispatch, token]);

    if (loading) return 'Loading...';

    if (!details || typeof details !== 'object') {
        return <Typography color="error">No details available</Typography>;
    }

    return renderListFromObject(details);
};

export default DynamicUrlDetails;
