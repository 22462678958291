import { createSlice } from '@reduxjs/toolkit';
import { createInitialState } from '../../../utils/initialState';
import { createSubReducer } from '../../../utils/createReducer';
import { marginAdd, marginDelete, marginDetails, marginEdit, marginGlobalDetails, marginList } from '../../../actions/margin/actions';

const initialState = {
    add: createInitialState(),
    edit: createInitialState(),
    details: createInitialState({ loading: true }),
    globalDetails: createInitialState({ loading: true }),
    list: createInitialState({ data: [] }),
    delete: createInitialState()
};

const marginSlice = createSlice({
    name: 'margin',
    initialState,
    reducers: {
        resetMarginState: (state, action) => {
            const { key } = action.payload;
            state[key] = initialState[key];
        }
    },
    extraReducers: (builder) => {
        const actionsMap = {
            add: marginAdd,
            edit: marginEdit,
            details: marginDetails,
            globalDetails: marginGlobalDetails,
            list: marginList,
            delete: marginDelete
        };

        Object.keys(actionsMap).forEach((key) => {
            createSubReducer(builder, actionsMap[key], key);
        });
    }
});

export const { resetMarginState } = marginSlice.actions;
export default marginSlice.reducer;
