import PropTypes from 'prop-types';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import StandardPopup from '../popup/StandardPopup';
import { Cancel, Delete } from '@mui/icons-material';

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': { padding: '4px 0' },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5)
            },
            '&:active': {
                backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
            }
        }
    }
}));

export default function ActionTextButton({ data, variant = 'contained', size = 'small', label = 'Actions', param }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [deleteAction, setDeleteAction] = useState(null);
    const dispatch = useDispatch();
    const open = Boolean(anchorEl);

    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const handleOpenDeletePopup = (action) => {
        setDeleteAction(() => action);
        setOpenDeletePopup(true);
    };
    const handleCloseDeletePopup = () => setOpenDeletePopup(false);
    const handleActionDelete = () => {
        dispatch(deleteAction());
        handleCloseDeletePopup();
    };

    const handleActionClick = (option) => {
        if (!option.path) {
            switch (option.type) {
                case 'api':
                    dispatch(option.action());
                    break;
                case 'delete':
                    handleOpenDeletePopup(option.action);
                    break;
                case 'group':
                    option.action(param);
                    break;
                default:
                    option.action();
                    break;
            }
        }
        handleClose();
    };

    return (
        <>
            <div>
                <Button
                    id="demo-customized-button"
                    aria-controls={open ? 'demo-customized-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open}
                    variant={variant}
                    disableElevation
                    onClick={handleClick}
                    endIcon={<KeyboardArrowDownIcon />}
                    size={size}
                >
                    {label}
                </Button>
                <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{ 'aria-labelledby': 'demo-customized-button' }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    {data.map((item, index) => (
                        <MenuItem
                            key={index}
                            component={item.path ? Link : 'div'}
                            to={item.path || ''}
                            onClick={() => {
                                handleActionClick(item);
                                handleClose();
                            }}
                            disabled={item.isDisabled}
                            disableRipple
                        >
                            {item.icon}
                            {item.label}
                        </MenuItem>
                    ))}
                </StyledMenu>
            </div>
            <StandardPopup
                close={handleCloseDeletePopup}
                open={openDeletePopup}
                title="Confirm the operation"
                content="Are you sure you want to delete this resource?"
                icon1={<Cancel />}
                icon2={<Delete />}
                label1="Cancel"
                label2="Delete"
                request1={handleCloseDeletePopup}
                request2={handleActionDelete}
                maxWidth="sm"
            />
        </>
    );
}

ActionTextButton.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            path: PropTypes.string,
            action: PropTypes.func,
            icon: PropTypes.element,
            label: PropTypes.string,
            type: PropTypes.string
        })
    ).isRequired
};
