import React, { useEffect } from 'react';
import { Grid, Card, CardContent, CardMedia, Typography, Container, Box, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { helpCenterList } from '../../../services/actions/other/otherActions';

const InstructionsList = () => {
    const dispatch = useDispatch();
    const { data, loading } = useSelector((state) => state.other.helpCenterList);

    useEffect(() => {
        dispatch(helpCenterList());
    }, [dispatch]);

    if (loading) {
        return 'Loading...';
    }

    return (
        <Box pt={6} pb={6}>
            <Container maxWidth="lg" sx={{ zIndex: 9, position: 'relative' }}>
                <Grid container spacing={4}>
                    {data.map((item, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Box
                                sx={{
                                    transition: 'all 0.2s ease-in-out 0s',
                                    '&:hover': {
                                        transform: 'translateY(-4px)'
                                    }
                                }}
                            >
                                <Link style={{ textDecoration: 'none' }} to={`/help-center/${item.news_url}`}>
                                    <Card sx={{ transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms', textDecoration: 'none' }}>
                                        <CardMedia
                                            component="div"
                                            sx={{ backgroundImage: `url(${item.image})`, height: 140 }}
                                            alt={item.image_alt}
                                        />
                                        <CardContent>
                                            <Stack spacing={1}>
                                                <Stack direction="row" justifyContent="space-between">
                                                    <Typography variant="subtitle1">{item.title}</Typography>{' '}
                                                    <Typography variant="subtitle2">
                                                        {item.read_time ? `${item.read_time} minut czytania` : item.read_time}
                                                    </Typography>
                                                </Stack>
                                                <Typography variant="body2" color="text.secondary">
                                                    <div dangerouslySetInnerHTML={{ __html: item.short_text }} />
                                                </Typography>
                                            </Stack>
                                        </CardContent>
                                    </Card>
                                </Link>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    );
};

export default InstructionsList;
