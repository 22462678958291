import { Box, IconButton } from '@mui/material';
import { IconHelp } from '@tabler/icons-react';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { helpCenterCheckUrl } from '../../../../../services/actions/other/otherActions';
import { useSelector } from 'react-redux';
import { fetchReset } from '../../../../../services/actions';
import { HELP_CENTER_CHECK_URL } from '../../../../../services/actions/other/otherActionsType';

const HelpCenter = () => {
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { data, success } = useSelector((state) => state.other.helpCenterCheckUrl);

    const handleCheckUrl = () => {
        dispatch(helpCenterCheckUrl(pathname));
    };

    useEffect(() => {
        if (success && data?.is_news_url) {
            dispatch(fetchReset(HELP_CENTER_CHECK_URL));
            navigate(`/help-center/${data?.news_url}`);
        }
    }, [data?.is_news_url, data?.news_url, dispatch, navigate, success]);

    return (
        <Box sx={{ flexShrink: 0, ml: 0.75 }} data-tour="step-15">
            <IconButton sx={{ color: 'text.primary' }} onClick={handleCheckUrl}>
                <IconHelp stroke={1.5} size="1.2rem" />
            </IconButton>
        </Box>
    );
};

export default HelpCenter;
