import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import InputLabel from '@mui/material/InputLabel';
import { Stack, FormHelperText, useTheme, alpha, IconButton } from '@mui/material';
import Select, { components } from 'react-select';
import VisibilityIcon from '@mui/icons-material/Visibility';

const IndicatorsContainer = ({ children, value, isDetails, ...props }) => {
    const handleNavigate = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (value && value.value) {
            window.open(`/sales/report/${value.value}/details/information`, '_blank');
        }
    };

    return (
        <components.IndicatorsContainer {...props}>
            {isDetails && (
                <IconButton
                    aria-label="view details"
                    onKeyDown={(e) => handleNavigate(e)}
                    onMouseDown={(e) => handleNavigate(e)}
                    onTouchEnd={(e) => handleNavigate(e)}
                >
                    <VisibilityIcon />
                </IconButton>
            )}

            {children}
        </components.IndicatorsContainer>
    );
};

const SearchableSelect = ({
    components,
    disabled,
    isLoading = false,
    isClearable = true,
    isSearchable = true,
    label,
    name,
    options,
    handleChange,
    menuListStyle = {},
    isDetails = false,
    placeholder,
    width = '100%'
}) => {
    const [focused, setFocused] = useState(false);
    const [field, meta, helpers] = useField(name);
    const { value } = field;
    const { touched, error } = meta;
    const { setValue, setTouched } = helpers;

    const theme = useTheme();
    const mode = theme.palette.mode;

    const styles = {
        valueContainer: (base) => ({
            ...base,
            minHeight: 39
        }),
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        menuList: (base) => ({
            ...base,
            ...menuListStyle,
            background: mode === 'light' ? 'white' : 'black',
            backgroundImage: mode === 'dark' && 'linear-gradient(rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15))'
        }),
        control: (base, state) => ({
            ...base,
            backgroundColor: 'transparent',
            borderColor: touched && error?.value ? 'red' : mode === 'dark' ? theme.palette.grey[600] : theme.palette.grey[300],
            '&:hover': {
                borderColor: state.isFocused
                    ? touched && error?.value
                        ? 'red'
                        : theme.palette.primary.light
                    : touched && error?.value
                      ? 'red'
                      : theme.palette.grey[300]
            },
            boxShadow: state.isFocused ? `0 0 0 2px ${alpha(theme.palette.primary.main, 0.2)}` : 'none'
        }),
        option: (base, { isFocused, isSelected }) => {
            return {
                ...base,
                backgroundColor: isFocused
                    ? mode === 'dark'
                        ? 'rgba(255, 255, 255, 0.08)'
                        : theme.palette.grey[100]
                    : isSelected
                      ? mode === 'dark'
                          ? 'rgba(255, 255, 255, 0.16)'
                          : theme.palette.primary.lighter
                      : null,
                color: 'inherit'
            };
        },
        input: (styles) => ({ ...styles, color: 'inherit' }),
        singleValue: (styles) => ({ ...styles, color: 'inherit' })
    };

    const handleChangeEvent = (selectedOption) => {
        setValue(selectedOption || { value: null, label: null });
        if (handleChange) handleChange(selectedOption || { value: null, label: null });
    };

    const handleFocus = () => setFocused(true);
    const handleBlur = () => {
        setFocused(false);
        setTouched(true);
    };

    return (
        <Stack spacing={1} sx={{ width: width }}>
            {label && (
                <InputLabel htmlFor={`${name}-select`} error={Boolean(touched && error?.value)} sx={{ color: focused && 'primary.darker' }}>
                    {label}
                </InputLabel>
            )}

            <Select
                id={`${name}-select`}
                aria-label={`${name}-searchable-select`}
                className={`${name}-searchable-select`}
                classNamePrefix="select"
                components={{
                    ...components,
                    IndicatorsContainer: (props) => <IndicatorsContainer {...props} isDetails={isDetails} value={value} />
                }}
                isClearable={isClearable}
                isDisabled={disabled}
                isLoading={isLoading}
                isSearchable={isSearchable}
                menuPortalTarget={document.body}
                name={name}
                onBlur={handleBlur}
                onChange={handleChangeEvent}
                onFocus={handleFocus}
                options={options}
                styles={styles}
                value={value?.value === null ? null : value}
                placeholder={placeholder}
            />
            {label && touched && error?.value && (
                <FormHelperText error id={`${name}-error`}>
                    {error.value}
                </FormHelperText>
            )}
        </Stack>
    );
};

SearchableSelect.propTypes = {
    components: PropTypes.any,
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    isClearable: PropTypes.bool,
    isSearchable: PropTypes.bool,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    onInputChange: PropTypes.func,
    handleChange: PropTypes.func
};

export default SearchableSelect;
