import { initialAuthState } from './initialAuthState';
import { initialPostState } from './initialPostState';
import { initialState } from './initialState';

export const globalReducer = (startType, successType, failType, resetType) => {
    return (state = initialState, action) => {
        switch (action.type) {
            case startType:
                return { ...state, loading: true, success: false };
            case successType:
                return { loading: false, data: action.data, error: null, success: true };
            case failType:
                return { loading: false, data: [], error: action.error, success: false };
            case resetType:
                return { loading: false, data: [], error: action.error, success: false };
            default:
                return state;
        }
    };
};

export const authReducer = (startType, successType, failType, resetType) => {
    return (state = initialAuthState, action) => {
        switch (action.type) {
            case startType:
                return { ...state, loading: true, success: false };
            case successType:
                return {
                    loading: false,
                    data: action.data,
                    error: null,
                    success: true,
                    token: action.data.token,
                    isOtpEnabled: action.data.otp_enabled
                };
            case failType:
                return { loading: false, data: [], error: action.error, success: false, token: null };
            case resetType:
                return { loading: false, data: [], error: action.error, success: false, token: null };
            default:
                return state;
        }
    };
};

export const globalPostReducer = (startType, successType, failType, resetType) => {
    return (state = initialPostState, action) => {
        switch (action.type) {
            case startType:
                return { ...state, loading: true, success: false };
            case successType:
                return { loading: false, data: action.data, error: null, success: true };
            case failType:
                return { loading: false, data: [], error: action.error, success: false };
            case resetType:
                return { loading: false, data: [], error: action.error, success: false };
            default:
                return state;
        }
    };
};

export const globalGetReducer = (startType, successType, failType) => {
    return (state = initialState, action) => {
        switch (action.type) {
            case startType:
                return { ...state, loading: true, success: false };
            case successType:
                return { loading: false, data: action.data, error: null, success: true };
            case failType:
                return { loading: false, data: [], error: action.error, success: false };
            default:
                return state;
        }
    };
};
