import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { useDispatch, useSelector } from 'react-redux';
import { favoritesPage } from './favoritesPages';
import { matchPath } from '../../utils/hooks';
import { siteUrlFavouriteAdd, siteUrlFavouriteCheck } from '../../services/actions/siteUrl/actions';

const FavoritePage = ({ url }) => {
    const [hovered, setHovered] = useState(false);
    const [isFavourite, setIsFavourite] = useState(false);

    const dispatch = useDispatch();
    const { data } = useSelector((state) => state.siteUrl.favouriteCheck);
    const { success: isPageAddToFavourite } = useSelector((state) => state.siteUrl.favouriteAdd);

    const favoritePaths = Object.values(favoritesPage)
        .flat()
        .map((page) => page.url);

    const isMatchPage = favoritePaths.some((path) => matchPath(path, url));

    useEffect(() => {
        if (isMatchPage) {
            dispatch(siteUrlFavouriteCheck({ id: url }));
        }
    }, [dispatch, isMatchPage, url, isPageAddToFavourite]);

    useEffect(() => {
        if (data?.url === url) {
            setIsFavourite(data?.check);
        }
    }, [data, url]);

    const handleClick = () => {
        setIsFavourite((prev) => !prev);
        dispatch(siteUrlFavouriteAdd({ id: url }));
    };

    if (!isMatchPage) return null;

    return (
        <Box
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            onClick={handleClick}
            sx={{
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer'
            }}
        >
            {hovered || isFavourite ? (
                <FavoriteIcon fontSize="small" color="primary" />
            ) : (
                <FavoriteBorderIcon fontSize="small" color="primary" />
            )}
        </Box>
    );
};

export default FavoritePage;
