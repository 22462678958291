import { Box, IconButton } from '@mui/material';
import { IconSearch } from '@tabler/icons-react';
import BasePopup from '../../../../../components/ui-component/popup/BasePopup';
import { useState } from 'react';
import SearchingList from './list';

const Search = () => {
    const [open, setOpen] = useState(false);

    const handleClosePopup = () => setOpen(false);

    const handleOpenPopup = () => setOpen(true);

    return (
        <>
            <Box sx={{ ml: { xs: 1, md: 1 }, mr: { xs: 1, md: 1 } }}>
                <IconButton
                    data-tour="step-1"
                    sx={{ color: 'text.primary' }}
                    aria-label="open search"
                    aria-controls={'search-grow'}
                    aria-haspopup="true"
                    onClick={handleOpenPopup}
                >
                    <IconSearch stroke={1.5} size="1.2rem" />
                </IconButton>
            </Box>
            <BasePopup
                open={open}
                close={handleClosePopup}
                title={'Add connection'}
                content={<SearchingList handleClosePopup={handleClosePopup} />}
                maxWidth={'sm'}
            />
        </>
    );
};

export default Search;
