import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { FormControl, FormControlLabel, Switch, Stack } from '@mui/material';

const BaseSwitch = ({ label, name, isDisabled = false, handleChange }) => {
    const [field] = useField(name);
    const { value, onChange } = field;

    const handleChangeEvent = (event) => {
        onChange(event);
        if (handleChange) handleChange(event);
    };

    return (
        <FormControl component="fieldset" variant="standard">
            <Stack direction="row" alignItems="center" spacing={1}>
                <FormControlLabel
                    sx={{ marginLeft: 0, alignItems: 'flex-start' }}
                    control={<Switch checked={value} onChange={handleChangeEvent} name={name} disabled={isDisabled} />}
                    label={label}
                    labelPlacement="top"
                />
            </Stack>
        </FormControl>
    );
};

BaseSwitch.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool,
    handleChange: PropTypes.func
};

export default BaseSwitch;
