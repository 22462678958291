import { combineReducers } from 'redux';
import {
    orderAddReducer,
    orderDeleteReducer,
    orderDetailsReducer,
    orderEditReducer,
    orderListReducer,
    orderProductsUnitListReducer
} from './orderReducers';

const orderReducer = combineReducers({
    list: orderListReducer,
    productsUnitList: orderProductsUnitListReducer,
    add: orderAddReducer,
    delete: orderDeleteReducer,
    details: orderDetailsReducer,
    edit: orderEditReducer
});

export default orderReducer;
