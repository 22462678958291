import { createSlice } from '@reduxjs/toolkit';
import { createInitialState } from '../../../utils/initialState';
import { createSubReducer } from '../../../utils/createReducer';
import {
    authCheckState,
    authSetToken,
    forgotPassword,
    login,
    logout,
    otpDisable,
    otpEnable,
    otpSetBool,
    otpValidate,
    otpVerify,
    register,
    resetPassword,
    userChangePassword,
    userGoogleDetails,
    userGoogleLogin,
    userGoogleStart
} from '../../../actions/auth/actions';

const initialState = {
    token: null,
    login: createInitialState(),
    checkState: createInitialState({ loading: true }),
    logout: createInitialState(),
    register: createInitialState(),
    forgotPassword: createInitialState(),
    resetPassword: createInitialState(),
    otpEnable: createInitialState(),
    otpDisable: createInitialState(),
    otpVerify: createInitialState(),
    otpValidate: createInitialState(),
    userChangePassword: createInitialState(),
    userGoogleStart: createInitialState(),
    userGoogleDetails: createInitialState(),
    userGoogleLogin: createInitialState(),
    isOtpEnable: false
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        resetAuthState: (state, action) => {
            const { key } = action.payload;
            state[key] = initialState[key];
        }
    },
    extraReducers: (builder) => {
        const actionsMap = {
            login: login,
            checkState: authCheckState,
            logout: logout,
            register: register,
            forgotPassword: forgotPassword,
            resetPassword: resetPassword,
            otpEnable: otpEnable,
            otpDisable: otpDisable,
            otpVerify: otpVerify,
            otpValidate: otpValidate,
            userChangePassword: userChangePassword,
            userGoogleStart: userGoogleStart,
            userGoogleDetails: userGoogleDetails,
            userGoogleLogin: userGoogleLogin
        };
        builder.addCase(authSetToken, (state, action) => {
            state.token = action.payload;
        });
        builder.addCase(otpSetBool, (state, action) => {
            state.isOtpEnable = action.payload;
        });
        Object.keys(actionsMap).forEach((key) => {
            createSubReducer(builder, actionsMap[key], key);
        });
    }
});

export const { resetAuthState } = authSlice.actions;

export default authSlice.reducer;
