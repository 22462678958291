import { Add, Delete, Edit } from '@mui/icons-material';
import DescriptionTwoToneIcon from '@mui/icons-material/DescriptionTwoTone';
import {
    IconAccessPoint,
    IconChartArcs,
    IconChartInfographic,
    IconInfoSquareRounded,
    IconLicense,
    IconLock,
    IconMessageCircle,
    IconNotification,
    IconPackage,
    IconPackages,
    IconPlugConnected,
    IconReport,
    IconStack2,
    IconTemplate,
    IconUser,
    IconTarget,
    IconCoins,
    IconBrandWindy,
    IconLoader,
    IconBuildingStore
} from '@tabler/icons-react';
import ClearIcon from '@mui/icons-material/Clear';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useTheme } from '@mui/material';

export const getModelIcon = (type) => {
    switch (type) {
        case 'order':
            return <IconPackage />;
        case 'messages':
            return <IconMessageCircle />;
        case 'product':
            return <IconStack2 />;
        default:
            return <DescriptionTwoToneIcon />;
    }
};

export const getModelTypeIcon = (type) => {
    switch (type) {
        case 'new':
            return <Add sx={{ fontSize: '1rem' }} />;
        case 'edit':
            return <Edit sx={{ fontSize: '0.8rem' }} />;
        case 'delete':
            return <Delete sx={{ fontSize: '0.9rem' }} />;
        case 'low_amount':
            return <NotificationsIcon sx={{ fontSize: '0.9rem' }} />;
        default:
            return <ClearIcon sx={{ fontSize: '1rem' }} />;
    }
};

export const getModelTypeColor = (type) => {
    switch (type) {
        case 'new':
            return 'success.main';
        case 'edit':
            return 'info.main';
        case 'delete':
            return 'error.main';
        case 'low_amount':
            return 'warning.main';
        default:
            return 'info.main';
    }
};

const getIconColor = (type) => {
    switch (type) {
        case 'account':
            return 'primary.main';
        case 'chat':
            return 'secondary.main';
        case 'delivery':
            return 'error.main';
        case 'dashboard':
            return 'warning.main';
        case 'product':
            return 'info.main';
        case 'sales':
            return 'success.main';
        case 'statistics':
            return 'pink.main';
        case 'status':
            return 'purple.main';
        default:
            return 'primary.main';
    }
};

export const ShortcutIcon = ({ model, group }) => {
    const icon = useModelSearchIcon(model, group);
    return icon;
};

export const useModelSearchIcon = (type, group) => {
    const theme = useTheme();
    const iconColor = getIconColor(group);

    const [colorGroup, colorShade] = iconColor.split('.');
    const colorValue = theme.palette[colorGroup]?.[colorShade];

    switch (type) {
        case 'info':
            return <IconInfoSquareRounded color={colorValue} />;
        case 'order':
            return <IconPackage color={colorValue} />;
        case 'notification':
            return <IconNotification color={colorValue} />;
        case 'analysis':
            return <IconChartArcs color={colorValue} />;
        case 'statistics':
            return <IconChartInfographic color={colorValue} />;
        case 'product':
            return <IconStack2 color={colorValue} />;
        case 'warehouse':
            return <IconPackages color={colorValue} />;
        case 'status':
            return <IconAccessPoint color={colorValue} />;
        case 'chat':
            return <IconMessageCircle color={colorValue} />;
        case 'report':
            return <IconReport color={colorValue} />;
        case 'template':
            return <IconTemplate color={colorValue} />;
        case 'account':
            return <IconUser color={colorValue} />;
        case 'security':
            return <IconLock color={colorValue} />;
        case 'license':
            return <IconLicense color={colorValue} />;
        case 'connections':
            return <IconPlugConnected color={colorValue} />;
        case 'targets':
            return <IconTarget color={colorValue} />;
        case 'costs':
            return <IconCoins color={colorValue} />;
        case 'forecast':
            return <IconBrandWindy color={colorValue} />;
        case 'soa':
            return <IconLoader color={colorValue} />;
        case 'marketWatcher':
            return <IconBuildingStore color={colorValue} />;
        default:
            return <DescriptionTwoToneIcon color={colorValue} />;
    }
};
