import { lazy } from 'react';
import Loadable from '../../components/Loadable';

const ColeSpreadsheet = Loadable(lazy(() => import('./../../components/cole-spreadsheet/index')));
const ColeList = Loadable(lazy(() => import('../../pages/cole/list')));
const ColeAdd = Loadable(lazy(() => import('../../pages/cole/add')));
const ColeEdit = Loadable(lazy(() => import('../../pages/cole/edit')));
const ColeBaseList = Loadable(lazy(() => import('../../pages/cole/list/base')));
const ColeDynamicList = Loadable(lazy(() => import('../../pages/cole/list/dynamic')));
const ColeDetailsDynamic = Loadable(lazy(() => import('../../pages/cole/details/dynamic')));
const ColeDetailsDynamicInformation = Loadable(lazy(() => import('../../pages/cole/details/dynamic/information')));

export const ColeRoutes = [
    {
        path: 'cole',
        children: [
            {
                path: '',
                element: <ColeSpreadsheet />
            },
            {
                path: 'list',
                element: <ColeList />,
                children: [
                    { path: 'template', element: <ColeBaseList /> },
                    { path: 'dynamic', element: <ColeDynamicList /> }
                ]
            },
            {
                path: 'add',
                element: <ColeAdd />
            },
            {
                path: ':id/edit',
                element: <ColeEdit />
            },
            {
                path: ':id/edit',
                element: <ColeEdit />
            },
            {
                path: 'dynamic/:id/details',
                element: <ColeDetailsDynamic />,
                children: [{ path: 'information', element: <ColeDetailsDynamicInformation /> }]
            }
        ]
    }
];
