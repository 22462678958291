import React from 'react';
import { Box, FormControl, InputAdornment, OutlinedInput } from '@mui/material';
import { IconSearch } from '@tabler/icons-react';

const SearchBar = ({ query, setQuery }) => {
    return (
        <Box sx={{ width: '100%' }} p={2}>
            <FormControl sx={{ width: '100%' }}>
                <OutlinedInput
                    size="small"
                    id="header-search"
                    startAdornment={
                        <InputAdornment position="start" sx={{ mr: -0.5 }}>
                            <IconSearch stroke={1.5} size="1.2rem" />
                        </InputAdornment>
                    }
                    aria-describedby="header-search-text"
                    inputProps={{
                        'aria-label': 'weight'
                    }}
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder="Search..."
                    value={query}
                    sx={{ height: '45px' }}
                />
            </FormControl>
        </Box>
    );
};

export default SearchBar;
