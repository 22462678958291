import PropTypes from 'prop-types';

import { useTheme } from '@mui/material/styles';
import { AppBar, IconButton, Toolbar, useMediaQuery } from '@mui/material';

import AppBarStyled from './AppBarStyled';
import HeaderContent from './HeaderContent';
import { MenuOpen, MenuSharp } from '@mui/icons-material';

const Header = ({ open, handleDrawerToggle }) => {
    const theme = useTheme();
    const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));
    const mode = theme.palette.mode;

    const iconBackColorOpen = 'grey.200';

    const mainHeader = (
        <Toolbar>
            <IconButton
                disableRipple
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                edge="start"
                color="secondary"
                sx={{
                    color: 'text.primary',
                    bgcolor: mode === 'light' ? iconBackColorOpen : 'rgba(255, 255, 255, 0.08)',
                    ml: { xs: 0, lg: -2 }
                }}
            >
                {!open ? <MenuSharp fontSize="small" /> : <MenuOpen fontSize="small" />}
            </IconButton>
            <HeaderContent />
        </Toolbar>
    );

    const appBar = {
        position: 'fixed',
        color: 'inherit',
        elevation: 0,
        sx: {
            borderBottom: `1px solid ${theme.palette.divider}`
            // boxShadow: theme.customShadows.z1
        }
    };

    return (
        <>
            {!matchDownMD ? (
                <AppBarStyled open={open} {...appBar}>
                    {mainHeader}
                </AppBarStyled>
            ) : (
                <AppBar {...appBar}>{mainHeader}</AppBar>
            )}
        </>
    );
};

Header.propTypes = {
    open: PropTypes.bool,
    handleDrawerToggle: PropTypes.func
};

export default Header;
