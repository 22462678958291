import { thunkActionDjango, thunkFileActionDjango, thunkFileDownloadActionDjango } from '../../thunks/thunkActions';
import {
    CHAT_ROOM_ADD,
    CHAT_ROOM_DETAILS,
    CHAT_ROOM_FILE_ADD,
    CHAT_ROOM_FILE_DELETE,
    CHAT_ROOM_FILE_DOWNLOAD,
    CHAT_ROOM_FILE_LIST,
    CHAT_ROOM_LIST,
    CHAT_ROOM_MESSAGES_LIST,
    CHAT_USERS_LIST
} from './chatActionsType';

export const chatUsersList = () => thunkActionDjango(`chat/buddies/list/`, CHAT_USERS_LIST);
export const chatRoomList = () => thunkActionDjango(`chat/rooms/list/`, CHAT_ROOM_LIST);
export const chatRoomMessagesList = (identifier, params) =>
    thunkActionDjango(`chat/rooms/detail/${identifier}/messages/`, CHAT_ROOM_MESSAGES_LIST, 'get', null, params);
export const chatRoomDetails = (identifier) => thunkActionDjango(`chat/rooms/detail/${identifier}/`, CHAT_ROOM_DETAILS);
export const chatRoomAdd = (data) => thunkActionDjango(`chat/rooms/add/`, CHAT_ROOM_ADD, 'post', data);
export const chatRoomFileAdd = (identifier, data) =>
    thunkFileActionDjango(`chat/rooms/detail/${identifier}/files/add/`, CHAT_ROOM_FILE_ADD, 'post', data);
export const chatRoomFileList = (identifier) =>
    thunkActionDjango(`chat/rooms/files/list/?special_filters=[{"id":"chat_room", "value":${identifier}}]`, CHAT_ROOM_FILE_LIST);
export const chatRoomFileDownload = (identifier, fileName) =>
    thunkFileDownloadActionDjango(`chat/rooms/files/download/${identifier}/`, CHAT_ROOM_FILE_DOWNLOAD, 'get', null, fileName);
export const chatRoomFileDelete = (identifier) =>
    thunkActionDjango(`chat/rooms/files/delete/${identifier}/`, CHAT_ROOM_FILE_DELETE, 'delete');
