import { createSlice } from '@reduxjs/toolkit';
import { createInitialState } from '../../../utils/initialState';
import { createSubReducer } from '../../../utils/createReducer';
import {
    creditNoteAdd,
    creditNoteDelete,
    creditNoteDetails,
    creditNoteEdit,
    creditNoteGlobalDetails,
    creditNoteList
} from '../../../actions/creditNote/actions';

const initialState = {
    add: createInitialState(),
    globalDetails: createInitialState({ loading: true }),
    edit: createInitialState(),
    details: createInitialState({ loading: true }),
    list: createInitialState({ data: [] }),
    delete: createInitialState()
};

const creditNoteSlice = createSlice({
    name: 'creditNote',
    initialState,
    reducers: {
        resetCreditNoteState: (state, action) => {
            const { key } = action.payload;
            state[key] = initialState[key];
        }
    },
    extraReducers: (builder) => {
        const actionsMap = {
            add: creditNoteAdd,
            globalDetails: creditNoteGlobalDetails,
            edit: creditNoteEdit,
            details: creditNoteDetails,
            list: creditNoteList,
            delete: creditNoteDelete
        };

        Object.keys(actionsMap).forEach((key) => {
            createSubReducer(builder, actionsMap[key], key);
        });
    }
});

export const { resetCreditNoteState } = creditNoteSlice.actions;
export default creditNoteSlice.reducer;
