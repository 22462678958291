import { createRequestTypes } from '../actionsTypes';

export const REGISTER = createRequestTypes('REGISTER');
export const LOGIN = createRequestTypes('LOGIN');
export const LOGOUT = createRequestTypes('LOGOUT');
export const FORGOT_PASSWORD = createRequestTypes('FORGOT_PASSWORD');
export const RESET_PASSWORD = createRequestTypes('RESET_PASSWORD');
export const OTP_ENABLE = createRequestTypes('OTP_ENABLE');
export const OTP_DISABLE = createRequestTypes('OTP_DISABLE');
export const OTP_VERIFY = createRequestTypes('OTP_VERIFY');
export const OTP_VALIDATE = createRequestTypes('OTP_VALIDATE');
export const AUTH_CHECK_STATE = createRequestTypes('AUTH_CHECK_STATE');
export const USER_CHANGE_PASSWORD = createRequestTypes('USER_CHANGE_PASSWORD');
export const USER_GOOGLE_START = createRequestTypes('USER_GOOGLE_START');
export const USER_GOOGLE_DETAILS = createRequestTypes('USER_GOOGLE_DETAILS');
export const USER_GOOGLE_LOGIN = createRequestTypes('USER_GOOGLE_LOGIN');
