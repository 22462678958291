import PropTypes from 'prop-types';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';

const CoreTable = ({ columns, fetchAction, fetchSelector, refreshTriggers }) => {
    const dispatch = useDispatch();

    const { data, loading } = useSelector(fetchSelector);

    const shouldRefetch = useMemo(() => refreshTriggers.some((trigger) => trigger === true), [refreshTriggers]);

    useEffect(() => {
        dispatch(fetchAction());
    }, [dispatch, fetchAction, shouldRefetch]);

    const table = useMaterialReactTable({
        columns,
        data,
        enableColumnActions: false,
        enableColumnFilters: false,
        // enablePagination: false,
        enableSorting: false,

        muiTableProps: {
            sx: {
                '& .MuiTableRow-root': {
                    boxShadow: 'none'
                }
            }
        },
        state: {
            isLoading: loading
        }
    });

    return <MaterialReactTable table={table} />;
};

CoreTable.propTypes = {
    columns: PropTypes.array.isRequired,
    fetchAction: PropTypes.func.isRequired,
    fetchSelector: PropTypes.func.isRequired,
    triggers: PropTypes.object,
    subRowSelector: PropTypes.string,
    subRowColumns: PropTypes.array
};

export default CoreTable;
