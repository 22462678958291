import React from 'react';
import { Divider, List, ListItemButton, ListItemAvatar, ListItemText, Skeleton } from '@mui/material';

const SkeletonNotificationList = ({ count = 4 }) => {
    return (
        <List sx={{ width: '100%' }}>
            {Array.from(new Array(count)).map((_, index) => (
                <React.Fragment key={index}>
                    <ListItemButton>
                        <ListItemAvatar>
                            <Skeleton animation="wave" variant="circular" width={40} height={40} />
                        </ListItemAvatar>
                        <ListItemText
                            primary={<Skeleton animation="wave" height={10} width="80%" />}
                            secondary={<Skeleton animation="wave" height={10} width="40%" />}
                        />
                    </ListItemButton>
                    {index < count - 1 && <Divider />}
                </React.Fragment>
            ))}
        </List>
    );
};

export default SkeletonNotificationList;
