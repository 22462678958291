import PropTypes from 'prop-types';

import { useTheme } from '@mui/material/styles';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

import { IconUserEdit } from '@tabler/icons-react';
import { Link } from 'react-router-dom';

const ProfileTab = () => {
    const theme = useTheme();

    return (
        <>
            <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32 } }}>
                <ListItemButton component={Link} to="/account/settings/profile">
                    <ListItemIcon>
                        <IconUserEdit stroke={1.75} size="1.2rem" style={{ color: theme.palette.primary.main }} />
                    </ListItemIcon>
                    <ListItemText primary="Edit Profile" />
                </ListItemButton>
            </List>
        </>
    );
};

ProfileTab.propTypes = {
    handleLogout: PropTypes.func
};

export default ProfileTab;
