import { Box, Drawer, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { IconSettings } from '@tabler/icons-react';
import React, { useState } from 'react';
import AnimateButton from '../../../../../components/ui-component/buttons/AnimateButton';
import CustomizationDrawer from './CustomizationDrawer';

const Customization = () => {
    const [open, setOpen] = useState(false);

    const theme = useTheme();

    const handleDrawerToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    return (
        <>
            <Box sx={{ flexShrink: 0, ml: 0.75 }}>
                <IconButton
                    sx={{ color: 'text.primary' }}
                    aria-label="open customization"
                    aria-controls={'customization-grow'}
                    aria-haspopup="true"
                    onClick={handleDrawerToggle}
                >
                    <AnimateButton type="rotate">
                        <span
                            role="img"
                            aria-label="setting"
                            style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                                color: 'inherit',
                                fontStyle: 'normal',
                                lineHeight: 0,
                                textAlign: 'center',
                                textTransform: 'none',
                                verticalAlign: '-0.225em'
                            }}
                        >
                            <IconSettings stroke={1.5} size="1.2rem" />
                        </span>
                    </AnimateButton>
                </IconButton>
            </Box>
            <Drawer
                sx={{
                    width: 360,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: 360,
                        boxSizing: 'border-box'
                    }
                }}
                variant="temporary"
                anchor={theme.direction === 'rtl' ? 'left' : 'right'}
                open={open}
                onClose={handleDrawerToggle}
            >
                <CustomizationDrawer onClose={handleDrawerToggle} />
            </Drawer>
        </>
    );
};

export default Customization;
