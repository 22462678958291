import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';

import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Button,
    ButtonBase,
    CardContent,
    ClickAwayListener,
    Divider,
    Grid,
    IconButton,
    Paper,
    Popper,
    Stack,
    Tab,
    Tabs,
    Typography,
    useMediaQuery
} from '@mui/material';

import ProfileTab from './ProfileTab';
import SettingTab from './SettingTab';

import MainCard from '../../../../../components/ui-component/cards/MainCard';
import Transitions from '../../../../../components/ui-component/Transitions';
import { IconPower, IconSettings, IconUser } from '@tabler/icons-react';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../../../../services/actions/auth/actions';
import { licenseCurrentDetails } from '../../../../../services/actions/license/actions';

function TabPanel({ children, value, index, ...other }) {
    return (
        <div role="tabpanel" hidden={value !== index} id={`profile-tabpanel-${index}`} aria-labelledby={`profile-tab-${index}`} {...other}>
            {value === index && children}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps(index) {
    return {
        id: `profile-tab-${index}`,
        'aria-controls': `profile-tabpanel-${index}`
    };
}

const Profile = () => {
    const [value, setValue] = useState(0);

    const theme = useTheme();
    const dispatch = useDispatch();
    const mode = useSelector((state) => state.customization.mode);

    const handleLogout = async () => {
        dispatch(logout());
    };

    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const matchesSm = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const userDetails = useSelector((state) => state.user2.details.data);
    const { data: license, loading: isLoadingLicense } = useSelector((state) => state.license2.currentDetails);

    useEffect(() => {
        dispatch(licenseCurrentDetails());
    }, [dispatch]);

    return (
        <Box sx={{ flexShrink: 0, ml: 0.75 }}>
            <ButtonBase
                sx={{
                    p: 0.25,
                    bgcolor: open ? (mode === 'light' ? 'grey.100' : 'rgba(255, 255, 255, 0.08)') : 'transparent',
                    borderRadius: 1,
                    '&:hover': { bgcolor: mode === 'light' ? 'grey.100' : 'rgba(255, 255, 255, 0.08)' }
                }}
                aria-label="open profile"
                ref={anchorRef}
                aria-controls={open ? 'profile-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
                    <Avatar src={userDetails.user_image} sx={{ width: 32, height: 32 }} />
                    {!matchesSm && (
                        <Typography variant="subtitle1">
                            {userDetails.first_name} {userDetails.last_name}
                        </Typography>
                    )}
                </Stack>
            </ButtonBase>
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 9]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions type="fade" in={open} {...TransitionProps}>
                        {open && (
                            <Paper
                                sx={{
                                    boxShadow: theme.customShadows.z1,
                                    width: 290,
                                    minWidth: 240,
                                    maxWidth: 290,
                                    [theme.breakpoints.down('md')]: {
                                        maxWidth: 250
                                    }
                                }}
                            >
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MainCard elevation={0} border={false} content={false}>
                                        <CardContent sx={{ px: 2.5, pt: 3 }}>
                                            <Grid container justifyContent="space-between" alignItems="center">
                                                <Grid item>
                                                    <Stack direction="row" spacing={1.25} alignItems="center">
                                                        <Avatar src={userDetails.user_image} sx={{ width: 32, height: 32 }} />
                                                        <Stack>
                                                            <Typography variant="h6">
                                                                {userDetails.first_name} {userDetails.last_name}
                                                            </Typography>
                                                            <Typography variant="body2" color="textSecondary">
                                                                {!isLoadingLicense && `Expiry: ${license.expiration_date}`}
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                </Grid>
                                                <Grid item>
                                                    <IconButton size="large" color="error" onClick={handleLogout}>
                                                        <IconPower stroke={1.75} size="1.2rem" />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                        {open && (
                                            <>
                                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                    <Tabs
                                                        variant="fullWidth"
                                                        value={value}
                                                        onChange={handleChange}
                                                        aria-label="profile tabs"
                                                    >
                                                        <Tab
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                textTransform: 'capitalize'
                                                            }}
                                                            icon={<IconUser style={{ marginBottom: 0, marginRight: '10px' }} />}
                                                            label="Profile"
                                                            {...a11yProps(0)}
                                                        />
                                                        <Tab
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                textTransform: 'capitalize'
                                                            }}
                                                            icon={<IconSettings style={{ marginBottom: 0, marginRight: '10px' }} />}
                                                            label="Settings"
                                                            {...a11yProps(1)}
                                                        />
                                                    </Tabs>
                                                </Box>
                                                <TabPanel value={value} index={0} dir={theme.direction}>
                                                    <ProfileTab handleLogout={handleLogout} />
                                                </TabPanel>
                                                <TabPanel value={value} index={1} dir={theme.direction}>
                                                    <SettingTab />
                                                </TabPanel>
                                                <Divider />
                                                <Box p={1}>
                                                    <Button
                                                        endIcon={<IconPower stroke={1.75} size="1.2rem" />}
                                                        onClick={handleLogout}
                                                        fullWidth
                                                    >
                                                        Logout
                                                    </Button>
                                                </Box>
                                            </>
                                        )}
                                    </MainCard>
                                </ClickAwayListener>
                            </Paper>
                        )}
                    </Transitions>
                )}
            </Popper>
        </Box>
    );
};

export default Profile;
