import { List, ListItem } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ConnectionCard from './types/ConnectionCard';
import CommentCard from './types/CommentCard';
import FilesCard from './types/FilesCard';
import DefaultCard from './types/DefaultCard';
import ScrollBar from '../../../../../../components/third-party/ScrollBar';
import SkeletonNotificationList from '../SkeletonNotificationList';

const NotificationList = ({ data = [], onScrollToEnd, isLoading, handleMarkAsRead }) => {
    const [scrollEl, setScrollEl] = useState(null);

    useEffect(() => {
        if (scrollEl) {
            const handleScroll = () => {
                const isScrolledToBottom = scrollEl.scrollTop + scrollEl.clientHeight === scrollEl.scrollHeight;

                if (isScrolledToBottom) {
                    onScrollToEnd();
                }
            };

            scrollEl.addEventListener('scroll', handleScroll);

            return () => {
                scrollEl.removeEventListener('scroll', handleScroll);
            };
        }
    }, [onScrollToEnd, scrollEl]);

    const renderNotification = (item) => {
        switch (item.type) {
            case 'connection_request':
                return <ConnectionCard {...item} />;
            case 'comment':
                return <CommentCard {...item} />;
            case 'files':
                return <FilesCard {...item} />;
            case 'default':
                return <DefaultCard {...item} handleMarkAsRead={handleMarkAsRead} />;
            default:
                return <DefaultCard {...item} handleMarkAsRead={handleMarkAsRead} />;
        }
    };

    return (
        <ScrollBar
            options={{
                wheelPropagation: false
            }}
            style={{ height: '100%', maxHeight: 'calc(100vh - 168px)', overflowX: 'hidden' }}
            containerRef={(ref) => {
                setScrollEl(ref);
            }}
        >
            <List sx={{ padding: 0 }}>
                {data.map((item, index) => (
                    <ListItem
                        sx={{
                            cursor: 'pointer',
                            '&:hover': {
                                backgroundColor: 'grey.100'
                            },
                            borderBottom: '1px solid',
                            borderLeft: !item.is_read && 2,
                            borderLeftColor: 'info.main',
                            borderBottomColor: 'divider',
                            alignItems: 'start'
                        }}
                        key={index}
                    >
                        {renderNotification(item)}
                    </ListItem>
                ))}
            </List>
            {isLoading && <SkeletonNotificationList count={2} />}
        </ScrollBar>
    );
};

export default NotificationList;
