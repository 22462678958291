import { combineReducers } from 'redux';
import {
    authCheckStateReducer,
    forgotPasswordReducer,
    loginReducer,
    logoutReducer,
    otpDisableReducer,
    otpEnableReducer,
    otpValidateReducer,
    otpVerifyReducer,
    registerReducer,
    resetPasswordReducer,
    userChangePasswordReducer,
    userGoogleDetailsReducer,
    userGoogleLoginReducer,
    userGoogleStartReducer
} from './authReducers';

const authReducer = combineReducers({
    register: registerReducer,
    login: loginReducer,
    logout: logoutReducer,
    forgotPassword: forgotPasswordReducer,
    resetPassword: resetPasswordReducer,
    otpEnable: otpEnableReducer,
    otpVerify: otpVerifyReducer,
    otpDisable: otpDisableReducer,
    otpValidate: otpValidateReducer,
    authCheckState: authCheckStateReducer,
    userChangePassword: userChangePasswordReducer,
    userGoogleStart: userGoogleStartReducer,
    userGoogleDetails: userGoogleDetailsReducer,
    userGoogleLogin: userGoogleLoginReducer
});

export default authReducer;
