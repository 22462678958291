import { createSlice } from '@reduxjs/toolkit';
import { createInitialState } from '../../../utils/initialState';
import { createSubReducer } from '../../../utils/createReducer';
import {
    costAdd,
    costCategoryOptions,
    costDelete,
    costDetails,
    costEdit,
    costHistoryList,
    costList,
    costNameOptions,
    costOptionsList,
    costUnitOptions
} from '../../../actions/costs/actions';

const initialState = {
    options: createInitialState({ data: [] }),
    nameOptions: createInitialState({ data: [] }),
    unitOptions: createInitialState({ data: [] }),
    categoryOptions: createInitialState({ data: [] }),
    eanOptions: createInitialState({ data: [] }),
    list: createInitialState({ data: [] }),
    add: createInitialState(),
    details: createInitialState({ loading: true }),
    edit: createInitialState(),
    delete: createInitialState(),
    historyList: createInitialState({ data: [] })
};

const costSlice = createSlice({
    name: 'cost',
    initialState,
    reducers: {
        resetCostState: (state, action) => {
            const { key } = action.payload;
            state[key] = initialState[key];
        }
    },
    extraReducers: (builder) => {
        const actionsMap = {
            options: costOptionsList,
            nameOptions: costNameOptions,
            unitOptions: costUnitOptions,
            categoryOptions: costCategoryOptions,
            list: costList,
            add: costAdd,
            details: costDetails,
            edit: costEdit,
            delete: costDelete,
            historyList: costHistoryList
        };

        Object.keys(actionsMap).forEach((key) => {
            createSubReducer(builder, actionsMap[key], key);
        });
    }
});

export const { resetCostState } = costSlice.actions;
export default costSlice.reducer;
