import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { BrowserView, MobileView } from 'react-device-detect';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './customScrollbar.css';

const RootStyle = styled(BrowserView)({
    flexGrow: 1,
    height: '100%',
    overflow: 'hidden'
});

export default function SimpleBarScroll({ children, sx, ...other }) {
    return (
        <>
            <RootStyle>
                <PerfectScrollbar
                    options={{
                        wheelSpeed: 0.5,
                        swipeEasing: true,
                        wheelPropagation: false
                    }}
                    style={{ maxHeight: '100%', ...sx }}
                    {...other}
                >
                    {children}
                </PerfectScrollbar>
            </RootStyle>
            <MobileView>
                <Box sx={{ overflowX: 'auto', ...sx }} {...other}>
                    {children}
                </Box>
            </MobileView>
        </>
    );
}

SimpleBarScroll.propTypes = {
    children: PropTypes.node,
    sx: PropTypes.object
};
