import React from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Card,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    Radio,
    Stack,
    Typography,
    Collapse
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSelector, useDispatch } from 'react-redux';
import { SET_THEME_DRAWER } from '../../../../../../services/actions/customization/customizationType';
import { IconLayoutSidebar } from '@tabler/icons-react';

const ThemeLayout = () => {
    const drawer = useSelector((state) => state.customization.drawer);
    const customization = useSelector((state) => state.customization);

    const dispatch = useDispatch();

    const handleModeChange = (event) => {
        const themeDrawer = event.target.value;
        const updatedCustomization = { ...customization, drawer: themeDrawer };
        localStorage.setItem('customization', JSON.stringify(updatedCustomization));

        dispatch({ type: SET_THEME_DRAWER, drawer: themeDrawer });
    };

    return (
        <Accordion elevation={0} sx={{ borderBottom: '1px solid', borderColor: 'divider', margin: 0 }} disableGutters>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2d-content" id="panel2d-header">
                <Stack direction="row" spacing={2} alignItems="center">
                    <IconButton color="primary" aria-label="settings toggler">
                        <IconLayoutSidebar />
                    </IconButton>
                    <Stack>
                        <Typography variant="subtitle1">Theme Layout</Typography>
                        <Typography variant="caption">Choose layout</Typography>
                    </Stack>
                </Stack>
            </AccordionSummary>
            <Collapse in={true}>
                <AccordionDetails>
                    <FormGroup row aria-label="payment-card" role="radiogroup" onChange={handleModeChange}>
                        <Grid container spacing={1.75}>
                            <Grid item>
                                <FormControlLabel
                                    control={<Radio value="default" color="primary" size="small" checked={drawer === 'default'} />}
                                    label={
                                        <Card elevation={0}>
                                            <Stack>
                                                <Typography variant="caption">Default</Typography>
                                            </Stack>
                                        </Card>
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={<Radio value="mini" color="primary" size="small" checked={drawer === 'mini'} />}
                                    label={
                                        <Card elevation={0}>
                                            <Stack>
                                                <Typography variant="caption">Minimal drawer</Typography>
                                            </Stack>
                                        </Card>
                                    }
                                />
                            </Grid>
                        </Grid>
                    </FormGroup>
                </AccordionDetails>
            </Collapse>
        </Accordion>
    );
};

export default ThemeLayout;
