import { lazy } from 'react';
import Loadable from '../../components/Loadable';

const Statistics = Loadable(lazy(() => import('../../pages/statistics')));
const Overview = Loadable(lazy(() => import('../../pages/statistics/overview')));
const ProfitAnalysis = Loadable(lazy(() => import('../../pages/statistics/profit-analysis')));
const StatisticsDashboard = Loadable(lazy(() => import('../../pages/statistics/dashboard')));

export const StatisticsRoutes = [
    {
        path: 'statistics',
        element: <Statistics />,
        children: [
            { path: 'overview', element: <Overview /> },
            { path: 'profit-analysis', element: <ProfitAnalysis /> },
            { path: 'dashboard', element: <StatisticsDashboard /> }
        ]
    }
];
