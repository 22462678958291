import React, { useEffect, useState } from 'react';
import CorePopup from '../components/ui-component/popup/CorePopup';
import { useUpdateSearchParams } from './hooks/params';
import { useSearchParams } from 'react-router-dom';
import { BasicFab } from '../components/ui-component/fab/BasicFab';
import StandardPopup from '../components/ui-component/popup/StandardPopup';
import CheckIcon from '@mui/icons-material/Check';
import { Cancel } from '@mui/icons-material';
import FullScreenPopup from '../components/ui-component/popup/FullScreenPopup';

export const renderPopups = (popupState, closePopup, popupConfig, props = {}) => {
    const openPopup = props.openPopup;

    return Object.keys(popupState).map((key) => {
        if (popupState[key] && popupConfig[key]) {
            const { title, content, maxWidth, isPadding = true, fab, model = 'core' } = popupConfig[key];

            switch (model) {
                case 'core':
                    return (
                        <React.Fragment key={key}>
                            <CorePopup
                                open={popupState[key]}
                                close={() => closePopup(key)}
                                title={title}
                                content={content(props)}
                                maxWidth={maxWidth}
                                isPadding={isPadding}
                            />
                            {fab && (
                                <BasicFab
                                    actions={fab.map((action) => ({
                                        icon: action.icon,
                                        name: action.name,
                                        onClick: () => openPopup(action.model)
                                    }))}
                                />
                            )}
                        </React.Fragment>
                    );

                case 'standard':
                    return (
                        <React.Fragment key={key}>
                            <StandardPopup
                                open={popupState[key]}
                                close={() => closePopup(key)}
                                request1={() => closePopup(key)}
                                request2={props.request}
                                fullWidth={true}
                                maxWidth={maxWidth}
                                title={title}
                                content={'Confirm the operation'}
                                icon1={<Cancel />}
                                icon2={<CheckIcon />}
                                label1={'Cancel'}
                                label2={'Confirm'}
                            />
                        </React.Fragment>
                    );

                case 'fullscreen':
                    return (
                        <React.Fragment key={key}>
                            <FullScreenPopup open={popupState[key]} close={() => closePopup(key)} title={title} content={content(props)} />
                        </React.Fragment>
                    );

                default:
                    return null;
            }
        }
        return null;
    });
};

export const usePopupManager = (initialState) => {
    const [popupState, setPopupState] = useState(initialState.state || {});
    const [popupData, setPopupData] = useState({});
    const updateSearchParams = useUpdateSearchParams();
    const [searchParams] = useSearchParams();
    const includeInUrl = initialState.includeInUrl || [];

    useEffect(() => {
        const action = searchParams.get('action');
        if (action && includeInUrl.includes(action)) {
            setPopupState((prev) => ({ ...prev, [action]: true }));
        }
    }, [searchParams, includeInUrl]);

    const openPopup = (popupName, data = null) => {
        setPopupState((prev) => ({ ...prev, [popupName]: true }));
        if (data) {
            setPopupData((prev) => ({ ...prev, [popupName]: data }));
        }
        if (includeInUrl.includes(popupName)) {
            updateSearchParams({ action: popupName });
        }
    };

    const closePopup = (popupName) => {
        setPopupState((prev) => ({ ...prev, [popupName]: false }));
        setPopupData((prev) => ({ ...prev, [popupName]: null }));
        if (includeInUrl.includes(popupName)) {
            updateSearchParams({ action: null });
        }
    };

    return [popupState, openPopup, closePopup, popupData];
};
