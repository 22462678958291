import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import { useTheme } from '@mui/material/styles';
import { Card, CardContent, CardHeader, Divider, Stack, Typography } from '@mui/material';
import SlugInfo from '../tooltip/SlugInfo';

const headerSX = {
    p: 2.5,
    '& .MuiCardHeader-action': { m: '0px auto', alignSelf: 'center' }
};

const MainCard = forwardRef(
    (
        {
            border = true,
            boxShadow,
            children,
            content = true,
            contentSX = {},
            divider = true,
            darkTitle,
            elevation,
            secondary,
            shadow,
            sx = {},
            title,
            slug = null,
            ...others
        },
        ref
    ) => {
        const theme = useTheme();
        boxShadow = theme.palette.mode === 'dark' ? boxShadow || true : boxShadow;

        return (
            <Card
                elevation={elevation || 0}
                ref={ref}
                {...others}
                sx={{
                    border: border ? '1px solid' : 'none',
                    borderRadius: 2,
                    borderColor: theme.palette.mode === 'dark' ? theme.palette.divider : theme.palette.grey.A800,
                    boxShadow: boxShadow && (!border || theme.palette.mode === 'dark') ? shadow || theme.customShadows.z1 : 'inherit',
                    backgroundColor: theme.palette.mode === 'dark' ? 'rgb(30, 30, 30)' : 'white',
                    ':hover': {
                        boxShadow: boxShadow ? shadow || theme.customShadows.z1 : 'inherit'
                    },
                    '& pre': {
                        m: 0,
                        p: '16px !important',
                        fontFamily: theme.typography.fontFamily,
                        fontSize: '0.75rem'
                    },
                    ...sx
                }}
            >
                {!darkTitle && title && (
                    <CardHeader
                        sx={headerSX}
                        title={
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography variant="subtitle1">{title}</Typography>
                                {slug && <SlugInfo slug={slug} />}
                            </Stack>
                        }
                        action={secondary}
                    />
                )}
                {darkTitle && title && (
                    <CardHeader
                        sx={headerSX}
                        title={
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography variant="h3">{title}</Typography>
                                {slug && <SlugInfo slug={slug} />}
                            </Stack>
                        }
                        action={secondary}
                    />
                )}
                {title && divider && <Divider />}

                {content && <CardContent sx={contentSX}>{children}</CardContent>}
                {!content && children}
            </Card>
        );
    }
);

MainCard.propTypes = {
    border: PropTypes.bool,
    boxShadow: PropTypes.bool,
    contentSX: PropTypes.object,
    darkTitle: PropTypes.bool,
    divider: PropTypes.bool,
    elevation: PropTypes.number,
    secondary: PropTypes.node,
    shadow: PropTypes.string,
    sx: PropTypes.object,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    codeHighlight: PropTypes.bool,
    content: PropTypes.bool,
    children: PropTypes.node
};

export default MainCard;
