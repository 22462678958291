import { createSlice } from '@reduxjs/toolkit';
import { createInitialState } from '../../../utils/initialState';
import { createSubReducer } from '../../../utils/createReducer';
import { statusAdd, statusDelete, statusEdit, statusList, statusOptionsList, statusOrderChange } from '../../../actions/status/actions';

const initialState = {
    options: createInitialState({ data: [] }),
    list: createInitialState({ data: [] }),
    add: createInitialState(),
    delete: createInitialState(),
    edit: createInitialState(),
    orderChange: createInitialState()
};

const statusSlice = createSlice({
    name: 'status',
    initialState,
    reducers: {
        resetStatusState: (state, action) => {
            const { key } = action.payload;
            state[key] = initialState[key];
        }
    },
    extraReducers: (builder) => {
        const actionsMap = {
            options: statusOptionsList,
            list: statusList,
            add: statusAdd,
            delete: statusDelete,
            edit: statusEdit,
            orderChange: statusOrderChange
        };

        Object.keys(actionsMap).forEach((key) => {
            createSubReducer(builder, actionsMap[key], key);
        });
    }
});

export const { resetStatusState } = statusSlice.actions;
export default statusSlice.reducer;
