import { useTheme } from '@mui/material/styles';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

import { IconLifebuoy } from '@tabler/icons-react';
import { Link } from 'react-router-dom';

const SettingTab = () => {
    const theme = useTheme();

    return (
        <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32, color: theme.palette.grey[500] } }}>
            <ListItemButton component={Link} to="/help-center">
                <ListItemIcon>
                    <IconLifebuoy stroke={1.75} size="1.2rem" style={{ color: theme.palette.primary.main }} />
                </ListItemIcon>
                <ListItemText primary="Support" />
            </ListItemButton>
        </List>
    );
};

export default SettingTab;
