import React, { useState } from 'react';
import DynamicUrlFilterForm from './filterForm';
import MainCard from '../../../components/ui-component/cards/MainCard';
import { Button, Stack } from '@mui/material';
import Table from '../../../components/table';
import { useSelector } from 'react-redux';
import DynamicUrlDetails from '../details';
import { renderPopups, usePopupManager } from '../../../utils/popup';
import { Link } from 'react-router-dom';

function generateRowActionsFromList(data) {
    if (!data || !data.length) return null;

    return (row, openPopup) =>
        data.map((item) => ({
            label: item.name, // Ustawia etykietę akcji
            action: () =>
                openPopup(item.type, {
                    row: row.original,
                    data: {
                        url: item.url,
                        separator: item.separator,
                        auth_method: item.auth_method
                    }
                })
        }));
}

function generateColumnsFromList(data) {
    if (!data || !data.length) return [];

    const item = data[0]; // Bierzemy pierwszy element, zakładając, że struktura jest jednolita
    const columns = [];

    for (const key in item) {
        const value = item[key];

        // Pomijamy obiekty i tablice
        if (typeof value === 'object' || Array.isArray(value)) {
            continue;
        }

        columns.push({
            accessorKey: key,
            header: formatHeader(key)
        });
    }

    return columns;
}

// Pomocnicza funkcja do formatowania nagłówków
function formatHeader(key) {
    return key
        .replace(/_/g, ' ') // Zastępuje `_` spacjami
        .replace(/\b\w/g, (char) => char.toUpperCase()); // Zmienia pierwsze litery na wielkie
}

const initialPopupState = {
    state: {
        details: false
    }
};

const popupConfig = {
    details: {
        title: 'Details',
        content: (props) => <DynamicUrlDetails {...props} />,
        maxWidth: 'sm'
    }
};

const DynamicUrlList = () => {
    const [popupState, openPopup, closePopup, popupData] = usePopupManager(initialPopupState);

    const [filter, setFilter] = useState({});
    const [token, setToken] = useState(null);

    const { data, loading } = useSelector((state) => state.dynamicUrl.check);
    const { data: actions } = useSelector((state) => state.dynamicUrl.actionOptions);

    const popupProps = {
        data: popupData.details,
        token: token
    };

    return (
        <>
            <Stack spacing={2}>
                <MainCard
                    title="Filter"
                    secondary={
                        <Button variant="contained" component={Link} to="/dynamic-url/add">
                            Add
                        </Button>
                    }
                >
                    <DynamicUrlFilterForm setFilter={setFilter} setToken={setToken} />
                </MainCard>
                <Table
                    title={filter?.listName?.label || 'Select list'}
                    columns={generateColumnsFromList(
                        filter?.listName?.separator ? (data?.[filter?.listName?.separator] ?? []) : (data ?? [] ?? [])
                    )}
                    data={filter?.listName?.separator ? (data?.[filter?.listName?.separator] ?? []) : (data ?? [] ?? [])}
                    rowActions={generateRowActionsFromList(actions ?? []) ?? null}
                    isLoading={loading}
                    openPopup={openPopup}
                    type="local"
                />
            </Stack>
            {renderPopups(popupState, closePopup, popupConfig, popupProps)}
        </>
    );
};

export default DynamicUrlList;
