import {
    ORDER_ADD,
    ORDER_DELETE,
    ORDER_DETAILS,
    ORDER_EDIT,
    ORDER_LIST,
    ORDER_PRODUCTS_UNIT_LIST
} from '../../../actions/order/orderActionsType';
import { globalGetReducer, globalPostReducer } from '../../helpers';

export const orderListReducer = globalGetReducer(ORDER_LIST.START, ORDER_LIST.SUCCESS, ORDER_LIST.FAIL);
export const orderProductsUnitListReducer = globalGetReducer(
    ORDER_PRODUCTS_UNIT_LIST.START,
    ORDER_PRODUCTS_UNIT_LIST.SUCCESS,
    ORDER_PRODUCTS_UNIT_LIST.FAIL
);
export const orderAddReducer = globalPostReducer(ORDER_ADD.START, ORDER_ADD.SUCCESS, ORDER_ADD.FAIL, ORDER_ADD.RESET);
export const orderDeleteReducer = globalPostReducer(ORDER_DELETE.START, ORDER_DELETE.SUCCESS, ORDER_DELETE.FAIL, ORDER_DELETE.RESET);
export const orderDetailsReducer = globalGetReducer(ORDER_DETAILS.START, ORDER_DETAILS.SUCCESS, ORDER_DETAILS.FAIL);
export const orderEditReducer = globalPostReducer(ORDER_EDIT.START, ORDER_EDIT.SUCCESS, ORDER_EDIT.FAIL, ORDER_EDIT.RESET);
