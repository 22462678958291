import { lazy } from 'react';
import Loadable from '../../components/Loadable';

const NotificationList = Loadable(lazy(() => import('../../pages/notification/list')));

export const NotificationRoutes = [
    {
        path: 'notification',
        children: [
            {
                path: '',
                element: <NotificationList />
            }
        ]
    }
];
