import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import { useTheme } from '@mui/material';
import { useState } from 'react';

export const BasicFab = ({ actions }) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setTimeout(() => setOpen(false), 100);
    };

    const theme = useTheme();

    return (
        <Box sx={{ position: 'fixed', bottom: 16, right: 16, zIndex: 9999 }}>
            <SpeedDial
                ariaLabel="SpeedDial controlled open example"
                icon={<SpeedDialIcon />}
                onClose={handleClose}
                onOpen={handleOpen}
                open={open}
                sx={{ zIndex: theme.zIndex.modal + 1, position: 'relative' }}
            >
                {actions.map((action) => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        onClick={(e) => {
                            e.stopPropagation();
                            action.onClick();
                        }}
                    />
                ))}
            </SpeedDial>
        </Box>
    );
};
