import { createSlice } from '@reduxjs/toolkit';
import { createInitialState } from '../../../utils/initialState';
import { createSubReducer } from '../../../utils/createReducer';
import { noteAdd, noteDelete, noteEdit, noteList } from '../../../actions/note/actions';

const initialState = {
    list: createInitialState({ data: [] }),
    add: createInitialState(),
    delete: createInitialState(),
    edit: createInitialState()
};

const noteSlice = createSlice({
    name: 'note',
    initialState,
    reducers: {
        resetNoteState: (state, action) => {
            const { key } = action.payload;
            state[key] = initialState[key];
        }
    },
    extraReducers: (builder) => {
        const actionsMap = {
            list: noteList,
            add: noteAdd,
            delete: noteDelete,
            edit: noteEdit
        };

        Object.keys(actionsMap).forEach((key) => {
            createSubReducer(builder, actionsMap[key], key);
        });
    }
});

export const { resetNoteState } = noteSlice.actions;
export default noteSlice.reducer;
