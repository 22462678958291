import { lazy } from 'react';
import Loadable from '../../components/Loadable';

const TargetsList = Loadable(lazy(() => import('../../pages/targets/list')));

export const TargetsRoutes = [
    {
        path: 'targets',
        children: [
            {
                path: '',
                element: <TargetsList />
            }
        ]
    }
];
