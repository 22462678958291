import { lazy } from 'react';
import Loadable from '../../components/Loadable';

const Chat = Loadable(lazy(() => import('../../pages/chat')));

export const ChatRoutes = [
    {
        path: 'chat',
        children: [
            {
                path: '',
                element: <Chat />
            }
        ]
    }
];
