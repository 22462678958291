import { Box, Typography } from '@mui/material';

import NavGroup from './NavGroup';
import menuItems from '../../../../../components/menu-items';

const Navigation = () => {
    const navGroups = menuItems.items.map((item, index) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={`${item.id}-${index}`} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Fix - Navigation Group
                    </Typography>
                );
        }
    });

    return <Box sx={{ pt: 2.5 }}>{navGroups}</Box>;
};

export default Navigation;
