import { createAsyncThunk } from '@reduxjs/toolkit';
import { showNotification } from '../../utils/notification';
import { requestAction, requestBlob, requestExternalAction, requestMultipart, requestWithoutToken2 } from '.';

export const createAuthThunk = (actionType, endpoint, method = 'get') =>
    createAsyncThunk(actionType, async (data, { rejectWithValue }) => {
        try {
            const endpointUrl = typeof endpoint === 'function' ? endpoint(data.id, data.id2) : endpoint;

            const response = await requestWithoutToken2(endpointUrl, method, data);
            showNotification('Success', 'success');

            return response.data;
        } catch (error) {
            showNotification(error.response?.data?.error, 'error');
            return rejectWithValue(error.response?.data?.error || error.message);
        }
    });

export const createThunkAction = (actionType, endpoint, method = 'get', isDjango = true, isNotification = true) =>
    createAsyncThunk(actionType, async (data, { rejectWithValue }) => {
        try {
            const endpointUrl = typeof endpoint === 'function' ? endpoint(data.id, data.id2, data.id3) : endpoint;
            const response = await requestAction(endpointUrl, method, data, isDjango);
            if (method !== 'get') {
                if (isNotification) showNotification('Success', 'success');
            }

            return response.data;
        } catch (error) {
            showNotification(error.response?.data?.error, 'error');
            return rejectWithValue(error.response?.data?.error || error.message);
        }
    });

export const createThunkExternalAction = (actionType, endpoint, method = 'get') =>
    createAsyncThunk(actionType, async (data, { rejectWithValue }) => {
        try {
            const endpointUrl = typeof endpoint === 'function' ? endpoint(data.id, data.id2, data.id3) : endpoint;
            const response = await requestExternalAction(endpointUrl, method, data);
            if (method !== 'get') showNotification('Success', 'success');

            return response.data;
        } catch (error) {
            showNotification(error.response?.data?.error, 'error');
            return rejectWithValue(error.response?.data?.error || error.message);
        }
    });

export const createThunkFileAction = (actionType, endpoint, method = 'get', isDjango = true, isNotification = true) =>
    createAsyncThunk(actionType, async (data, { rejectWithValue }) => {
        try {
            const endpointUrl = typeof endpoint === 'function' ? endpoint(data.id) : endpoint;
            const response = await requestMultipart(endpointUrl, method, data, isDjango);

            if (isNotification) showNotification('Sukces', 'success');

            return response.data;
        } catch (error) {
            showNotification(error.response?.data?.error, 'error');
            return rejectWithValue(error.response?.data?.error || error.message);
        }
    });

export const thunkFileDownloadAction = (actionType, endpoint, method = 'get', isDjango = true) =>
    createAsyncThunk(actionType, async (data, { rejectWithValue }) => {
        showNotification('Loading..', 'info');

        try {
            const endpointUrl = typeof endpoint === 'function' ? endpoint(data.id, data.id2) : endpoint;
            const response = await requestBlob(endpointUrl, method, data, isDjango);

            showNotification('File in the process of downloading..', 'info');

            const fileUrl = URL.createObjectURL(response.data);
            const fileName =
                response.headers['content-disposition']?.split('filename=')[1].split(';')[0].replace(/['"]/g, '') || 'downloaded-file';

            const link = document.createElement('a');
            link.href = fileUrl;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            link.remove();

            URL.revokeObjectURL(fileUrl);

            return;
        } catch (error) {
            showNotification(error.response?.data?.error, 'error');
            return rejectWithValue(error.response?.data?.error || error.message);
        }
    });
