import { lazy } from 'react';
import Loadable from '../../components/Loadable';

const Dashboard = Loadable(lazy(() => import('../../pages/dashboard')));
const Overview = Loadable(lazy(() => import('../../pages/dashboard/overiew')));
const Orders = Loadable(lazy(() => import('../../pages/dashboard/orders')));
const Notification = Loadable(lazy(() => import('../../pages/dashboard/notification')));
const Analysis = Loadable(lazy(() => import('../../pages/dashboard/analysis')));
const Statistics = Loadable(lazy(() => import('../../pages/dashboard/statistics')));

export const DashboardRoutes = [
    {
        path: 'dashboard/*',
        element: <Dashboard />,
        children: [
            { index: true, path: 'overview', element: <Overview /> },
            { path: 'orders', element: <Orders /> },
            { path: 'notification', element: <Notification /> },
            { path: 'analysis', element: <Analysis /> },
            { path: 'statistics', element: <Statistics /> }
        ]
    }
];
