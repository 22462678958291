import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';

import { useTheme } from '@mui/material/styles';
import { Box, Drawer, useMediaQuery } from '@mui/material';

import DrawerHeader from './DrawerHeader';
import DrawerContent from './DrawerContent';
import MiniDrawerStyled from './MiniDrawerStyled';
import { drawerWidth } from '../../constant';
import DrawerFooter from './DrawerFooter';

const MainDrawer = ({ open, handleDrawerToggle, setOpen }) => {
    const theme = useTheme();

    const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));

    useEffect(() => {
        if (theme.drawer === 'mini') () => setOpen(false);
    }, [theme.drawer]);

    const drawerContent = useMemo(() => <DrawerContent />, []);
    const drawerHeader = useMemo(() => <DrawerHeader open={open} />, [open]);
    const drawerFooter = useMemo(() => <DrawerFooter open={open} />, [open]);

    return (
        <Box component="nav" sx={{ flexShrink: { md: 0 }, zIndex: 1300 }} aria-label="mailbox folders">
            {theme.drawer === 'mini' && (
                <MiniDrawerStyled variant="permanent" open={false}>
                    {drawerHeader}
                    {drawerContent}
                    {drawerFooter}
                </MiniDrawerStyled>
            )}
            {theme.drawer === 'default' &&
                (!matchDownMD ? (
                    <MiniDrawerStyled variant="permanent" open={open}>
                        {drawerHeader}
                        {drawerContent}
                        {drawerFooter}
                    </MiniDrawerStyled>
                ) : (
                    <Drawer
                        variant="temporary"
                        open={open}
                        onClose={handleDrawerToggle}
                        ModalProps={{ keepMounted: true }}
                        sx={{
                            display: { xs: 'block', lg: 'none' },
                            '& .MuiDrawer-paper': {
                                boxSizing: 'border-box',
                                width: drawerWidth,
                                backgroundColor: '#1B2430',
                                backgroundImage: 'none',
                                boxShadow: 'inherit'
                            }
                        }}
                    >
                        {open && drawerHeader}
                        {open && drawerContent}
                        {open && drawerFooter}
                    </Drawer>
                ))}
        </Box>
    );
};

MainDrawer.propTypes = {
    open: PropTypes.bool,
    handleDrawerToggle: PropTypes.func,
    window: PropTypes.object
};

export default MainDrawer;
