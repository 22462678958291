import { createRequestTypes } from '../actionsTypes';

export const PRODUCTS_LIST = createRequestTypes('PRODUCTS_LIST');
export const PRODUCT_ADD = createRequestTypes('PRODUCT_ADD');
export const PRODUCT_DETAILS = createRequestTypes('PRODUCT_DETAILS');
export const PRODUCT_EDIT = createRequestTypes('PRODUCT_EDIT');
export const PRODUCT_LICENSE_LIST = createRequestTypes('PRODUCT_LICENSE_LIST');
export const PRODUCT_DELETE = createRequestTypes('PRODUCT_DELETE');
export const PRODUCT_CATEGORIES_LIST = createRequestTypes('PRODUCT_CATEGORIES_LIST');
export const PRODUCT_SKU_LIST = createRequestTypes('PRODUCT_SKU_LIST');
export const PRODUCT_EAN_LIST = createRequestTypes('PRODUCT_EAN_LIST');
export const PRODUCT_BRAND_LIST = createRequestTypes('PRODUCT_BRAND_LIST');
export const PRODUCT_NAME_LIST = createRequestTypes('PRODUCT_NAME_LIST');
export const PRODUCT_IDENTIFIER_LIST = createRequestTypes('PRODUCT_IDENTIFIER_LIST');
export const PRICING_ADD = createRequestTypes('PRICING_ADD');
export const PRICING_PRODUCT_LIST = createRequestTypes('PRICING_PRODUCT_LIST');
export const PRICING_DELETE = createRequestTypes('PRICING_DELETE');
export const PRICING_PRODUCT_DETAILS = createRequestTypes('PRICING_PRODUCT_DETAILS');
export const PRICING_EDIT = createRequestTypes('PRICING_EDIT');
export const PRICING_HISTORY_LIST = createRequestTypes('PRICING_HISTORY_LIST');
export const PRODUCT_PRICING_HISTORY_LIST = createRequestTypes('PRODUCT_PRICING_HISTORY_LIST');
export const PRODUCT_CALCULATE_XOX = createRequestTypes('PRODUCT_CALCULATE_XOX');
export const PRODUCT_CALCULATE_WOW = createRequestTypes('PRODUCT_CALCULATE_WOW');
export const PRODUCT_CALCULATE_MOM = createRequestTypes('PRODUCT_CALCULATE_MOM');
export const PRODUCT_CALCULATE_QOQ = createRequestTypes('PRODUCT_CALCULATE_QOQ');
export const PRODUCT_CALCULATE_YOY = createRequestTypes('PRODUCT_CALCULATE_QOQ');
export const PRODUCT_SPECIAL_IDENTIFIER_LIST = createRequestTypes('PRODUCT_SPECIAL_IDENTIFIER_LIST');
export const PRODUCT_SPECIAL_IDENTIFIER_ADD = createRequestTypes('PRODUCT_SPECIAL_IDENTIFIER_ADD');
export const PRODUCT_SPECIAL_IDENTIFIER_DELETE = createRequestTypes('PRODUCT_SPECIAL_IDENTIFIER_DELETE');
export const PRODUCT_SPECIAL_IDENTIFIER_EDIT = createRequestTypes('PRODUCT_SPECIAL_IDENTIFIER_EDIT');
export const PRODUCT_SPECIAL_IDENTIFIER_DETAILS = createRequestTypes('PRODUCT_SPECIAL_IDENTIFIER_DETAILS');
export const SPECIAL_IDENTIFIER_LIST = createRequestTypes('SPECIAL_IDENTIFIER_LIST');
