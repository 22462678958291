import { Box, Divider, List, ListSubheader, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SearchBar from './SearchBar';
import ListItemComponent from './ListItemComponent';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { siteUrlList } from '../../../../../../services/actions/siteUrl/actions';

const SearchingList = ({ handleClosePopup }) => {
    const [query, setQuery] = useState('');

    const { data: favoritesList, isLoading: isLoadingFavoritesList } = useSelector((state) => state.siteUrl.favouriteList);

    const [recent] = useState(favoritesList.last_seen ?? []);
    const [favorites, setFavorites] = useState(favoritesList.favourite ?? []);

    const [pages, setPages] = useState([]);

    const dispatch = useDispatch();
    const theme = useTheme();
    const mode = theme.palette.mode;

    const { data: pagesList, isLoading: isLoadingPagesList } = useSelector((state) => state.siteUrl.list);

    const filteredData = Object.keys(pages).reduce((acc, category) => {
        const filteredCategoryItems = pages[category].filter((item) => item.title.toLowerCase().includes(query.toLowerCase()));
        if (filteredCategoryItems.length) {
            acc[category] = filteredCategoryItems;
        }
        return acc;
    }, {});

    const hasResults = Object.keys(filteredData).length > 0;

    useEffect(() => {
        dispatch(siteUrlList({ params: { is_favourite: 0, is_json: 1 } }));
    }, [dispatch]);

    useEffect(() => {
        if (pagesList) {
            setPages(pagesList);
        }
    }, [pagesList]);

    const handleFavoriteToggle = (item) => {
        setFavorites((prevFavorites) => {
            const isFavorite = prevFavorites.some((fav) => fav.title === item.title);
            if (isFavorite) {
                return prevFavorites.filter((fav) => fav.title !== item.title);
            } else {
                return [...prevFavorites, { ...item, is_favourite: !item.is_favourite }];
            }
        });

        setPages((prevPages) => {
            const updatedPages = { ...prevPages };
            Object.keys(updatedPages).forEach((category) => {
                updatedPages[category] = updatedPages[category].map((pageItem) => {
                    if (pageItem.title === item.title) {
                        return { ...pageItem, is_favourite: !item.is_favourite };
                    }
                    return pageItem;
                });
            });
            return updatedPages;
        });
    };

    return (
        <>
            <Box>
                <SearchBar query={query} setQuery={setQuery} />
                <Divider />

                <Box sx={{ width: '100%' }}>
                    {query && hasResults ? (
                        isLoadingPagesList ? (
                            'Loading'
                        ) : (
                            <List component="nav" aria-label="pages search">
                                {Object.keys(filteredData).map((category) => (
                                    <React.Fragment key={category}>
                                        <ListSubheader sx={{ width: '90%', backgroundColor: 'transparent' }}>
                                            {category
                                                .replace(/([a-z])([A-Z])/g, '$1 $2')
                                                .charAt(0)
                                                .toUpperCase() +
                                                category
                                                    .replace(/([a-z])([A-Z])/g, '$1 $2')
                                                    .slice(1)
                                                    .toLowerCase()}
                                        </ListSubheader>
                                        {filteredData[category].map((item, index) => (
                                            <ListItemComponent
                                                key={index}
                                                item={item}
                                                index={index}
                                                onFavoriteToggle={() => handleFavoriteToggle(item)}
                                                handleClosePopup={handleClosePopup}
                                                {...item}
                                            />
                                        ))}
                                    </React.Fragment>
                                ))}
                            </List>
                        )
                    ) : (
                        <>
                            {isLoadingFavoritesList ? (
                                'Loading..'
                            ) : (
                                <>
                                    {recent.length > 0 ? (
                                        <Typography variant="subtitle1" p={2} sx={{ backgroundColor: 'primary.lighter' }}>
                                            Recent:
                                        </Typography>
                                    ) : (
                                        <Typography variant="body2" p={2} textAlign="center">
                                            No recent searches
                                        </Typography>
                                    )}

                                    {recent.map((item, index) => (
                                        <ListItemComponent
                                            key={index}
                                            item={item}
                                            index={index}
                                            onFavoriteToggle={() => handleFavoriteToggle(item)}
                                            handleClosePopup={handleClosePopup}
                                            {...item}
                                        />
                                    ))}

                                    {favorites.length > 0 && (
                                        <Typography
                                            variant="subtitle1"
                                            p={2}
                                            sx={{ backgroundColor: mode === 'light' ? 'grey.100' : 'rgba(255, 255, 255, 0.08)' }}
                                        >
                                            Favorite:
                                        </Typography>
                                    )}

                                    {favorites.map((item, index) => (
                                        <ListItemComponent
                                            key={index}
                                            index={index}
                                            onFavoriteToggle={() => handleFavoriteToggle(item)}
                                            handleClosePopup={handleClosePopup}
                                            {...item}
                                        />
                                    ))}
                                </>
                            )}
                        </>
                    )}
                </Box>
            </Box>
        </>
    );
};

export default SearchingList;
