import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './services/store';
import { BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import config from './config';
import './i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));
const history = createBrowserHistory();

root.render(
    <Provider store={store}>
        <BrowserRouter basename={config.basename} history={history}>
            <App />
        </BrowserRouter>
    </Provider>
);

reportWebVitals();
