import { createThunkAction } from '../../thunks/actions';

export const notificationList = createThunkAction('notification/list', 'users/notifications/list/');
export const notificationEdit = createThunkAction('notification/edit', 'users/notifications/update/', 'put');
export const notificationSystemList = createThunkAction('notification/system/list', 'api/system/notifications/list/', 'get', false);
export const notificationSystemWarnList = createThunkAction(
    'notification/system/warn/list',
    'api/system/notifications/list/',
    'get',
    false
);
export const notificationRead = createThunkAction('notification/read', (id) => `system/notifications/detail/${id}/read/`, 'put');
export const notificationSystemAllList = createThunkAction('notification/system/all/list', 'api/system/notifications/list/', 'get', false);
export const notificationReadAll = createThunkAction('notification/readAll', 'system/notifications/detail/all/read/', 'put');
export const notificationReportOptions = createThunkAction('notification/report/options', 'system/simple-serializer/sales-raport_type/');
export const notificationColumnOptions = createThunkAction(
    'notification/column/options',
    (type) => `system/simple-serializer/sales-raports-columns/?raport_fk__raport_type=${type}`
);
export const notificationCheck = createThunkAction('notification/check', 'system/notifications/check/');
