import axios from 'axios';

const API_DEV_DJANGO_URL = process.env.REACT_APP_API_DJANGO_URL;
const API_DEV_EXPRESS_URL = process.env.REACT_APP_API_EXPRESS_URL;

export const requestWithoutToken = (endpoint, method = 'GET', data) => {
    return axios({
        method,
        url: `${API_DEV_DJANGO_URL}/${endpoint}`,
        data,
        headers: {
            'Content-Type': 'application/json'
        }
    });
};

export const requestDjango = (endpoint, method = 'GET', data, params = null) => {
    return axios({
        method,
        url: `${API_DEV_DJANGO_URL}/${endpoint}`,
        data,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`
        },
        params
    });
};

export const requestMultipartDjango = (endpoint, method = 'GET', data) => {
    return axios({
        method,
        url: `${API_DEV_DJANGO_URL}/${endpoint}`,
        data,
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Token ${localStorage.getItem('token')}`
        }
    });
};

export const requestExpress = (endpoint, method = 'GET', data, params = null) => {
    return axios({
        method,
        url: `${API_DEV_EXPRESS_URL}/${endpoint}`,
        data,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`
        },
        params
    });
};

export const requestBlobDjango = (endpoint, method = 'GET', data, params = null) => {
    return axios({
        method,
        url: `${API_DEV_DJANGO_URL}/${endpoint}`,
        data,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`
        },
        responseType: 'blob',
        params
    });
};

export const requestAction = async (endpoint, method = 'GET', data = {}, isDjango) => {
    const { formData = null, params = null } = data;
    const apiUrl = isDjango ? API_DEV_DJANGO_URL : API_DEV_EXPRESS_URL;

    try {
        const token = data.token || localStorage.getItem('token');

        const config = {
            method,
            url: `${apiUrl}/${endpoint}`,
            headers: {
                'Content-Type': 'application/json',
                authorization: `Token ${token}`
            },
            params
        };

        if (formData !== null) {
            config.data = formData;
        }

        const response = await axios(config);

        return response;
    } catch (error) {
        console.error('Error making request:', error.response ? error.response.data : error.message);
        throw error;
    }
};

export const requestExternalAction = async (endpoint, method = 'GET', data = {}) => {
    const { formData = null, params = null, isAuth = false, token = '' } = data;

    try {
        const config = {
            method,
            url: endpoint,
            params,
            headers: { 'Content-Type': 'application/json' }
        };

        if (isAuth && token) {
            config.headers['Authorization'] = `Token ${token}`;
        }

        if (formData !== null) {
            config.data = formData;
        }

        const response = await axios(config);

        return response;
    } catch (error) {
        console.error('Error making request:', error.response ? error.response.data : error.message);
        throw error;
    }
};

export const requestMultipart = async (endpoint, method = 'GET', data = {}, isDjango) => {
    const { formData = null, params = null } = data;
    const apiUrl = isDjango ? API_DEV_DJANGO_URL : API_DEV_EXPRESS_URL;

    try {
        const token = localStorage.getItem('token');

        const config = {
            method,
            url: `${apiUrl}/${endpoint}`,
            headers: {
                'Content-Type': 'multipart/form-data',
                authorization: `Token ${token}`
            },
            data: formData,
            params
        };

        const response = await axios(config);

        return response;
    } catch (error) {
        console.error('Error making request:', error.response ? error.response.data : error.message);
        throw error;
    }
};

export const requestBlob = async (endpoint, method = 'GET', data = {}, isDjango) => {
    const { formData = null } = data;

    const apiUrl = isDjango ? API_DEV_DJANGO_URL : API_DEV_EXPRESS_URL;

    try {
        const token = localStorage.getItem('token');

        const config = {
            method,
            url: `${apiUrl}/${endpoint}`,
            headers: {
                'Content-Type': 'application/json',
                authorization: `Token ${token}`
            },
            responseType: 'blob'
        };

        if (formData !== null) {
            config.data = formData;
        }

        const response = await axios(config);

        return response;
    } catch (error) {
        console.error('Error making request:', error.response ? error.response.data : error.message);
        throw error;
    }
};

export const requestWithoutToken2 = async (endpoint, method = 'GET', data = {}) => {
    const { formData = null } = data;

    const apiUrl = API_DEV_DJANGO_URL;

    try {
        const config = {
            method,
            url: `${apiUrl}/${endpoint}`,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        if (formData !== null) {
            config.data = formData;
        }

        const response = await axios(config);

        return response;
    } catch (error) {
        console.error('Error making request:', error.response ? error.response.data : error.message);
        throw error;
    }
};
