import config from '../../../../config';
import * as actionTypes from './../../../actions/customization/customizationType';

export const initialState = {
    fontFamily: config.fontFamily,
    borderRadius: config.borderRadius,
    mode: config.mode,
    direction: config.direction,
    drawer: config.drawer
};

const customizationReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_FONT_FAMILY:
            return {
                ...state,
                fontFamily: action.fontFamily
            };
        case actionTypes.SET_BORDER_RADIUS:
            return {
                ...state,
                borderRadius: action.borderRadius
            };
        case actionTypes.SET_THEME_MODE:
            return {
                ...state,
                mode: action.mode
            };
        case actionTypes.SET_LAYOUT_DIRECTION:
            return {
                ...state,
                direction: action.direction
            };
        case actionTypes.SET_THEME_DRAWER:
            return {
                ...state,
                drawer: action.drawer
            };
        case actionTypes.SET_THEME_CUSTOMIZATION:
            return {
                ...state,
                fontFamily: action.fontFamily,
                borderRadius: action.borderRadius,
                mode: action.mode,
                direction: action.direction,
                drawer: action.drawer
            };

        default:
            return state;
    }
};

export default customizationReducer;
