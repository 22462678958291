import { ToastContainer } from 'react-toastify';
import NavigationScroll from './layout/NavigationScroll';
import Routes from './routes';
import ThemeCustomization from './themes';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import './app.css';
import { authCheckState } from './services/actions/auth/actions';
import { resetAuthState } from './services/reducer/reducers/auth/slice';
import { useSearchParams } from 'react-router-dom';

function App() {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const isLogout = useSelector((state) => state.auth2.logout.success);
    const urlToken = searchParams.get('token');

    useEffect(() => {
        dispatch(authCheckState({ urlToken }));
    }, [dispatch, urlToken]);

    useEffect(() => {
        if (isLogout) dispatch(resetAuthState({ key: 'logout' }));
    }, [dispatch, isLogout]);

    return (
        <ThemeCustomization>
            <NavigationScroll>
                <Routes />
            </NavigationScroll>
            <ToastContainer position="bottom-right" draggable pauseOnHover closeOnClick />
        </ThemeCustomization>
    );
}

export default App;
