import { createRequestTypes } from '../actionsTypes';

export const STATISTICS_ADD = createRequestTypes('STATISTICS_ADD');
export const STATISTICS_DASHBOARD_DEFAULT = createRequestTypes('STATISTICS_DASHBOARD_DEFAULT');
export const STATISTICS_GENERATE = createRequestTypes('STATISTICS_GENERATE');
export const STATISTICS_SET_DASHBOARD_DEFAULT = createRequestTypes('STATISTICS_SET_DASHBOARD_DEFAULT');
export const STATISTICS_DASHBOARD_SIMPLE_LIST = createRequestTypes('STATISTICS_DASHBOARD_SIMPLE_LIST');
export const STATISTICS_DASHBOARD_SESSIONS_LIST = createRequestTypes('STATISTICS_DASHBOARD_SESSIONS_LIST');
export const STATISTICS_SELLOUT_ALLOWANCE_CALCULATE_DETAILS = createRequestTypes('STATISTICS_SELLOUT_ALLOWANCE_CALCULATE_DETAILS');
export const STATISTICS_WEEKS_ON_HAND_CALCULATE_DETAILS = createRequestTypes('STATISTICS_WEEKS_ON_HAND_CALCULATE_DETAILS');
export const TARGETS_LIST = createRequestTypes('TARGETS_LIST');
export const SELLOUT_SUM_CALCULATE = createRequestTypes('SELLOUT_SUM_CALCULATE');
export const TARGETS_ADD = createRequestTypes('TARGETS_ADD');
export const TARGETS_DETAILS = createRequestTypes('TARGETS_DETAILS');
export const TARGETS_EDIT = createRequestTypes('TARGETS_EDIT');
export const TARGETS_DELETE = createRequestTypes('TARGETS_DELETE');
export const BEST_SELLING_PRODUCTS = createRequestTypes('BEST_SELLING_PRODUCTS');
export const WORST_SELLING_PRODUCTS = createRequestTypes('WORST_SELLING_PRODUCTS');
export const BEST_DERIVATIVE_SELLING_PRODUCTS = createRequestTypes('BEST_DERIVATIVE_SELLING_PRODUCTS');
export const TARGETS_TYPE_LIST = createRequestTypes('TARGETS_TYPE_LIST');
export const COUNT_RECEIVED_ORDERS = createRequestTypes('COUNT_RECEIVED_ORDERS');
export const COUNT_DELIVERY = createRequestTypes('COUNT_DELIVERY');
export const COUNT_SELLOUT_VALUE = createRequestTypes('COUNT_SELLOUT_VALUE');
export const COUNT_SELLOUT_AMOUNT = createRequestTypes('COUNT_SELLOUT_AMOUNT');
export const COUNT_PRODUCTS = createRequestTypes('COUNT_PRODUCTS');
export const CHART_DELIVERIES_COMPANIES = createRequestTypes('CHART_DELIVERIES_COMPANIES');
export const TARGET_MAIN = createRequestTypes('TARGET_MAIN');
export const LAST_ORDERS = createRequestTypes('LAST_ORDERS');
export const LAST_DELIVERIES = createRequestTypes('LAST_DELIVERIES');
export const AVERAGE_SELLOUT_ALLOWANCE = createRequestTypes('AVERAGE_SELLOUT_ALLOWANCE');
export const SELLOUT_VALUE = createRequestTypes('SELLOUT_VALUE');
export const SELL_AMOUNT = createRequestTypes('SELL_AMOUNT');
export const CHART_SELL_VALUE_RAPORT = createRequestTypes('CHART_SELL_VALUE_RAPORT');
export const CHART_CATEGORY_REPORT = createRequestTypes('CHART_CATEGORY_REPORT');
export const CHART_AMOUNT_REPORT = createRequestTypes('CHART_AMOUNT_REPORT');
export const POPULAR_PRODUCTS = createRequestTypes('POPULAR_PRODUCTS');
export const SELL_VALUE_RAPORT_COMPANIES = createRequestTypes('SELL_VALUE_RAPORT_COMPANIES');
export const SALES_SPIKES_LIST = createRequestTypes('SALES_SPIKES_LIST');
export const PRODUCT_WOH_CALCULATE = createRequestTypes('PRODUCT_WOH_CALCULATE');
export const TARGETS_REFRESH = createRequestTypes('TARGETS_REFRESH');
export const STATISTICS_REPORT_ABCD = createRequestTypes('STATISTICS_REPORT_ABCD');
export const STATISTICS_CALCULATE_XOX = createRequestTypes('STATISTICS_CALCULATE_XOX');
export const STATISTICS_CALCULATE_WOW = createRequestTypes('STATISTICS_CALCULATE_WOW');
export const STATISTICS_CALCULATE_MOM = createRequestTypes('STATISTICS_CALCULATE_MOM');
export const STATISTICS_CALCULATE_QOQ = createRequestTypes('STATISTICS_CALCULATE_QOQ');
export const STATISTICS_CALCULATE_YOY = createRequestTypes('STATISTICS_CALCULATE_YOY');
export const STATISTICS_CALCULATE_STOCK_ON_HAND = createRequestTypes('STATISTICS_CALCULATE_STOCK_ON_HAND');
export const STATISTICS_CALCULATE_AMOUNT_SELL = createRequestTypes('STATISTICS_CALCULATE_AMOUNT_SELL');
export const PRODUCT_WOH_SOA_CALCULATE = createRequestTypes('PRODUCT_WOH_SOA_CALCULATE');
export const SOA_ADD = createRequestTypes('SOA_ADD');
export const STATISTICS_BEST_PERFORM_SKU = createRequestTypes('STATISTICS_BEST_PERFORM_SKU');
