import { createRequestTypes } from '../actionsTypes';

export const SALES_REPORT_LIST = createRequestTypes('SALES_REPORT_LIST');
export const SALES_REPORT_DELETE = createRequestTypes('SALES_REPORT_DELETE');
export const SALES_REPORT_COLUMNS_LIST = createRequestTypes('SALES_REPORT_COLUMNS_LIST');
export const SALES_REPORT_ADD = createRequestTypes('SALES_REPORT_ADD');
export const SALES_REPORT_DETAILS = createRequestTypes('SALES_REPORT_DETAILS');
export const SALES_REPORT_DOWNLOAD = createRequestTypes('SALES_REPORT_DOWNLOAD');
export const SALES_REPORT_IMPORT = createRequestTypes('SALES_REPORT_IMPORT');
export const SALES_REPORT_TEMPLATE_LIST = createRequestTypes('SALES_REPORT_TEMPLATE_LIST');
export const SALES_REPORT_TEMPLATE_DELETE = createRequestTypes('SALES_REPORT_TEMPLATE_DELETE');
export const SALES_REPORT_TEMPLATE_COLUMNS_LIST = createRequestTypes('SALES_REPORT_TEMPLATE_COLUMNS_LIST');
export const SALES_REPORT_TEMPLATE_ADD = createRequestTypes('SALES_REPORT_TEMPLATE_ADD');
export const SALES_REPORT_TEMPLATE_DETAILS = createRequestTypes('SALES_REPORT_TEMPLATE_DETAILS');
export const SALES_REPORT_ROW_EDIT = createRequestTypes('SALES_REPORT_ROW_EDIT');
export const SALES_REPORT_TEMPLATE_EDIT = createRequestTypes('SALES_REPORT_TEMPLATE_EDIT');
export const SALES_REPORT_SIMPLE_LIST = createRequestTypes('SALES_REPORT_SIMPLE_LIST');
export const SALES_REPORT_CONFIGURE = createRequestTypes('SALES_REPORT_CONFIGURE');
export const SALES_REPORT_DETAILS_PRODUCTS = createRequestTypes('SALES_REPORT_DETAILS_PRODUCTS');
export const SALES_REPORT_DETAILS_DATE = createRequestTypes('SALES_REPORT_DETAILS_DATE');
export const SALES_REPORT_DETAILS_LIST = createRequestTypes('SALES_REPORT_DETAILS_LIST');
export const SALES_REPORT_ROW_DELETE = createRequestTypes('SALES_REPORT_ROW_DELETE');
export const SALES_REPORT_ROW_ADD = createRequestTypes('SALES_REPORT_ROW_ADD');
export const SALES_REPORT_ROW_LIST = createRequestTypes('SALES_REPORT_ROW_LIST');
export const SALES_WEEKS_ON_HAND_CONFIG = createRequestTypes('SALES_WEEKS_ON_HAND_CONFIG');
export const SALES_WEEKS_ON_HAND_CONFIG_DETAILS = createRequestTypes('SALES_WEEKS_ON_HAND_CONFIG_DETAILS');
export const SALES_SELLOUT_ALLOWANCE_CONFIG = createRequestTypes('SALES_SELLOUT_ALLOWANCE_CONFIG');
export const SALES_SELLOUT_ALLOWANCE_DETAILS = createRequestTypes('SALES_SELLOUT_ALLOWANCE_DETAILS');
export const SALES_REPORT_REFRESH = createRequestTypes('SALES_REPORT_REFRESH');
export const SALES_REPORT_GENERATE_DETAILS = createRequestTypes('SALES_REPORT_GENERATE_DETAILS');
export const SALES_MARGIN_ADD = createRequestTypes('SALES_MARGIN_ADD');
export const SALES_MARGIN_EDIT = createRequestTypes('SALES_MARGIN_EDIT');
export const SALES_MARGIN_DETAILS = createRequestTypes('SALES_MARGIN_DETAILS');
export const SALES_MARGIN_GLOBAL_DETAILS = createRequestTypes('SALES_MARGIN_GLOBAL_DETAILS');
export const SALES_MARGIN_LIST = createRequestTypes('SALES_MARGIN_LIST');
export const SALES_MARGIN_DELETE = createRequestTypes('SALES_MARGIN_DELETE');
export const SALES_CREDIT_NOTE_ADD = createRequestTypes('SALES_CREDIT_NOTE_ADD');
export const SALES_CREDIT_NOTE_GLOBAL_DETAILS = createRequestTypes('SALES_CREDIT_NOTE_GLOBAL_DETAILS');
export const SALES_CREDIT_NOTE_EDIT = createRequestTypes('SALES_CREDIT_NOTE_EDIT');
export const SALES_CREDIT_NOTE_DETAILS = createRequestTypes('SALES_CREDIT_NOTE_DETAILS');
export const SALES_CREDIT_NOTE_LIST = createRequestTypes('SALES_CREDIT_NOTE_LIST');
export const SALES_CREDIT_NOTE_DELETE = createRequestTypes('SALES_CREDIT_NOTE_DELETE');
export const SALES_REPORT_COSTS_ADD = createRequestTypes('SALES_REPORT_COSTS_ADD');
export const SALES_REPORT_DETAILS_NET_VALUE = createRequestTypes('SALES_REPORT_DETAILS_NET_VALUE');
