import { lazy } from 'react';
import Loadable from '../../components/Loadable';

const Settings = Loadable(lazy(() => import('../../pages/account/Settings')));
const PersonalInformation = Loadable(lazy(() => import('../../pages/account/Settings/PersonalInformation')));
const Security = Loadable(lazy(() => import('../../pages/account/Settings/Security')));
const OrganizationInformation = Loadable(lazy(() => import('../../pages/account/Settings/Organization/Information')));
const License = Loadable(lazy(() => import('../../pages/account/Settings/Organization/License')));
const Connections = Loadable(lazy(() => import('../../pages/account/Settings/Organization/Connections')));
const Notification = Loadable(lazy(() => import('../../pages/account/Settings/Notification')));
const Sales = Loadable(lazy(() => import('../../pages/account/Settings/Sales')));
const Product = Loadable(lazy(() => import('../../pages/account/Settings/Product')));
const Refresh = Loadable(lazy(() => import('../../pages/account/Settings/Refresh')));
const Billing = Loadable(lazy(() => import('../../pages/account/Settings/billing')));

export const AccountRoutes = [
    {
        path: 'account',
        children: [
            {
                path: 'settings',
                element: <Settings />,
                children: [
                    { path: 'profile', element: <PersonalInformation /> },
                    { path: 'security', element: <Security /> },
                    { path: 'license', element: <License /> },
                    { path: 'information', element: <OrganizationInformation /> },
                    { path: 'connections', element: <Connections /> },
                    { path: 'notification', element: <Notification /> },
                    { path: 'sales', element: <Sales /> },
                    { path: 'product', element: <Product /> },
                    { path: 'refresh', element: <Refresh /> },
                    { path: 'billing', element: <Billing /> }
                ]
            }
        ]
    }
];
