import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const showNotification = (message, type = 'default', onClick = null) => {
    const options = onClick ? { onClick } : {};

    switch (type) {
        case 'success':
            toast.success(message, options);
            break;
        case 'error':
            toast.error(message, options);
            break;
        case 'info':
            toast.info(message, options);
            break;
        case 'warning':
            toast.warn(message, options);
            break;
        default:
            toast(message, options);
    }
};
