import { combineReducers } from 'redux';
import { companyAddReducer, companyCheckVatReducer, companySimpleListReducer, companyTagsListReducer } from './companyReducers';

const companyReducer = combineReducers({
    companyTagsList: companyTagsListReducer,
    companyCheckVat: companyCheckVatReducer,
    companyAdd: companyAddReducer,
    companySimpleList: companySimpleListReducer
});

export default companyReducer;
