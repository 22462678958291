import { createThunkAction } from '../../thunks/actions';

export const orderLastList = createThunkAction('order/last/list', 'statistics/dashboard/data/last_5_deliveries/?class=overview');
export const orderLastDeliveryList = createThunkAction(
    'order/last/delivery/list',
    'statistics/dashboard/data/last_5_orders/?class=overview'
);
export const orderCountReceived = createThunkAction('order/countReceived', 'statistics/dashboard/data/count_of_received_orders/');
export const orderList = createThunkAction('order/list', 'api/orders/list/', 'get', false);
export const orderUnitOptions = createThunkAction('orders/unit/options', 'system/simple-serializer/orders-products-unit/');
export const orderAdd = createThunkAction('order/add', 'orders/add/', 'post');
export const orderDelete = createThunkAction('order/delete', 'orders/delete/', 'delete');
export const orderDetails = createThunkAction('order/details', (identifier) => `orders/detail/${identifier}/`);
export const orderEdit = createThunkAction('order/edit', (identifier) => `orders/detail/${identifier}/update/`, 'put');
