import PropTypes from 'prop-types';
import { forwardRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { activeItem } from '../../../../../services/reducer/reducers/menuReducer';

const NavItem = ({ item, level }) => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();

    const { drawerOpen, openItem } = useSelector((state) => state.menu);

    let itemTarget = '_self';
    if (item.target) {
        itemTarget = '_blank';
    }

    let listItemProps = { component: forwardRef((props, ref) => <Link ref={ref} {...props} to={item.url} target={itemTarget} />) };
    if (item?.external) {
        listItemProps = { component: 'a', href: item.url, target: itemTarget };
    }

    const itemHandler = (id) => {
        dispatch(activeItem({ openItem: [id] }));
    };

    const Icon = item.icon;
    const itemIcon = item.icon ? <Icon stroke={1.75} size="1.25rem" style={{ opacity: 0.5 }} /> : false;

    const isSelected = openItem.findIndex((id) => id === item.id) > -1;

    useEffect(() => {
        if (pathname === item.url) {
            dispatch(activeItem({ openItem: [item.id] }));
        }
        // eslint-disable-next-line
    }, [pathname]);

    const textColor = 'rgb(238, 238, 238)';
    const iconSelectedColor = 'rgb(238, 238, 238)';

    return (
        !item.hide && (
            <ListItemButton
                {...listItemProps}
                disabled={item.disabled}
                onClick={() => itemHandler(item.id)}
                selected={isSelected}
                data-tour={item.tour ?? null}
                sx={{
                    zIndex: 1201,
                    pl: drawerOpen ? `${level * 28}px` : 1.5,
                    py: !drawerOpen && level === 1 ? 1.25 : 1,
                    color: 'white',
                    ...(drawerOpen && {
                        '&:hover': {
                            bgcolor: '#121820'
                        },
                        '&.Mui-selected': {
                            bgcolor: '#121820',
                            '&:hover': {
                                bgcolor: '#121820'
                            }
                        }
                    }),
                    ...(!drawerOpen && {
                        '&:hover': {
                            bgcolor: 'transparent'
                        },
                        '&.Mui-selected': {
                            '&:hover': {
                                bgcolor: 'transparent'
                            },
                            bgcolor: 'transparent'
                        }
                    })
                }}
            >
                {itemIcon && (
                    <ListItemIcon
                        sx={{
                            minWidth: 28,
                            color: isSelected ? iconSelectedColor : textColor,
                            ...(!drawerOpen && {
                                borderRadius: 1.5,
                                width: 36,
                                height: 36,
                                alignItems: 'center',
                                justifyContent: 'center',
                                '&:hover': {
                                    bgcolor: '#121820'
                                }
                            }),
                            ...(!drawerOpen &&
                                isSelected && {
                                    bgcolor: '#121820',
                                    '&:hover': {
                                        bgcolor: '#121820'
                                    }
                                })
                        }}
                    >
                        {itemIcon}
                    </ListItemIcon>
                )}
                {(drawerOpen || (!drawerOpen && level !== 1)) && (
                    <ListItemText
                        sx={{ pl: '8px' }}
                        primary={
                            <Typography variant="body1" sx={{ color: 'rgb(238, 238, 238)' }}>
                                {item.title}
                            </Typography>
                        }
                    />
                )}
                {(drawerOpen || (!drawerOpen && level !== 1)) && item.chip && (
                    <Chip
                        color={item.chip.color}
                        variant={item.chip.variant}
                        size={item.chip.size}
                        label={item.chip.label}
                        avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                    />
                )}
            </ListItemButton>
        )
    );
};

NavItem.propTypes = {
    item: PropTypes.object,
    level: PropTypes.number
};

export default NavItem;
