import { Close } from '@mui/icons-material';
import { Box, Divider, IconButton, Stack, Typography } from '@mui/material';
import React from 'react';
import CustomizationSettings from './settings';

const CustomizationDrawer = ({ onClose }) => {
    return (
        <Box>
            <Box p={2}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Stack direction="row" spacing={2}>
                        <Typography variant="h5">Layout settings</Typography>
                    </Stack>

                    <IconButton aria-label="close notification drawer" size="small" onClick={() => onClose()} color="error">
                        <Close fontSize="small" />
                    </IconButton>
                </Stack>
            </Box>
            <Divider />
            <CustomizationSettings />
        </Box>
    );
};

export default CustomizationDrawer;
