import { combineReducers } from 'redux';
import {
    deliveryCompanyAddReducer,
    deliveryCompanyDeleteReducer,
    deliveryCompanyDetailsReducer,
    deliveryCompanyEditReducer,
    deliveryCompanyListReducer,
    deliveryOrderAddReducer,
    deliveryOrderDeleteReducer,
    deliveryOrderDetailsReducer,
    deliveryOrderEditReducer,
    deliveryOrderHistoryListReducer,
    deliveryOrderListReducer,
    deliveryOrderSimpleListReducer,
    productsSimpleListReducer,
    warehouseAlertDeleteReducer,
    warehouseAlertEditReducer,
    warehouseAlertGlobalDetailsReducer,
    warehouseAlertProductReducer,
    warehouseAmountHistoryChartReducer,
    warehouseAmountImportFileReducer,
    warehouseAmountImportReducer,
    warehouseAmountRaportExportReducer,
    warehouseDeliveryAddReducer,
    warehouseDeliveryDeleteReducer,
    warehouseDeliveryEditReducer,
    warehouseDeliveryImportFileReducer,
    warehouseDeliveryListReducer,
    warehouseDeliveryProductListReducer,
    warehouseForecastProductAmountReducer,
    warehouseLowAmountReportExportReducer,
    warehouseNotificationAddReducer,
    warehouseNotificationProductListReducer,
    warehouseProductAmountListReducer
} from './warehouseReducers';

const warehouseReducer = combineReducers({
    warehouseNotificationAdd: warehouseNotificationAddReducer,
    warehouseNotificationProductList: warehouseNotificationProductListReducer,
    warehouseDeliveryAdd: warehouseDeliveryAddReducer,
    warehouseDeliveryProductList: warehouseDeliveryProductListReducer,
    warehouseDeliveryDelete: warehouseDeliveryDeleteReducer,
    warehouseDeliveryList: warehouseDeliveryListReducer,
    warehouseDeliveryEdit: warehouseDeliveryEditReducer,
    warehouseAmountRaportExport: warehouseAmountRaportExportReducer,
    warehouseAlertProduct: warehouseAlertProductReducer,
    warehouseAmountImport: warehouseAmountImportReducer,
    warehouseAmountImportFile: warehouseAmountImportFileReducer,
    warehouseDeliveryImportFile: warehouseDeliveryImportFileReducer,
    warehouseProductAmountList: warehouseProductAmountListReducer,
    warehouseAmountHistoryChart: warehouseAmountHistoryChartReducer,
    warehouseForecastProductAmount: warehouseForecastProductAmountReducer,
    productsSimpleList: productsSimpleListReducer,
    deliveryCompanyAdd: deliveryCompanyAddReducer,
    deliveryCompanyList: deliveryCompanyListReducer,
    deliveryCompanyDelete: deliveryCompanyDeleteReducer,
    deliveryCompanyDetails: deliveryCompanyDetailsReducer,
    deliveryCompanyEdit: deliveryCompanyEditReducer,
    deliveryOrderList: deliveryOrderListReducer,
    deliveryOrderAdd: deliveryOrderAddReducer,
    deliveryOrderSimpleList: deliveryOrderSimpleListReducer,
    deliveryOrderDetails: deliveryOrderDetailsReducer,
    deliveryOrderEdit: deliveryOrderEditReducer,
    deliveryOrderDelete: deliveryOrderDeleteReducer,
    deliveryOrderHistoryList: deliveryOrderHistoryListReducer,
    warehouseAlertGlobalDetails: warehouseAlertGlobalDetailsReducer,
    warehouseAlertEdit: warehouseAlertEditReducer,
    warehouseAlertDelete: warehouseAlertDeleteReducer,
    lowAmountReportExport: warehouseLowAmountReportExportReducer
});

export default warehouseReducer;
