import PropTypes from 'prop-types';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { useMemo } from 'react';
import ActionTableButton from '../../ui-component/buttons/ActionTableButton';

export const LocalTable = ({ columns, data = [], isLoading = false, rowActions, openPopup }) => {
    const memoizedColumns = useMemo(() => columns, [columns]);

    const table = useMaterialReactTable({
        columns: memoizedColumns,
        data: data,
        positionToolbarAlertBanner: 'bottom',
        enableRowActions: rowActions,
        renderRowActions: ({ row }) => rowActions && <ActionTableButton data={rowActions(row, openPopup)} />,
        state: {
            isLoading: isLoading
        },
        positionActionsColumn: 'last'
    });

    return <MaterialReactTable table={table} />;
};

LocalTable.propTypes = {
    columns: PropTypes.array.isRequired,
    isLoading: PropTypes.bool,
    topToolbarActions: PropTypes.array,
    data: PropTypes.array
};

export default LocalTable;
