import React from 'react';
import MainSlider from './MainSlider';
import InstructionsList from './InstructionsList';

const HelpCenter = () => {
    return (
        <>
            <MainSlider />
            <InstructionsList />
        </>
    );
};

export default HelpCenter;
