import { createSlice } from '@reduxjs/toolkit';
import { createInitialState } from '../../../utils/initialState';
import { createSubReducer } from '../../../utils/createReducer';
import {
    orderAdd,
    orderCountReceived,
    orderDelete,
    orderDetails,
    orderEdit,
    orderLastDeliveryList,
    orderLastList,
    orderList,
    orderUnitOptions
} from '../../../actions/order/actions';

const initialState = {
    countReceived: createInitialState({ loading: true }),
    lastList: createInitialState({ data: [] }),
    lastDeliveryList: createInitialState({ data: [] }),
    list: createInitialState({ data: [] }),
    unitOptions: createInitialState({ data: [] }),
    add: createInitialState(),
    delete: createInitialState(),
    details: createInitialState({ loading: true }),
    edit: createInitialState()
};

const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        resetOrderState: (state, action) => {
            const { key } = action.payload;
            state[key] = initialState[key];
        }
    },
    extraReducers: (builder) => {
        const actionsMap = {
            countReceived: orderCountReceived,
            lastList: orderLastList,
            lastDeliveryList: orderLastDeliveryList,
            list: orderList,
            unitOptions: orderUnitOptions,
            add: orderAdd,
            delete: orderDelete,
            details: orderDetails,
            edit: orderEdit
        };

        Object.keys(actionsMap).forEach((key) => {
            createSubReducer(builder, actionsMap[key], key);
        });
    }
});

export const { resetOrderState } = orderSlice.actions;
export default orderSlice.reducer;
