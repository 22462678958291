import { Warning } from '@mui/icons-material';
import { Avatar, Box, Button, Card, IconButton, List, ListItem, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import { formatDistance } from 'date-fns';
import React from 'react';
import { Link } from 'react-router-dom';
import { getFileIcon } from '../../../../../../../utils/files-type';

const FilesCard = ({ creator_user, label, date_created, is_priority, model, files }) => {
    return (
        <>
            <Box
                display="flex"
                alignItems="center"
                sx={{
                    padding: '10px 10px 10px 0px',
                    alignItems: 'flex-start',
                    width: '100%'
                }}
            >
                <Avatar alt="John Martinez" src="/avatars/1.png" />
                <Box ml={2} sx={{ width: '100%' }}>
                    <Typography variant="body1">
                        <a href="#" style={{ textDecoration: 'none', color: 'inherit', fontWeight: 'bold' }}>
                            {creator_user.first_name} {creator_user.last_name}
                        </a>{' '}
                        {label}
                    </Typography>
                    <Stack direction="row" spacing={1.5} alignItems="center">
                        <Typography variant="body2">
                            {formatDistance(new Date(date_created), new Date(), {
                                addSuffix: true
                            })}
                        </Typography>
                        <Box
                            sx={{
                                display: 'inline-flex',
                                width: '4px',
                                height: '4px',
                                backgroundColor: 'grey.400',
                                borderRadius: '72px'
                            }}
                        />
                        <Typography
                            variant="body2"
                            component={Link}
                            color="primary"
                            fontWeight={600}
                            sx={{
                                textDecoration: 'none',
                                '&:hover': {
                                    textDecoration: 'underline'
                                }
                            }}
                        >
                            {model}
                        </Typography>
                    </Stack>

                    <Card variant="outlined" sx={{ marginTop: 1.5 }}>
                        <List sx={{ padding: 0 }}>
                            {files.map((item, index) => (
                                <ListItem
                                    key={index}
                                    sx={{ paddingTop: 0, paddingBottom: 0, borderBottom: '1px solid', borderColor: 'divider' }}
                                >
                                    <ListItemIcon>{getFileIcon(item.extension)}</ListItemIcon>
                                    <ListItemText primary={item.name} secondary={item.size} />
                                    <Button variant="contained" color="primary" size="small">
                                        Download
                                    </Button>
                                </ListItem>
                            ))}
                        </List>
                    </Card>
                    <Button variant="outlined" sx={{ marginTop: 1.5 }}>
                        View
                    </Button>
                </Box>
            </Box>
            {is_priority && (
                <Box sx={{ padding: '10px 10px 10px 0px' }}>
                    <IconButton size="small" color="warning">
                        <Warning />
                    </IconButton>
                </Box>
            )}
        </>
    );
};

export default FilesCard;
