import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import InputLabel from '@mui/material/InputLabel';
import { OutlinedInput, FormHelperText, InputAdornment, FormControl, Tooltip, useTheme, Stack } from '@mui/material';
import { IconInfoSquareRounded } from '@tabler/icons-react';

const BaseInput = ({
    label,
    name,
    id,
    inputType,
    inputProps = {},
    disabled = false,
    placeholder = '',
    endAdornment,
    sx = {},
    handleBlur,
    handleChange,
    info = ''
}) => {
    const [field, meta] = useField(name);
    const { value, onBlur, onChange } = field;
    const { touched, error } = meta;

    const theme = useTheme();

    const handleBlurEvent = (e) => {
        onBlur(e);
        if (handleBlur) handleBlur(e);
    };

    const handleChangeEvent = (e) => {
        onChange(e);
        if (handleChange) handleChange(e);
    };

    return (
        <FormControl variant="standard" fullWidth error={Boolean(touched && error)}>
            <InputLabel shrink htmlFor={id} sx={{ transform: 'none' }}>
                <Stack direction="row" alignItems="center" spacing={1}>
                    <div>{label}</div>
                    {info && (
                        <Tooltip title={info} arrow>
                            <IconInfoSquareRounded size="1.35rem" style={{ cursor: 'pointer', color: theme.palette.info.main }} />
                        </Tooltip>
                    )}
                </Stack>
            </InputLabel>
            <OutlinedInput
                id={id}
                type={inputType}
                value={value}
                name={name}
                onBlur={handleBlurEvent}
                onChange={handleChangeEvent}
                inputProps={inputProps}
                placeholder={placeholder}
                disabled={disabled}
                sx={sx}
                endAdornment={<InputAdornment position="end">{endAdornment}</InputAdornment>}
                fullWidth
            />
            {touched && error && (
                <FormHelperText error id={`${id}-error`}>
                    {error}
                </FormHelperText>
            )}
        </FormControl>
    );
};

BaseInput.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    id: PropTypes.string,
    inputType: PropTypes.string,
    inputProps: PropTypes.any,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    endAdornment: PropTypes.node,
    sx: PropTypes.any,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func
};

export default BaseInput;
