import { createSlice } from '@reduxjs/toolkit';
import { createInitialState } from '../../../utils/initialState';
import { createSubReducer } from '../../../utils/createReducer';
import { tourDetails, tourView } from '../../../actions/tour/actions';

const initialState = {
    details: createInitialState({ loading: true }),
    view: createInitialState()
};

const tourSlice = createSlice({
    name: 'tour',
    initialState,
    reducers: {
        resetTourState: (state, action) => {
            const { key } = action.payload;
            state[key] = initialState[key];
        }
    },
    extraReducers: (builder) => {
        const actionsMap = {
            details: tourDetails,
            view: tourView
        };

        Object.keys(actionsMap).forEach((key) => {
            createSubReducer(builder, actionsMap[key], key);
        });
    }
});

export const { resetTourState } = tourSlice.actions;
export default tourSlice.reducer;
