import { initialState } from './initialState';

export const createReducer = (startType, successType, failType) => {
    return (state = initialState, action) => {
        switch (action.type) {
            case startType:
                return { ...state, loading: true, success: false };
            case successType:
                return { loading: false, data: action.data, error: null, success: true };
            case failType:
                return { loading: false, data: [], error: action.error, success: false };
            default:
                return state;
        }
    };
};

export const createSubReducer = (builder, asyncThunk, key, options = {}) => {
    const { onFulfilled, onRejected } = options;

    builder
        .addCase(asyncThunk.pending, (state) => {
            state[key].loading = true;
            state[key].error = null;
            state[key].success = false;
        })
        .addCase(asyncThunk.fulfilled, (state, action) => {
            state[key].loading = false;
            state[key].data = action.payload;
            state[key].success = true;
            state[key].error = null;

            if (onFulfilled) {
                onFulfilled(state, action);
            }
        })
        .addCase(asyncThunk.rejected, (state, action) => {
            state[key].loading = false;
            state[key].error = action.payload;
            state[key].success = false;

            if (onRejected) {
                onRejected(state, action);
            }
        });
};
