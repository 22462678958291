import { Badge, Box, Button, Drawer, IconButton, useMediaQuery } from '@mui/material';
import { IconBellRinging } from '@tabler/icons-react';
import NotificationDrawer from './NotificationDrawer';
import { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams, Link } from 'react-router-dom';
import { useUpdateSearchParams } from '../../../../../utils/hooks/params';
import {
    notificationCheck,
    notificationRead,
    notificationReadAll,
    notificationSystemList
} from '../../../../../services/actions/notification/actions';
import { resetNotificationState } from '../../../../../services/reducer/reducers/notification/slice';

const Notification = () => {
    const [searchParams] = useSearchParams();
    const updateSearchParams = useUpdateSearchParams();
    const [open, setOpen] = useState(searchParams.get('view') === 'notification');
    const [tab, setTab] = useState(searchParams.get('tab') || 'all');
    const [metaProcessed, setMetaProcessed] = useState(false);
    const [tabState, setTabState] = useState({
        all: { size: 9, maxLength: 9, filters: [], data: [] },
        priority: {
            size: 9,
            maxLength: 9,
            filters: [{ id: 'is_priority', value: 1 }],
            data: []
        },
        unread: { size: 9, maxLength: 9, filters: [{ id: 'is_read', value: 0 }], data: [] }
    });

    const dispatch = useDispatch();
    const prevTab = useRef(tab);

    const { data, loading } = useSelector((state) => state.notification.systemList);
    const { data: check } = useSelector((state) => state.notification.check);

    const matchesSm = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const isReadAll = useSelector((state) => state.notification.readAll.success);

    const handleScrollToEnd = useCallback(() => {
        setTabState((prevState) => ({
            ...prevState,
            [tab]: { ...prevState[tab], size: prevState[tab].size + 4 }
        }));
    }, [tab]);

    const handleDrawerToggle = () => {
        setOpen((prevOpen) => {
            if (!prevOpen) {
                updateSearchParams({ view: 'notification' });
            } else {
                updateSearchParams({ view: null, tab: null });
            }
            return !prevOpen;
        });
    };

    const handleTabChange = (newTab) => {
        setTab(newTab);
        updateSearchParams({ tab: newTab });
    };

    useEffect(() => {
        const currentTabState = tabState[tab];

        if (prevTab.current !== tab) {
            dispatch(resetNotificationState({ key: 'systemList' }));
            prevTab.current = tab;
        }

        if (currentTabState.size <= currentTabState.maxLength) {
            dispatch(
                notificationSystemList({
                    params: {
                        start: 0,
                        size: currentTabState.size,
                        filters: JSON.stringify(currentTabState.filters),
                        globalFilter: '',
                        sorting: JSON.stringify([])
                    }
                })
            );
        }
    }, [dispatch, tab, tabState[tab].size, isReadAll]);

    useEffect(() => {
        if (isReadAll) dispatch(resetNotificationState({ key: 'readAll' }));
    }, [dispatch, isReadAll]);

    useEffect(() => {
        dispatch(notificationCheck());

        const interval = setInterval(() => {
            dispatch(notificationCheck());
        }, 60000);

        return () => clearInterval(interval);
    }, [dispatch]);

    useEffect(() => {
        if (data && data.meta) {
            const currentTabData = tabState[tab].data;
            const isDataDifferent = JSON.stringify(currentTabData) !== JSON.stringify(data.data);

            if (currentTabData.length === 0 || isDataDifferent) {
                setTabState((prevState) => ({
                    ...prevState,
                    [tab]: { ...prevState[tab], data: data.data }
                }));
            }

            if (!metaProcessed) {
                setTabState((prevState) => ({
                    all: { ...prevState.all, maxLength: data.meta.all },
                    priority: { ...prevState.priority, maxLength: data.meta.priority },
                    unread: { ...prevState.unread, maxLength: data.meta.unread }
                }));
                setMetaProcessed(true);
            }
        }
    }, [data, tab, metaProcessed]);

    useEffect(() => {
        const view = searchParams.get('view');
        const tabParam = searchParams.get('tab');
        setOpen(view === 'notification');
        if (tabParam) {
            setTab(tabParam);
        }
    }, [searchParams]);

    const markAllAsRead = () => {
        setTabState((prevState) => {
            const updatedState = { ...prevState };
            Object.keys(updatedState).forEach((key) => {
                updatedState[key] = {
                    ...updatedState[key],
                    data: updatedState[key].data.map((notification) => ({
                        ...notification,
                        is_read: true
                    }))
                };
            });
            return updatedState;
        });
        dispatch(notificationReadAll());
    };

    const handleMarkAsRead = (identifier) => {
        setTabState((prevState) => {
            const updatedState = { ...prevState };
            Object.keys(updatedState).forEach((key) => {
                updatedState[key] = {
                    ...updatedState[key],
                    data: updatedState[key].data.map((notification) =>
                        notification.identifier === identifier ? { ...notification, is_read: true } : notification
                    )
                };
            });
            return updatedState;
        });
        dispatch(notificationRead({ id: identifier }));
    };

    return (
        <>
            <Box sx={{ flexShrink: 0, ml: 0.75 }} data-tour="step-2">
                <Badge
                    color={check?.details?.is_priority ? 'warning' : 'primary'}
                    badgeContent={check?.is_new ? check?.details?.count : 0}
                    max={9}
                >
                    <IconButton
                        sx={{ color: 'text.primary' }}
                        aria-label="open notifications"
                        aria-controls={'notification-grow'}
                        aria-haspopup="true"
                        onClick={handleDrawerToggle}
                    >
                        <IconBellRinging stroke={1.5} size="1.2rem" />
                    </IconButton>
                </Badge>
            </Box>
            <Drawer
                sx={{
                    width: matchesSm ? 360 : 420,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: matchesSm ? 360 : 420,
                        boxSizing: 'border-box'
                    }
                }}
                variant="temporary"
                anchor={'right'}
                open={open}
                onClose={handleDrawerToggle}
            >
                <NotificationDrawer
                    data={tabState[tab].data}
                    isLoading={loading}
                    onScrollToEnd={handleScrollToEnd}
                    setTab={handleTabChange}
                    tabValue={tab}
                    tabState={tabState}
                    onClose={handleDrawerToggle}
                    markAllAsRead={markAllAsRead}
                    handleMarkAsRead={handleMarkAsRead}
                />
                <Box p={1} sx={{ borderTop: 1, borderColor: 'divider' }}>
                    <Button component={Link} to="/notification" fullWidth>
                        View all
                    </Button>
                </Box>
            </Drawer>
        </>
    );
};

export default Notification;
