import { createRequestTypes } from '../actionsTypes';

export const WAREHOUSE_NOTIFICATION_ADD = createRequestTypes('WAREHOUSE_NOTIFICATION_ADD');
export const WAREHOUSE_NOTIFICATION_PRODUCT_LIST = createRequestTypes('WAREHOUSE_NOTIFICATION_PRODUCT_LIST');
export const WAREHOUSE_DELIVERY_ADD = createRequestTypes('WAREHOUSE_DELIVERY_ADD');
export const WAREHOUSE_DELIVERY_PRODUCT_LIST = createRequestTypes('WAREHOUSE_DELIVERY_PRODUCT_LIST');
export const WAREHOUSE_DELIVERY_DELETE = createRequestTypes('WAREHOUSE_DELIVERY_DELETE');
export const WAREHOUSE_DELIVERY_LIST = createRequestTypes('WAREHOUSE_DELIVERY_LIST');
export const WAREHOUSE_DELIVERY_EDIT = createRequestTypes('WAREHOUSE_DELIVERY_EDIT');
export const WAREHOUSE_AMOUNT_RAPORT_EXPORT = createRequestTypes('WAREHOUSE_AMOUNT_RAPORT_EXPORT');
export const WAREHOUSE_ALERT_PRODUCT = createRequestTypes('WAREHOUSE_ALERT_PRODUCT');
export const WAREHOUSE_AMOUNT_IMPORT = createRequestTypes('WAREHOUSE_AMOUNT_IMPORT');
export const WAREHOUSE_AMOUNT_IMPORT_FILE = createRequestTypes('WAREHOUSE_AMOUNT_IMPORT_FILE');
export const WAREHOUSE_DELIVERY_IMPORT_FILE = createRequestTypes('WAREHOUSE_DELIVERY_IMPORT_FILE');
export const WAREHOUSE_PRODUCT_AMOUNT_LIST = createRequestTypes('WAREHOUSE_PRODUCT_AMOUNT_LIST');
export const WAREHOUSE_AMOUNT_HISTORY_CHART = createRequestTypes('WAREHOUSE_AMOUNT_HISTORY_CHART');
export const WAREHOUSE_FORECAST_PRODUCT_AMOUNT = createRequestTypes('WAREHOUSE_FORECAST_PRODUCT_AMOUNT');
export const WAREHOUSE_ALERT_GLOBAL_DETAILS = createRequestTypes('WAREHOUSE_ALERT_GLOBAL_DETAILS');
export const PRODUCTS_SIMPLE_LIST = createRequestTypes('PRODUCTS_SIMPLE_LIST');
export const DELIVERY_COMPANY_ADD = createRequestTypes('DELIVERY_COMPANY_ADD');
export const DELIVERY_COMPANY_LIST = createRequestTypes('DELIVERY_COMPANY_LIST');
export const DELIVERY_COMPANY_DELETE = createRequestTypes('DELIVERY_COMPANY_DELETE');
export const DELIVERY_COMPANY_DETAILS = createRequestTypes('DELIVERY_COMPANY_DETAILS');
export const DELIVERY_COMPANY_EDIT = createRequestTypes('DELIVERY_COMPANY_EDIT');
export const DELIVERY_ORDER_LIST = createRequestTypes('DELIVERY_ORDER_LIST');
export const DELIVERY_ORDER_ADD = createRequestTypes('DELIVERY_ORDER_ADD');
export const DELIVERY_ORDER_SIMPLE_LIST = createRequestTypes('DELIVERY_ORDER_SIMPLE_LIST');
export const DELIVERY_ORDER_DETAILS = createRequestTypes('DELIVERY_ORDER_DETAILS');
export const DELIVERY_ORDER_EDIT = createRequestTypes('DELIVERY_ORDER_EDIT');
export const DELIVERY_ORDER_DELETE = createRequestTypes('DELIVERY_ORDER_DELETE');
export const DELIVERY_ORDER_HISTORY_LIST = createRequestTypes('DELIVERY_ORDER_HISTORY_LIST');
export const WAREHOUSE_ALERT_EDIT = createRequestTypes('WAREHOUSE_ALERT_EDIT');
export const WAREHOUSE_ALERT_DELETE = createRequestTypes('WAREHOUSE_ALERT_DELETE');
export const WAREHOUSE_LOW_AMOUNT_REPORT_EXPORT = createRequestTypes('WAREHOUSE_LOW_AMOUNT_REPORT_EXPORT');
