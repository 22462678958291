import { format, isValid, parseISO } from 'date-fns';

export const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, 'dd MMM yyyy');
};

export const formatFileDate = (date) => {
    const formattedDate = format(date, 'yyyy-MM-dd-HH-mm');
    return formattedDate;
};

export const formatDate2 = (dateString = null) => {
    if (typeof dateString !== 'string') return null;

    if (!dateString) return null;

    const date = parseISO(dateString);

    if (!isValid(date)) {
        return null;
    }

    return date.toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
    });
};

export const formatToLocalDate = (date) => {
    if (!date) return null;
    if (typeof date === 'string') return null;

    return date.toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
    });
};
