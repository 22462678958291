import {
    COSTS_ADD,
    COSTS_CATEGORY_LIST,
    COSTS_DELETE,
    COSTS_DETAILS,
    COSTS_EDIT,
    COSTS_HISTORY_LIST,
    COSTS_LIST,
    COSTS_NAME_LIST,
    COSTS_SIMPLE_LIST,
    COSTS_UNIT_LIST
} from '../../../actions/costs/costsActionsType';
import { globalGetReducer, globalPostReducer } from '../../helpers';

export const costsListReducer = globalGetReducer(COSTS_LIST.START, COSTS_LIST.SUCCESS, COSTS_LIST.FAIL);
export const costsAddReducer = globalPostReducer(COSTS_ADD.START, COSTS_ADD.SUCCESS, COSTS_ADD.FAIL, COSTS_ADD.RESET);
export const costsDetailsReducer = globalGetReducer(COSTS_DETAILS.START, COSTS_DETAILS.SUCCESS, COSTS_DETAILS.FAIL);
export const costsEditReducer = globalPostReducer(COSTS_EDIT.START, COSTS_EDIT.SUCCESS, COSTS_EDIT.FAIL, COSTS_EDIT.RESET);
export const costsDeleteReducer = globalPostReducer(COSTS_DELETE.START, COSTS_DELETE.SUCCESS, COSTS_DELETE.FAIL, COSTS_DELETE.RESET);
export const costsHistoryListReducer = globalGetReducer(COSTS_HISTORY_LIST.START, COSTS_HISTORY_LIST.SUCCESS, COSTS_HISTORY_LIST.FAIL);
export const costsSimpleListReducer = globalGetReducer(COSTS_SIMPLE_LIST.START, COSTS_SIMPLE_LIST.SUCCESS, COSTS_SIMPLE_LIST.FAIL);
export const costsNameListReducer = globalGetReducer(COSTS_NAME_LIST.START, COSTS_NAME_LIST.SUCCESS, COSTS_NAME_LIST.FAIL);
export const costsUnitListReducer = globalGetReducer(COSTS_UNIT_LIST.START, COSTS_UNIT_LIST.SUCCESS, COSTS_UNIT_LIST.FAIL);
export const costsCategoryListReducer = globalGetReducer(COSTS_CATEGORY_LIST.START, COSTS_CATEGORY_LIST.SUCCESS, COSTS_CATEGORY_LIST.FAIL);
