import { createThunkAction } from '../../thunks/actions';

export const costOptionsList = createThunkAction('cost/options/list', 'system/simple-serializer/costs/');
export const costNameOptions = createThunkAction('cost/options/name', 'system/simple-serializer/costs-name/');
export const costUnitOptions = createThunkAction('cost/options/unit', 'system/simple-serializer/costs-unit/');
export const costCategoryOptions = createThunkAction('cost/category/unit', 'system/simple-serializer/costs-categories/');
export const costList = createThunkAction('cost/list', 'api/costs/list/', 'get', false);
export const costAdd = createThunkAction('cost/add', 'costs/add/', 'post');
export const costDetails = createThunkAction('cost/details', (identifier) => `costs/detail/${identifier}/`, 'get');
export const costEdit = createThunkAction('cost/edit', (identifier) => `costs/detail/${identifier}/update/`, 'put');
export const costDelete = createThunkAction('cost/delete', 'costs/delete/', 'delete');
export const costHistoryList = createThunkAction('cost/historyList', (identifier) => `costs/detail/${identifier}/history/`, 'get');
