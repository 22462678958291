import { createSlice } from '@reduxjs/toolkit';
import { createInitialState } from '../../../utils/initialState';
import { createSubReducer } from '../../../utils/createReducer';
import { licenseAddUser, licenseCurrentDetails, licenseList } from '../../../actions/license/actions';

const initialState = {
    list: createInitialState({ data: [] }),
    addUser: createInitialState(),
    currentDetails: createInitialState({ loading: true })
};

const licenseSlice = createSlice({
    name: 'license',
    initialState,
    reducers: {
        resetLicenseState: (state, action) => {
            const { key } = action.payload;
            state[key] = initialState[key];
        }
    },
    extraReducers: (builder) => {
        const actionsMap = {
            list: licenseList,
            addUser: licenseAddUser,
            currentDetails: licenseCurrentDetails
        };

        Object.keys(actionsMap).forEach((key) => {
            createSubReducer(builder, actionsMap[key], key);
        });
    }
});

export const { resetLicenseState } = licenseSlice.actions;
export default licenseSlice.reducer;
