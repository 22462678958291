import { Close } from '@mui/icons-material';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import React from 'react';
import NoticiationTabs from './NoticiationTabs';
import NotificationList from './list';
import DoneAllIcon from '@mui/icons-material/DoneAll';

const NotificationDrawer = ({ data, onScrollToEnd, isLoading, setTab, tabValue, tabState, onClose, markAllAsRead, handleMarkAsRead }) => {
    return (
        <Box>
            <Box p={2}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Stack direction="row" spacing={2}>
                        <Typography variant="h5">Notifications</Typography>
                        {!isLoading && (
                            <IconButton aria-label="read all notifications" size="small" onClick={markAllAsRead} color="info">
                                <DoneAllIcon fontSize="small" />
                            </IconButton>
                        )}
                    </Stack>

                    <IconButton aria-label="close notification drawer" size="small" onClick={() => onClose()} color="error">
                        <Close fontSize="small" />
                    </IconButton>
                </Stack>
            </Box>
            <NoticiationTabs setTab={setTab} tabValue={tabValue} tabState={tabState} />
            <NotificationList data={data} onScrollToEnd={onScrollToEnd} isLoading={isLoading} handleMarkAsRead={handleMarkAsRead} />
        </Box>
    );
};

export default NotificationDrawer;
