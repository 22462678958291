import { combineReducers } from 'redux';
import {
    citiesListReducer,
    countriesListReducer,
    helpCenterCheckUrlReducer,
    helpCenterDetailsReducer,
    helpCenterListReducer,
    postCodeListReducer,
    siteAddToFavouriteReducer,
    siteFavouriteCheckReducer,
    siteRecentViewReducer,
    siteRoutesFavouritesListReducer,
    siteRoutesListReducer,
    slugInformationReducer,
    streetListReducer,
    systemExportFileDownloadReducer,
    systemTaskListReducer,
    systemTaskRefreshReducer,
    systemTemplateFileDownloadReducer,
    systemTemplateFileImportReducer,
    tourSiteInfoReducer,
    tourSiteViewReducer
} from './otherReducers';

const otherReducer = combineReducers({
    countriesList: countriesListReducer,
    citiesList: citiesListReducer,
    postCodeList: postCodeListReducer,
    streetList: streetListReducer,
    systemTemplateFileDownload: systemTemplateFileDownloadReducer,
    systemTemplateFileImport: systemTemplateFileImportReducer,
    systemExportFileDownload: systemExportFileDownloadReducer,
    siteRoutesList: siteRoutesListReducer,
    siteRoutesFavouritesList: siteRoutesFavouritesListReducer,
    siteRecentView: siteRecentViewReducer,
    siteAddToFavourite: siteAddToFavouriteReducer,
    helpCenterList: helpCenterListReducer,
    helpCenterDetails: helpCenterDetailsReducer,
    slugInformation: slugInformationReducer,
    tourSiteInfo: tourSiteInfoReducer,
    tourSiteView: tourSiteViewReducer,
    siteFavouriteCheck: siteFavouriteCheckReducer,
    helpCenterCheckUrl: helpCenterCheckUrlReducer,
    systemTaskList: systemTaskListReducer,
    systemTaskRefresh: systemTaskRefreshReducer
});

export default otherReducer;
