import { createRequestTypes } from '../actionsTypes';

export const CHAT_USERS_LIST = createRequestTypes('CHAT_USERS_LIST');
export const CHAT_ROOM_LIST = createRequestTypes('CHAT_ROOM_LIST');
export const CHAT_ROOM_MESSAGES_LIST = createRequestTypes('CHAT_ROOM_MESSAGES_LIST');
export const CHAT_ROOM_DETAILS = createRequestTypes('CHAT_ROOM_DETAILS');
export const CHAT_ROOM_ADD = createRequestTypes('CHAT_ROOM_ADD');
export const CHAT_ROOM_FILE_ADD = createRequestTypes('CHAT_ROOM_FILE_ADD');
export const CHAT_ROOM_FILE_LIST = createRequestTypes('CHAT_ROOM_FILE_LIST');
export const CHAT_ROOM_FILE_DOWNLOAD = createRequestTypes('CHAT_ROOM_FILE_DOWNLOAD');
export const CHAT_ROOM_FILE_DELETE = createRequestTypes('CHAT_ROOM_FILE_DELETE');
