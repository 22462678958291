import PropTypes from 'prop-types';
import { Dialog } from '@mui/material';
import ScrollBar from '../../third-party/ScrollBar';

const BasePopup = ({ close, open, fullWidth = true, maxWidth = 'md', content }) => {
    return (
        <Dialog open={open} onClose={close} fullWidth={fullWidth} maxWidth={maxWidth}>
            <ScrollBar
                options={{
                    wheelPropagation: false
                }}
                style={{ maxHeight: 'calc(100vh - 285px)' }}
            >
                {content}
            </ScrollBar>
        </Dialog>
    );
};

BasePopup.propTypes = {
    close: PropTypes.func,
    open: PropTypes.bool,
    fullWidth: PropTypes.bool,
    maxWidth: PropTypes.string,
    content: PropTypes.node
};

export default BasePopup;
