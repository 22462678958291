import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Loader from '../../components/ui-component/loader/Loader';
import MainLayout from '../../layout/MainLayout';
import MinimalLayout from '../../layout/MinimalLayout';

export const AuthGuard = ({ children }) => {
    const isAuthenticated = useSelector((state) => state.auth2.token);
    const isAuthorised = useSelector((state) => state.auth2.checkState.loading);

    if (isAuthorised) {
        return <Loader />;
    }

    return isAuthenticated ? children : <Navigate to="/login" />;
};

export const ThemeGuard = ({ children }) => {
    const isAuthenticated = useSelector((state) => state.auth2.token);
    const isAuthorised = useSelector((state) => state.auth2.checkState.loading);

    if (isAuthorised) {
        return <Loader />;
    }

    return isAuthenticated ? <Navigate to="/dashboard/overview" /> : children;
};

export const LayoutGuard = ({ children }) => {
    const isAuthenticated = useSelector((state) => state.auth2.token);
    const isAuthorised = useSelector((state) => state.auth2.checkState.loading);

    if (isAuthorised) {
        return <Loader />;
    }

    return isAuthenticated ? <MainLayout>{children}</MainLayout> : <MinimalLayout>{children}</MinimalLayout>;
};
