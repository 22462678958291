import { lazy } from 'react';
import Loadable from '../../components/Loadable';

const CostsList = Loadable(lazy(() => import('../../pages/costs/list')));

export const CostsRoutes = [
    {
        path: 'costs',
        children: [
            {
                path: '',
                element: <CostsList />
            }
        ]
    }
];
