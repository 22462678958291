import { Box, Grid } from '@mui/material';

import * as Yup from 'yup';
import { Formik } from 'formik';

import { useTranslation } from 'react-i18next';
import LoadingButton from '@mui/lab/LoadingButton';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import AnimateButton from '../../ui-component/buttons/AnimateButton';
import { fetchReset } from '../../../services/actions';
import BaseSwitch from '../../ui-component/switch/BaseSwitch';
import MultipleCreatableSelect from '../../ui-component/select/MultipleCreatableSelect';
import { chatUsersList } from '../../../services/actions/chat/chatActions';
import { systemExportFileDownload } from '../../../services/actions/other/otherActions';
import { SYSTEM_EXPORT_FILE_DOWNLOAD } from '../../../services/actions/other/otherActionsType';

const ExportForm = ({ data = [], model }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { success, loading } = useSelector((state) => state.other.systemExportFileDownload);
    const { data: usersOptions, loading: isLoadingUsersOptions } = useSelector((state) => state.chat.chatUsersList);

    const completeForm = (form) => {
        const formData = {
            identifier_list: data.map((row) => row.original.identifier),
            format_type: 'excel',
            is_email: form.isSendToEmail,
            email_receivers: form.receivers.map((item) => item.label),
            filter_by: null
        };

        dispatch(systemExportFileDownload(model, formData, `${model}.xlsx`));
    };

    useEffect(() => {
        dispatch(chatUsersList());
    }, [dispatch]);

    useEffect(() => {
        if (success) {
            dispatch(fetchReset(SYSTEM_EXPORT_FILE_DOWNLOAD));
        }
    }, [dispatch, success]);

    return (
        <>
            <Formik
                initialValues={{
                    receivers: [],
                    isSendToEmail: false
                }}
                validationSchema={Yup.object().shape({})}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    try {
                        setStatus({ success: false });
                        setSubmitting(false);
                        completeForm(values);
                    } catch (err) {
                        setStatus({ success: false });
                        setErrors({ submit: err.message });
                        setSubmitting(false);
                    }
                }}
            >
                {({ handleSubmit, isSubmitting, values }) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={3}>
                                <BaseSwitch name="isSendToEmail" label={t('input.label.isSendToEmail')} />
                            </Grid>
                            {values.isSendToEmail && (
                                <Grid item xs={12} md={9}>
                                    <MultipleCreatableSelect
                                        label={t('input.label.receivers')}
                                        name="receivers"
                                        options={usersOptions.map((item) => ({ value: item.email, label: item.email }))}
                                        isLoading={isLoadingUsersOptions}
                                    />
                                </Grid>
                            )}
                        </Grid>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'end',
                                mt: 2
                            }}
                        >
                            <AnimateButton>
                                <LoadingButton
                                    disableElevation
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    loading={isSubmitting || loading}
                                >
                                    Download
                                </LoadingButton>
                            </AnimateButton>
                        </Box>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default ExportForm;
