const config = {
    basename: '',
    defaultPath: '',
    fontFamily:
        'Nunito,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    borderRadius: 2,
    fontLeap: 0,
    i18n: 'en',
    mode: 'light',
    direction: 'ltr',
    drawer: 'default'
};

export default config;
