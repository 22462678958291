import React from 'react';
import ThemeMode from './ThemeMode';
import ThemeLayout from './ThemeLayout';
import FontFamily from './FontFamily';

const CustomizationSettings = () => {
    return (
        <>
            <ThemeMode />
            <ThemeLayout />
            <FontFamily />
        </>
    );
};

export default CustomizationSettings;
