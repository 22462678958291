import { lazy } from 'react';
import Loadable from '../../components/Loadable';

const OrderList = Loadable(lazy(() => import('../../pages/order/list')));

export const OrderRoutes = [
    {
        path: 'order',
        children: [
            {
                path: '',
                element: <OrderList />
            }
        ]
    }
];
