import { createSlice } from '@reduxjs/toolkit';
import { createInitialState } from '../../../utils/initialState';
import { createSubReducer } from '../../../utils/createReducer';
import {
    cityOptionsList,
    countryOptionsList,
    postCodeOptionsList,
    streetOptionsList,
    systemSlugDetails,
    systemTaskOptions,
    systemTaskRefresh
} from '../../../actions/system/actions';

const initialState = {
    countryOptions: createInitialState({ data: [] }),
    cityOptions: createInitialState({ data: [] }),
    postCodeOptions: createInitialState({ data: [] }),
    streetOptions: createInitialState({ data: [] }),
    taskOptions: createInitialState({ data: [] }),
    taskRefresh: createInitialState(),
    slugDetails: createInitialState({ loading: true })
};

const systemSlice = createSlice({
    name: 'system',
    initialState,
    reducers: {
        resetSystemState: (state, action) => {
            const { key } = action.payload;
            state[key] = initialState[key];
        }
    },
    extraReducers: (builder) => {
        const actionsMap = {
            countryOptions: countryOptionsList,
            cityOptions: cityOptionsList,
            postCodeOptions: postCodeOptionsList,
            streetOptions: streetOptionsList,
            taskOptions: systemTaskOptions,
            taskRefresh: systemTaskRefresh,
            slugDetails: systemSlugDetails
        };

        Object.keys(actionsMap).forEach((key) => {
            createSubReducer(builder, actionsMap[key], key);
        });
    }
});

export const { resetSystemState } = systemSlice.actions;
export default systemSlice.reducer;
