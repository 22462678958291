import { combineReducers } from 'redux';
import {
    pricingAddReducer,
    pricingDeleteReducer,
    pricingEditReducer,
    pricingHistoryListReducer,
    pricingProductDetailsReducer,
    pricingProductListReducer,
    productAddReducer,
    productBrandListReducer,
    productCalculateMomReducer,
    productCalculateQoqReducer,
    productCalculateWowReducer,
    productCalculateXoxReducer,
    productCalculateYoyReducer,
    productCategoriesListReducer,
    productDeleteReducer,
    productDetailsReducer,
    productEanListReducer,
    productEditReducer,
    productIdentifierListReducer,
    productNameListReducer,
    productPricingHistoryListReducer,
    productSkuListReducer,
    productsLicenseListReducer,
    productsListReducer,
    productSpecialIdentifierAddReducer,
    productSpecialIdentifierDeleteReducer,
    productSpecialIdentifierDetailsReducer,
    productSpecialIdentifierEditReducer,
    productSpecialIdentifierListReducer,
    specialIdentifierListReducer
} from './shopReducers';

const shopReducer = combineReducers({
    productsList: productsListReducer,
    productAdd: productAddReducer,
    productDetails: productDetailsReducer,
    productEdit: productEditReducer,
    productsLicenseList: productsLicenseListReducer,
    productDelete: productDeleteReducer,
    productCategoriesList: productCategoriesListReducer,
    productSkuList: productSkuListReducer,
    productEanList: productEanListReducer,
    productBrandList: productBrandListReducer,
    productNameList: productNameListReducer,
    productIdentifierList: productIdentifierListReducer,
    pricingAdd: pricingAddReducer,
    pricingProductList: pricingProductListReducer,
    pricingDelete: pricingDeleteReducer,
    pricingProductDetails: pricingProductDetailsReducer,
    pricingEdit: pricingEditReducer,
    pricingHistoryList: pricingHistoryListReducer,
    productPricingHistoryList: productPricingHistoryListReducer,
    productCalculateXox: productCalculateXoxReducer,
    productCalculateYoy: productCalculateYoyReducer,
    productCalculateWow: productCalculateWowReducer,
    productCalculateQoq: productCalculateQoqReducer,
    productCalculateMom: productCalculateMomReducer,
    productSpecialIdentifierList: productSpecialIdentifierListReducer,
    productSpecialIdentifierAdd: productSpecialIdentifierAddReducer,
    productSpecialIdentifierDelete: productSpecialIdentifierDeleteReducer,
    productSpecialIdentifierEdit: productSpecialIdentifierEditReducer,
    productSpecialIdentifierDetails: productSpecialIdentifierDetailsReducer,
    specialIdentifierList: specialIdentifierListReducer
});

export default shopReducer;
