import React, { useState } from 'react';
import { ListItem, ListItemIcon, ListItemText, Stack, Box, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { ShortcutIcon } from '../../../../../../utils/model-type';
import { useDispatch } from 'react-redux';
import { siteUrlFavouriteAdd, siteUrlRecentView } from '../../../../../../services/actions/siteUrl/actions';

const ListItemComponent = ({ url, model, title, description, index, is_favourite = false, onFavoriteToggle, handleClosePopup }) => {
    const [hovered, setHovered] = useState(false);

    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const mode = theme.palette.mode;

    return (
        <ListItem
            key={index}
            onClick={() => {
                dispatch(siteUrlRecentView({ id: url }));
                handleClosePopup();
                navigate(url);
            }}
            secondaryAction={
                <Stack direction="row" spacing={1}>
                    <Box
                        onMouseEnter={() => setHovered(true)}
                        onMouseLeave={() => setHovered(false)}
                        onClick={(e) => {
                            e.stopPropagation();
                            dispatch(siteUrlFavouriteAdd({ id: url }));
                            onFavoriteToggle();
                        }}
                    >
                        {hovered || is_favourite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                    </Box>
                    <KeyboardArrowRightIcon
                        sx={{
                            color: 'grey.500',
                            '.MuiListItem-root:hover &': {
                                color: 'primary.main'
                            }
                        }}
                    />
                </Stack>
            }
            sx={{
                cursor: 'pointer',
                '&:hover': {
                    backgroundColor: mode === 'light' ? 'grey.100' : 'rgba(255, 255, 255, 0.08)'
                },
                borderBottom: '1px solid',
                borderColor: 'divider',
                alignItems: 'center'
            }}
        >
            <ListItemIcon>
                <ShortcutIcon model={model} />
            </ListItemIcon>
            <ListItemText primary={title} secondary={description} />
        </ListItem>
    );
};

export default ListItemComponent;
