import { createThunkAction } from '../../thunks/actions';

export const productWohCalculate = createThunkAction(
    'product/wohCalculate',
    (product, vat, method) => `statistics/product/${product}/weeks-on-hand/calculate/?method=${method}&from_what_company=${vat}`
);
export const productPopularList = createThunkAction(
    'statistics/popularProducts',
    'statistics/dashboard/data/best_5_products_last_quarter_trend/?class=overview'
);
export const productWohSoaCalculate = createThunkAction(
    'product/wohSoaCalculate',
    (product, vat) => `statistics/product/${product}/weeks-on-hand/calculate/?from_what_company=${vat}`
);
export const productCount = createThunkAction('product/count', 'statistics/dashboard/data/count_of_products/');
export const productBestDerivativeSelling = createThunkAction(
    'product/bestDerivativeSelling',
    'statistics/dashboard/data/best_derivative_selling_products_from_raports/'
);
export const productBestSelling = createThunkAction('product/bestSelling', 'statistics/dashboard/data/best_selling_products_from_raports/');
export const productWorstSelling = createThunkAction(
    'product/worstSelling',
    'statistics/dashboard/data/worst_selling_products_from_raports/'
);
export const productList = createThunkAction('product/list', 'api/products/list', 'get', false);
export const productDelete = createThunkAction('product/delete', 'products/delete/', 'delete');
export const productSpecialIdentifierList = createThunkAction('product/specialIdentifier/list', 'products/special-identifier/list/');
export const productSpecialIdentifierDelete = createThunkAction(
    'product/specialIdentifier/delete',
    'products/special-identifier/delete/',
    'delete'
);
export const productOptionsList = createThunkAction('product/options/list', 'system/simple-serializer/product/');
export const productSpecialIdentifierAdd = createThunkAction('product/specialIdentifier/add', 'products/special-identifier/add/', 'post');
export const productSpecialIdentifierDetails = createThunkAction(
    'product/specialIdentifier/details',
    (id) => `products/special-identifier/detail/${id}/`
);
export const productSpecialIdentifierEdit = createThunkAction(
    'product/specialIdentifier/edit',
    (id) => `products/special-identifier/detail/${id}/update/`,
    'put'
);
export const productCategoryOptions = createThunkAction('product/options/category', 'system/simple-serializer/product-categories/');
export const productBrandOptions = createThunkAction('product/options/brand', 'system/simple-serializer/product-brand/');
export const productNameOptions = createThunkAction('product/options/name', 'system/simple-serializer/product-name/');
export const productSkuOptions = createThunkAction('product/options/sku', 'system/simple-serializer/product-sku/');
export const productEanOptions = createThunkAction('product/options/ean', 'system/simple-serializer/product-ean/');
export const productAdd = createThunkAction('product/add', 'products/add/', 'post');
export const productEdit = createThunkAction('product/edit', (id) => `products/detail/${id}/update/`, 'put');
export const productDetails = createThunkAction('product/details', (id) => `products/detail/${id}/`);
export const productBestDerivativeSell = createThunkAction(
    'product/bestDerivativeSell',
    'statistics/dashboard/data/best_derivative_selling_products_from_raports/'
);
export const productAlertDetails = createThunkAction('product/alert/details', (id) => `api/warehouse/alerts/product/${id}`, 'get', false);
export const productSelloutSum = createThunkAction('product/sellout/sum', (id) => `statistics/product/${id}/sellout-sum/calculate/`);
export const productCalculateWow = createThunkAction(
    'product/calculateWow',
    (identifier) => `products/detail/${identifier}/calculate/wow/`
);
export const productCalculateMom = createThunkAction(
    'product/calculateMom',
    (identifier) => `products/detail/${identifier}/calculate/mom/`
);
export const productCalculateQoq = createThunkAction(
    'product/calculateQoq',
    (identifier) => `products/detail/${identifier}/calculate/qoq/`
);
export const productCalculateYoy = createThunkAction(
    'product/calculateYoy',
    (identifier) => `products/detail/${identifier}/calculate/yoy/`
);
export const productAmountList = createThunkAction('product/amount/list', (id) => `api/warehouse/amount/real/${id}`, 'get', false);
export const productForecastAmount = createThunkAction(
    'product/forecast/amount',
    (id) => `statistics/predict/warehouse_real_amount/${id}/`,
    'post'
);
export const productCalculateWoh = createThunkAction(
    'product/calculateWoh',
    (product, method, vat) => `statistics/product/${product}/weeks-on-hand/calculate/?method=${method}&from_what_company=${vat}`
);
export const productSpecialIdentifierDetailsList = createThunkAction(
    'product/specialIdentifier/details/list',
    (type, id) => `products/special-identifier/filter/${type}/${id}/`
);
