import { createSlice } from '@reduxjs/toolkit';
import { createInitialState } from '../../../utils/initialState';
import { createSubReducer } from '../../../utils/createReducer';
import {
    productAdd,
    productAlertDetails,
    productAmountList,
    productBestDerivativeSell,
    productBestSelling,
    productBrandOptions,
    productCalculateMom,
    productCalculateQoq,
    productCalculateWoh,
    productCalculateWow,
    productCalculateYoy,
    productCategoryOptions,
    productCount,
    productDelete,
    productDetails,
    productEanOptions,
    productEdit,
    productForecastAmount,
    productList,
    productNameOptions,
    productOptionsList,
    productPopularList,
    productSelloutSum,
    productSkuOptions,
    productSpecialIdentifierAdd,
    productSpecialIdentifierDelete,
    productSpecialIdentifierDetails,
    productSpecialIdentifierDetailsList,
    productSpecialIdentifierEdit,
    productSpecialIdentifierList,
    productWorstSelling
} from '../../../actions/product/actions';

const initialState = {
    popularList: createInitialState({ loading: true }),
    count: createInitialState({ loading: true }),
    bestSelling: createInitialState({ loading: true }),
    worstSelling: createInitialState({ loading: true }),
    list: createInitialState({ data: [] }),
    delete: createInitialState(),
    specialIdentifierList: createInitialState({ data: [] }),
    specialIdentifierDelete: createInitialState(),
    options: createInitialState({ data: [] }),
    specialIdentifierAdd: createInitialState(),
    specialIdentifierDetails: createInitialState({ loading: true }),
    specialIdentifierEdit: createInitialState(),
    specialIdentifierDetailsList: createInitialState({ data: [] }),
    categoryOptions: createInitialState({ data: [] }),
    brandOptions: createInitialState({ data: [] }),
    nameOptions: createInitialState({ data: [] }),
    skuOptions: createInitialState({ data: [] }),
    eanOptions: createInitialState({ data: [] }),
    add: createInitialState(),
    edit: createInitialState(),
    details: createInitialState({ loading: true }),
    bestDerivativeSell: createInitialState({ loading: true }),
    selloutSum: createInitialState({ loading: true }),
    alertDetails: createInitialState({ loading: true }),
    calculateWow: createInitialState({ loading: true }),
    calculateMom: createInitialState({ loading: true }),
    calculateQoq: createInitialState({ loading: true }),
    calculateYoy: createInitialState({ loading: true }),
    calculateWoh: createInitialState({ loading: true }),
    amountList: createInitialState({ data: [] }),
    forecastAmount: createInitialState({ loading: true })
};

const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        resetProductState: (state, action) => {
            const { key } = action.payload;
            state[key] = initialState[key];
        }
    },
    extraReducers: (builder) => {
        const actionsMap = {
            popularList: productPopularList,
            count: productCount,
            bestSelling: productBestSelling,
            worstSelling: productWorstSelling,
            list: productList,
            delete: productDelete,
            specialIdentifierList: productSpecialIdentifierList,
            specialIdentifierDelete: productSpecialIdentifierDelete,
            specialIdentifierDetailsList: productSpecialIdentifierDetailsList,
            options: productOptionsList,
            specialIdentifierAdd: productSpecialIdentifierAdd,
            specialIdentifierDetails: productSpecialIdentifierDetails,
            specialIdentifierEdit: productSpecialIdentifierEdit,
            categoryOptions: productCategoryOptions,
            brandOptions: productBrandOptions,
            nameOptions: productNameOptions,
            skuOptions: productSkuOptions,
            eanOptions: productEanOptions,
            add: productAdd,
            edit: productEdit,
            details: productDetails,
            bestDerivativeSell: productBestDerivativeSell,
            alertDetails: productAlertDetails,
            selloutSum: productSelloutSum,
            calculateWow: productCalculateWow,
            calculateMom: productCalculateMom,
            calculateQoq: productCalculateQoq,
            calculateYoy: productCalculateYoy,
            calculateWoh: productCalculateWoh,
            amountList: productAmountList,
            forecastAmount: productForecastAmount
        };

        Object.keys(actionsMap).forEach((key) => {
            createSubReducer(builder, actionsMap[key], key);
        });
    }
});

export const { resetProductState } = productSlice.actions;
export default productSlice.reducer;
