import {
    AVERAGE_SELLOUT_ALLOWANCE,
    BEST_DERIVATIVE_SELLING_PRODUCTS,
    BEST_SELLING_PRODUCTS,
    CHART_AMOUNT_REPORT,
    CHART_CATEGORY_REPORT,
    CHART_DELIVERIES_COMPANIES,
    CHART_SELL_VALUE_RAPORT,
    COUNT_DELIVERY,
    COUNT_PRODUCTS,
    COUNT_RECEIVED_ORDERS,
    COUNT_SELLOUT_AMOUNT,
    COUNT_SELLOUT_VALUE,
    LAST_DELIVERIES,
    LAST_ORDERS,
    POPULAR_PRODUCTS,
    PRODUCT_WOH_CALCULATE,
    PRODUCT_WOH_SOA_CALCULATE,
    SALES_SPIKES_LIST,
    SELLOUT_SUM_CALCULATE,
    SELLOUT_VALUE,
    SELL_AMOUNT,
    SELL_VALUE_RAPORT_COMPANIES,
    SOA_ADD,
    STATISTICS_ADD,
    STATISTICS_BEST_PERFORM_SKU,
    STATISTICS_CALCULATE_AMOUNT_SELL,
    STATISTICS_CALCULATE_MOM,
    STATISTICS_CALCULATE_QOQ,
    STATISTICS_CALCULATE_STOCK_ON_HAND,
    STATISTICS_CALCULATE_WOW,
    STATISTICS_CALCULATE_XOX,
    STATISTICS_CALCULATE_YOY,
    STATISTICS_DASHBOARD_DEFAULT,
    STATISTICS_DASHBOARD_SESSIONS_LIST,
    STATISTICS_DASHBOARD_SIMPLE_LIST,
    STATISTICS_GENERATE,
    STATISTICS_REPORT_ABCD,
    STATISTICS_SELLOUT_ALLOWANCE_CALCULATE_DETAILS,
    STATISTICS_SET_DASHBOARD_DEFAULT,
    STATISTICS_WEEKS_ON_HAND_CALCULATE_DETAILS,
    TARGETS_ADD,
    TARGETS_DELETE,
    TARGETS_DETAILS,
    TARGETS_EDIT,
    TARGETS_LIST,
    TARGETS_REFRESH,
    TARGETS_TYPE_LIST,
    TARGET_MAIN,
    WORST_SELLING_PRODUCTS
} from '../../../actions/statistics/statisticsActionsType';

import { globalGetReducer, globalPostReducer } from '../../helpers';

export const statisticsAddReducer = globalPostReducer(
    STATISTICS_ADD.START,
    STATISTICS_ADD.SUCCESS,
    STATISTICS_ADD.FAIL,
    STATISTICS_ADD.RESET
);
export const statisticsDashboardDefaultReducer = globalPostReducer(
    STATISTICS_DASHBOARD_DEFAULT.START,
    STATISTICS_DASHBOARD_DEFAULT.SUCCESS,
    STATISTICS_DASHBOARD_DEFAULT.FAIL,
    STATISTICS_DASHBOARD_DEFAULT.RESET
);
export const statisticsGenerateReducer = globalGetReducer(STATISTICS_GENERATE.START, STATISTICS_GENERATE.SUCCESS, STATISTICS_GENERATE.FAIL);
export const statisticsSetDashboardDefaultReducer = globalPostReducer(
    STATISTICS_SET_DASHBOARD_DEFAULT.START,
    STATISTICS_SET_DASHBOARD_DEFAULT.SUCCESS,
    STATISTICS_SET_DASHBOARD_DEFAULT.FAIL,
    STATISTICS_SET_DASHBOARD_DEFAULT.RESET
);
export const statisticsDashboardSimpleListReducer = globalGetReducer(
    STATISTICS_DASHBOARD_SIMPLE_LIST.START,
    STATISTICS_DASHBOARD_SIMPLE_LIST.SUCCESS,
    STATISTICS_DASHBOARD_SIMPLE_LIST.FAIL
);
export const statisticsDashboardSessionsListReducer = globalGetReducer(
    STATISTICS_DASHBOARD_SESSIONS_LIST.START,
    STATISTICS_DASHBOARD_SESSIONS_LIST.SUCCESS,
    STATISTICS_DASHBOARD_SESSIONS_LIST.FAIL
);
export const statisticsSelloutAllowanceCalculateDetailsReducer = globalGetReducer(
    STATISTICS_SELLOUT_ALLOWANCE_CALCULATE_DETAILS.START,
    STATISTICS_SELLOUT_ALLOWANCE_CALCULATE_DETAILS.SUCCESS,
    STATISTICS_SELLOUT_ALLOWANCE_CALCULATE_DETAILS.FAIL
);
export const statisticsWeeksOnHandDetailsReducer = globalGetReducer(
    STATISTICS_WEEKS_ON_HAND_CALCULATE_DETAILS.START,
    STATISTICS_WEEKS_ON_HAND_CALCULATE_DETAILS.SUCCESS,
    STATISTICS_WEEKS_ON_HAND_CALCULATE_DETAILS.FAIL
);
export const targetsListReducer = globalGetReducer(TARGETS_LIST.START, TARGETS_LIST.SUCCESS, TARGETS_LIST.FAIL);
export const selloutSumCalculateReducer = globalGetReducer(
    SELLOUT_SUM_CALCULATE.START,
    SELLOUT_SUM_CALCULATE.SUCCESS,
    SELLOUT_SUM_CALCULATE.FAIL
);
export const targetsAddReducer = globalPostReducer(TARGETS_ADD.START, TARGETS_ADD.SUCCESS, TARGETS_ADD.FAIL, TARGETS_ADD.RESET);
export const targetsDetailsReducer = globalPostReducer(
    TARGETS_DETAILS.START,
    TARGETS_DETAILS.SUCCESS,
    TARGETS_DETAILS.FAIL,
    TARGETS_DETAILS.RESET
);
export const targetsEditReducer = globalPostReducer(TARGETS_EDIT.START, TARGETS_EDIT.SUCCESS, TARGETS_EDIT.FAIL, TARGETS_EDIT.RESET);
export const targetsDeleteReducer = globalPostReducer(
    TARGETS_DELETE.START,
    TARGETS_DELETE.SUCCESS,
    TARGETS_DELETE.FAIL,
    TARGETS_DELETE.RESET
);
export const bestSellingProductsReducer = globalGetReducer(
    BEST_SELLING_PRODUCTS.START,
    BEST_SELLING_PRODUCTS.SUCCESS,
    BEST_SELLING_PRODUCTS.FAIL
);
export const worstSellingProductsReducer = globalGetReducer(
    WORST_SELLING_PRODUCTS.START,
    WORST_SELLING_PRODUCTS.SUCCESS,
    WORST_SELLING_PRODUCTS.FAIL
);
export const bestDerivativeSellingProductsReducer = globalGetReducer(
    BEST_DERIVATIVE_SELLING_PRODUCTS.START,
    BEST_DERIVATIVE_SELLING_PRODUCTS.SUCCESS,
    BEST_DERIVATIVE_SELLING_PRODUCTS.FAIL
);

export const targetsTypeListReducer = globalGetReducer(TARGETS_TYPE_LIST.START, TARGETS_TYPE_LIST.SUCCESS, TARGETS_TYPE_LIST.FAIL);
export const countReceivedOrdersReducer = globalGetReducer(
    COUNT_RECEIVED_ORDERS.START,
    COUNT_RECEIVED_ORDERS.SUCCESS,
    COUNT_RECEIVED_ORDERS.FAIL
);
export const countDeliveryReducer = globalGetReducer(COUNT_DELIVERY.START, COUNT_DELIVERY.SUCCESS, COUNT_DELIVERY.FAIL);
export const countSelloutValueReducer = globalGetReducer(COUNT_SELLOUT_VALUE.START, COUNT_SELLOUT_VALUE.SUCCESS, COUNT_SELLOUT_VALUE.FAIL);
export const countSelloutAmountReducer = globalGetReducer(
    COUNT_SELLOUT_AMOUNT.START,
    COUNT_SELLOUT_AMOUNT.SUCCESS,
    COUNT_SELLOUT_AMOUNT.FAIL
);
export const countProductsReducer = globalGetReducer(COUNT_PRODUCTS.START, COUNT_PRODUCTS.SUCCESS, COUNT_PRODUCTS.FAIL);
export const chartDeliveriesCompaniesReducer = globalGetReducer(
    CHART_DELIVERIES_COMPANIES.START,
    CHART_DELIVERIES_COMPANIES.SUCCESS,
    CHART_DELIVERIES_COMPANIES.FAIL
);
export const targetMainReducer = globalGetReducer(TARGET_MAIN.START, TARGET_MAIN.SUCCESS, TARGET_MAIN.FAIL);
export const lastOrdersReducer = globalGetReducer(LAST_ORDERS.START, LAST_ORDERS.SUCCESS, LAST_ORDERS.FAIL);
export const lastDeliveriesReducer = globalGetReducer(LAST_DELIVERIES.START, LAST_DELIVERIES.SUCCESS, LAST_DELIVERIES.FAIL);
export const averageSelloutAllowanceReducer = globalGetReducer(
    AVERAGE_SELLOUT_ALLOWANCE.START,
    AVERAGE_SELLOUT_ALLOWANCE.SUCCESS,
    AVERAGE_SELLOUT_ALLOWANCE.FAIL
);
export const selloutValueReducer = globalGetReducer(SELLOUT_VALUE.START, SELLOUT_VALUE.SUCCESS, SELLOUT_VALUE.FAIL);
export const sellAmountReducer = globalGetReducer(SELL_AMOUNT.START, SELL_AMOUNT.SUCCESS, SELL_AMOUNT.FAIL);
export const chartSellValueRaportReducer = globalGetReducer(
    CHART_SELL_VALUE_RAPORT.START,
    CHART_SELL_VALUE_RAPORT.SUCCESS,
    CHART_SELL_VALUE_RAPORT.FAIL
);
export const chartCategoryReportReducer = globalGetReducer(
    CHART_CATEGORY_REPORT.START,
    CHART_CATEGORY_REPORT.SUCCESS,
    CHART_CATEGORY_REPORT.FAIL
);
export const chartAmountReportReducer = globalGetReducer(CHART_AMOUNT_REPORT.START, CHART_AMOUNT_REPORT.SUCCESS, CHART_AMOUNT_REPORT.FAIL);
export const popularProductsReducer = globalGetReducer(POPULAR_PRODUCTS.START, POPULAR_PRODUCTS.SUCCESS, POPULAR_PRODUCTS.FAIL);
export const sellValueRaportCompaniesReducer = globalGetReducer(
    SELL_VALUE_RAPORT_COMPANIES.START,
    SELL_VALUE_RAPORT_COMPANIES.SUCCESS,
    SELL_VALUE_RAPORT_COMPANIES.FAIL
);
export const salesSpikesListReducer = globalGetReducer(SALES_SPIKES_LIST.START, SALES_SPIKES_LIST.SUCCESS, SALES_SPIKES_LIST.FAIL);
export const productWohCalculateReducer = globalGetReducer(
    PRODUCT_WOH_CALCULATE.START,
    PRODUCT_WOH_CALCULATE.SUCCESS,
    PRODUCT_WOH_CALCULATE.FAIL
);
export const targetsRefreshReducer = globalPostReducer(
    TARGETS_REFRESH.START,
    TARGETS_REFRESH.SUCCESS,
    TARGETS_REFRESH.FAIL,
    TARGETS_REFRESH.RESET
);
export const statisticsReportAbcdReducer = globalPostReducer(
    STATISTICS_REPORT_ABCD.START,
    STATISTICS_REPORT_ABCD.SUCCESS,
    STATISTICS_REPORT_ABCD.FAIL,
    STATISTICS_REPORT_ABCD.RESET
);
export const statisticsCalculateXoxReducer = globalGetReducer(
    STATISTICS_CALCULATE_XOX.START,
    STATISTICS_CALCULATE_XOX.SUCCESS,
    STATISTICS_CALCULATE_XOX.FAIL
);
export const statisticsCalculateWowReducer = globalGetReducer(
    STATISTICS_CALCULATE_WOW.START,
    STATISTICS_CALCULATE_WOW.SUCCESS,
    STATISTICS_CALCULATE_WOW.FAIL
);
export const statisticsCalculateMomReducer = globalGetReducer(
    STATISTICS_CALCULATE_MOM.START,
    STATISTICS_CALCULATE_MOM.SUCCESS,
    STATISTICS_CALCULATE_MOM.FAIL
);
export const statisticsCalculateQoqReducer = globalGetReducer(
    STATISTICS_CALCULATE_QOQ.START,
    STATISTICS_CALCULATE_QOQ.SUCCESS,
    STATISTICS_CALCULATE_QOQ.FAIL
);
export const statisticsCalculateYoyReducer = globalGetReducer(
    STATISTICS_CALCULATE_YOY.START,
    STATISTICS_CALCULATE_YOY.SUCCESS,
    STATISTICS_CALCULATE_YOY.FAIL
);
export const statisticsCalculateStockOnHandReducer = globalGetReducer(
    STATISTICS_CALCULATE_STOCK_ON_HAND.START,
    STATISTICS_CALCULATE_STOCK_ON_HAND.SUCCESS,
    STATISTICS_CALCULATE_STOCK_ON_HAND.FAIL
);
export const statisticsCalculateAmountSellReducer = globalGetReducer(
    STATISTICS_CALCULATE_AMOUNT_SELL.START,
    STATISTICS_CALCULATE_AMOUNT_SELL.SUCCESS,
    STATISTICS_CALCULATE_AMOUNT_SELL.FAIL
);
export const productWohSoaCalculateReducer = globalGetReducer(
    PRODUCT_WOH_SOA_CALCULATE.START,
    PRODUCT_WOH_SOA_CALCULATE.SUCCESS,
    PRODUCT_WOH_SOA_CALCULATE.FAIL
);
export const soaAddReducer = globalPostReducer(SOA_ADD.START, SOA_ADD.SUCCESS, SOA_ADD.FAIL, SOA_ADD.RESET);
export const statisticsBestPerformSkuReducer = globalGetReducer(
    STATISTICS_BEST_PERFORM_SKU.START,
    STATISTICS_BEST_PERFORM_SKU.SUCCESS,
    STATISTICS_BEST_PERFORM_SKU.FAIL
);