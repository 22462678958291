import { createSlice } from '@reduxjs/toolkit';
import { createInitialState } from '../../../utils/initialState';
import { createSubReducer } from '../../../utils/createReducer';
import { alertAdd, alertDelete, alertEdit } from '../../../actions/alert/actions';

const initialState = {
    add: createInitialState(),
    edit: createInitialState(),
    delete: createInitialState()
};

const alertSlice = createSlice({
    name: 'alert',
    initialState,
    reducers: {
        resetAlertState: (state, action) => {
            const { key } = action.payload;
            state[key] = initialState[key];
        }
    },
    extraReducers: (builder) => {
        const actionsMap = {
            add: alertAdd,
            edit: alertEdit,
            delete: alertDelete
        };

        Object.keys(actionsMap).forEach((key) => {
            createSubReducer(builder, actionsMap[key], key);
        });
    }
});

export const { resetAlertState } = alertSlice.actions;
export default alertSlice.reducer;
