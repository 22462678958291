import { combineReducers } from 'redux';
import menuReducer from './reducers/menuReducer';
import authReducer from './reducers/auth';
import userReducer from './reducers/user';
import licenseReducer from './reducers/license';
import shopReducer from './reducers/shop';
import warehouseReducer from './reducers/warehouse';
import statusReducer from './reducers/status';
import companyReducer from './reducers/company';
import chatReducer from './reducers/chat';
import salesReducer from './reducers/sales';
import otherReducer from './reducers/other';
import statisticsReducer from './reducers/statistics';
import costsReducer from './reducers/costs';
import orderReducer from './reducers/order';
import forecastReducer from './reducers/forecast';
import customizationReducer from './reducers/customization';
import marketWatcherReducer from './reducers/marketWatcher/reducer';
import notificationReducer from './reducers/notification/reducer';
import salesReducer2 from './reducers/sales/reducer';
import statisticsReducer2 from './reducers/statistics/reducer';
import productReducer from './reducers/product/reducer';
import userReducer2 from './reducers/user/reducer';
import orderReducer2 from './reducers/order/reducer';
import targetReducer from './reducers/target/reducer';
import soaReducer from './reducers/soa/reducer';
import companyReducer2 from './reducers/company/reducer';
import costReducer from './reducers/cost/reducer';
import deliveryReducer from './reducers/delivery/reducer';
import statusReducer2 from './reducers/status/reducer';
import systemReducer from './reducers/system/reducer';
import warehouseReducer2 from './reducers/warehouse/reducer';
import forecastReducer2 from './reducers/forecast/reducer';
import noteReducer from './reducers/note/reducer';
import alertReducer from './reducers/alert/reducer';
import pricingReducer from './reducers/pricing/reducer';
import marginReducer from './reducers/margin/reducer';
import creditNoteReducer from './reducers/creditNote/reducer';
import authReducer2 from './reducers/auth/reducer';
import coleReducer from './reducers/cole/reducer';
import licenseReducer2 from './reducers/license/reducer';
import dynamicUrlReducer from './reducers/dynamicUrl/reducer';
import siteUrlReducer from './reducers/siteUrl/reducer';
import tourViewReducer from './reducers/tour/reducer';

const reducer = combineReducers({
    alert: alertReducer,
    customization: customizationReducer,
    menu: menuReducer,
    auth: authReducer,
    auth2: authReducer2,
    user: userReducer,
    user2: userReducer2,
    license: licenseReducer,
    license2: licenseReducer2,
    shop: shopReducer,
    status: statusReducer,
    status2: statusReducer2,
    note: noteReducer,
    delivery: deliveryReducer,
    warehouse: warehouseReducer,
    warehouse2: warehouseReducer2,
    company: companyReducer,
    company2: companyReducer2,
    chat: chatReducer,
    sales: salesReducer,
    other: otherReducer,
    notification: notificationReducer,
    statistics: statisticsReducer,
    costs: costsReducer,
    cost: costReducer,
    order: orderReducer,
    order2: orderReducer2,
    forecast: forecastReducer,
    forecast2: forecastReducer2,
    marketWatcher: marketWatcherReducer,
    sales2: salesReducer2,
    statistics2: statisticsReducer2,
    product: productReducer,
    target: targetReducer,
    soa: soaReducer,
    system: systemReducer,
    pricing: pricingReducer,
    margin: marginReducer,
    creditNote: creditNoteReducer,
    cole: coleReducer,
    dynamicUrl: dynamicUrlReducer,
    siteUrl: siteUrlReducer,
    tour: tourViewReducer
});

export default reducer;
