import { combineReducers } from 'redux';
import {
    statusAddReducer,
    statusDeleteReducer,
    statusEditReducer,
    statusListReducer,
    statusOrderChangeReducer,
    statusSimpleListReducer
} from './statusReducers';

const statusReducer = combineReducers({
    statusList: statusListReducer,
    statusSimpleList: statusSimpleListReducer,
    statusAdd: statusAddReducer,
    statusDelete: statusDeleteReducer,
    statusEdit: statusEditReducer,
    statusOrderChange: statusOrderChangeReducer
});

export default statusReducer;
