import { combineReducers } from 'redux';
import {
    salesReportListReducer,
    salesReportDeleteReducer,
    salesReportColumnsListReducer,
    salesReportAddReducer,
    salesReportDetailsReducer,
    salesReportDownloadReducer,
    salesReportImportReducer,
    salesReportTemplateListReducer,
    salesReportTemplateDeleteReducer,
    salesReportTemplateColumnsListReducer,
    salesReportTemplateAddReducer,
    salesReportTemplateDetailsReducer,
    salesReportRowEditReducer,
    salesReportTemplateEditReducer,
    salesReportSimpleListReducer,
    salesReportConfigureReducer,
    salesReportDetailsProductsReducer,
    salesReportDetailsDateReducer,
    salesReportDetailsListReducer,
    salesReportRowDeleteReducer,
    salesReportRowAddReducer,
    salesReportRowListReducer,
    salesWeeksOnHandConfigReducer,
    salesWeeksOnHandConfigDetailsReducer,
    salesSelloutAllowanceDetailsReducer,
    salesSelloutAllowanceConfigReducer,
    salesReportRefreshReducer,
    salesReportGenerateDetailsReducer,
    salesMarginAddReducer,
    salesMarginDetailsReducer,
    salesMarginGlobalDetailsReducer,
    salesMarginDeleteReducer,
    salesMarginListReducer,
    salesCreditNoteAddReducer,
    salesCreditNoteGlobalDetailsReducer,
    salesMarginEditReducer,
    salesCreditNoteEditReducer,
    salesCreditNoteDetailsReducer,
    salesCreditNoteListReducer,
    salesCreditNoteDeleteReducer,
    salesReportCostsAddReducer,
    salesReportDetailsNetValueReducer
} from './salesReducers';

const salesReducer = combineReducers({
    salesReportList: salesReportListReducer,
    salesReportDelete: salesReportDeleteReducer,
    salesReportColumnsList: salesReportColumnsListReducer,
    salesReportAdd: salesReportAddReducer,
    salesReportDetails: salesReportDetailsReducer,
    salesReportDownload: salesReportDownloadReducer,
    salesReportImport: salesReportImportReducer,
    salesReportTemplateList: salesReportTemplateListReducer,
    salesReportTemplateDelete: salesReportTemplateDeleteReducer,
    salesReportTemplateColumnsList: salesReportTemplateColumnsListReducer,
    salesReportTemplateAdd: salesReportTemplateAddReducer,
    salesReportTemplateDetails: salesReportTemplateDetailsReducer,
    salesReportRowEdit: salesReportRowEditReducer,
    salesReportTemplateEdit: salesReportTemplateEditReducer,
    salesReportSimpleList: salesReportSimpleListReducer,
    salesReportConfigure: salesReportConfigureReducer,
    salesReportDetailsProducts: salesReportDetailsProductsReducer,
    salesReportDetailsDate: salesReportDetailsDateReducer,
    salesReportDetailsList: salesReportDetailsListReducer,
    reportRowDelete: salesReportRowDeleteReducer,
    reportRowAdd: salesReportRowAddReducer,
    reportRowList: salesReportRowListReducer,
    weeksOnHandConfig: salesWeeksOnHandConfigReducer,
    weeksOnHandConfigDetails: salesWeeksOnHandConfigDetailsReducer,
    selloutAllowanceDetails: salesSelloutAllowanceDetailsReducer,
    selloutAllowanceConfig: salesSelloutAllowanceConfigReducer,
    reportRefresh: salesReportRefreshReducer,
    reportGenerateDetails: salesReportGenerateDetailsReducer,
    marginAdd: salesMarginAddReducer,
    marginDetails: salesMarginDetailsReducer,
    marginGlobalDetails: salesMarginGlobalDetailsReducer,
    marginDelete: salesMarginDeleteReducer,
    marginList: salesMarginListReducer,
    creditNoteAdd: salesCreditNoteAddReducer,
    creditNoteGlobalDetails: salesCreditNoteGlobalDetailsReducer,
    marginEdit: salesMarginEditReducer,
    creditNoteEdit: salesCreditNoteEditReducer,
    creditNoteDetails: salesCreditNoteDetailsReducer,
    creditNoteList: salesCreditNoteListReducer,
    creditNoteDelete: salesCreditNoteDeleteReducer,
    reportCostsAdd: salesReportCostsAddReducer,
    reportDetailsNetValue: salesReportDetailsNetValueReducer
});

export default salesReducer;
