import PropTypes from 'prop-types';
import { MRT_Table, useMaterialReactTable } from 'material-react-table';

export const SubRowTable = ({ data, columns }) => {
    const table = useMaterialReactTable({
        columns,
        data,
        enableColumnActions: false,
        enableColumnFilters: false,
        enablePagination: false,
        enableSorting: false,
        muiTableProps: {
            sx: {
                '& .MuiTableRow-root': {
                    boxShadow: 'none'
                }
            }
        }
    });

    return <MRT_Table table={table} />;
};

SubRowTable.propTypes = {
    data: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired
};

export default SubRowTable;
