import { createSlice } from '@reduxjs/toolkit';
import { createInitialState } from '../../../utils/initialState';
import { createSubReducer } from '../../../utils/createReducer';
import {
    notificationCheck,
    notificationColumnOptions,
    notificationEdit,
    notificationList,
    notificationRead,
    notificationReadAll,
    notificationReportOptions,
    notificationSystemAllList,
    notificationSystemList,
    notificationSystemWarnList
} from '../../../actions/notification/actions';

const initialState = {
    list: createInitialState({ loading: true }),
    edit: createInitialState(),
    systemList: createInitialState({ data: [] }),
    systemWarnList: createInitialState({ data: [] }),
    read: createInitialState(),
    readAll: createInitialState(),
    systemAllList: createInitialState({ data: [] }),
    reportOptions: createInitialState({ data: [] }),
    columnOptions: createInitialState({ data: [] }),
    check: createInitialState({ loading: true })
};

const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        resetNotificationState: (state, action) => {
            const { key } = action.payload;
            state[key] = initialState[key];
        }
    },
    extraReducers: (builder) => {
        const actionsMap = {
            list: notificationList,
            edit: notificationEdit,
            systemList: notificationSystemList,
            systemWarnList: notificationSystemWarnList,
            read: notificationRead,
            readAll: notificationReadAll,
            systemAllList: notificationSystemAllList,
            reportOptions: notificationReportOptions,
            columnOptions: notificationColumnOptions,
            check: notificationCheck
        };

        Object.keys(actionsMap).forEach((key) => {
            createSubReducer(builder, actionsMap[key], key);
        });
    }
});

export const { resetNotificationState } = notificationSlice.actions;
export default notificationSlice.reducer;
