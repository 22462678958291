import {
    SALES_CREDIT_NOTE_ADD,
    SALES_CREDIT_NOTE_DELETE,
    SALES_CREDIT_NOTE_DETAILS,
    SALES_CREDIT_NOTE_EDIT,
    SALES_CREDIT_NOTE_GLOBAL_DETAILS,
    SALES_CREDIT_NOTE_LIST,
    SALES_MARGIN_ADD,
    SALES_MARGIN_DELETE,
    SALES_MARGIN_DETAILS,
    SALES_MARGIN_EDIT,
    SALES_MARGIN_GLOBAL_DETAILS,
    SALES_MARGIN_LIST,
    SALES_REPORT_ADD,
    SALES_REPORT_COLUMNS_LIST,
    SALES_REPORT_CONFIGURE,
    SALES_REPORT_COSTS_ADD,
    SALES_REPORT_DELETE,
    SALES_REPORT_DETAILS,
    SALES_REPORT_DETAILS_DATE,
    SALES_REPORT_DETAILS_LIST,
    SALES_REPORT_DETAILS_NET_VALUE,
    SALES_REPORT_DETAILS_PRODUCTS,
    SALES_REPORT_DOWNLOAD,
    SALES_REPORT_GENERATE_DETAILS,
    SALES_REPORT_IMPORT,
    SALES_REPORT_LIST,
    SALES_REPORT_REFRESH,
    SALES_REPORT_ROW_ADD,
    SALES_REPORT_ROW_DELETE,
    SALES_REPORT_ROW_EDIT,
    SALES_REPORT_ROW_LIST,
    SALES_REPORT_SIMPLE_LIST,
    SALES_REPORT_TEMPLATE_ADD,
    SALES_REPORT_TEMPLATE_COLUMNS_LIST,
    SALES_REPORT_TEMPLATE_DELETE,
    SALES_REPORT_TEMPLATE_DETAILS,
    SALES_REPORT_TEMPLATE_EDIT,
    SALES_REPORT_TEMPLATE_LIST,
    SALES_SELLOUT_ALLOWANCE_CONFIG,
    SALES_SELLOUT_ALLOWANCE_DETAILS,
    SALES_WEEKS_ON_HAND_CONFIG,
    SALES_WEEKS_ON_HAND_CONFIG_DETAILS
} from '../../../actions/sales/salesActionsType';
import { globalGetReducer, globalPostReducer } from '../../helpers';

export const salesReportListReducer = globalGetReducer(SALES_REPORT_LIST.START, SALES_REPORT_LIST.SUCCESS, SALES_REPORT_LIST.FAIL);
export const salesReportDeleteReducer = globalPostReducer(
    SALES_REPORT_DELETE.START,
    SALES_REPORT_DELETE.SUCCESS,
    SALES_REPORT_DELETE.FAIL,
    SALES_REPORT_DELETE.RESET
);
export const salesReportColumnsListReducer = globalGetReducer(
    SALES_REPORT_COLUMNS_LIST.START,
    SALES_REPORT_COLUMNS_LIST.SUCCESS,
    SALES_REPORT_COLUMNS_LIST.FAIL
);
export const salesReportAddReducer = globalPostReducer(
    SALES_REPORT_ADD.START,
    SALES_REPORT_ADD.SUCCESS,
    SALES_REPORT_ADD.FAIL,
    SALES_REPORT_ADD.RESET
);
export const salesReportDetailsReducer = globalGetReducer(
    SALES_REPORT_DETAILS.START,
    SALES_REPORT_DETAILS.SUCCESS,
    SALES_REPORT_DETAILS.FAIL
);
export const salesReportDownloadReducer = globalGetReducer(
    SALES_REPORT_DOWNLOAD.START,
    SALES_REPORT_DOWNLOAD.SUCCESS,
    SALES_REPORT_DOWNLOAD.FAIL
);
export const salesReportImportReducer = globalPostReducer(
    SALES_REPORT_IMPORT.START,
    SALES_REPORT_IMPORT.SUCCESS,
    SALES_REPORT_IMPORT.FAIL,
    SALES_REPORT_IMPORT.RESET
);
export const salesReportTemplateListReducer = globalPostReducer(
    SALES_REPORT_TEMPLATE_LIST.START,
    SALES_REPORT_TEMPLATE_LIST.SUCCESS,
    SALES_REPORT_TEMPLATE_LIST.FAIL,
    SALES_REPORT_TEMPLATE_LIST.RESET
);
export const salesReportTemplateDeleteReducer = globalPostReducer(
    SALES_REPORT_TEMPLATE_DELETE.START,
    SALES_REPORT_TEMPLATE_DELETE.SUCCESS,
    SALES_REPORT_TEMPLATE_DELETE.FAIL,
    SALES_REPORT_TEMPLATE_DELETE.RESET
);
export const salesReportTemplateColumnsListReducer = globalGetReducer(
    SALES_REPORT_TEMPLATE_COLUMNS_LIST.START,
    SALES_REPORT_TEMPLATE_COLUMNS_LIST.SUCCESS,
    SALES_REPORT_TEMPLATE_COLUMNS_LIST.FAIL
);
export const salesReportTemplateAddReducer = globalPostReducer(
    SALES_REPORT_TEMPLATE_ADD.START,
    SALES_REPORT_TEMPLATE_ADD.SUCCESS,
    SALES_REPORT_TEMPLATE_ADD.FAIL,
    SALES_REPORT_TEMPLATE_ADD.RESET
);
export const salesReportTemplateDetailsReducer = globalGetReducer(
    SALES_REPORT_TEMPLATE_DETAILS.START,
    SALES_REPORT_TEMPLATE_DETAILS.SUCCESS,
    SALES_REPORT_TEMPLATE_DETAILS.FAIL
);
export const salesReportRowEditReducer = globalPostReducer(
    SALES_REPORT_ROW_EDIT.START,
    SALES_REPORT_ROW_EDIT.SUCCESS,
    SALES_REPORT_ROW_EDIT.FAIL,
    SALES_REPORT_ROW_EDIT.RESET
);
export const salesReportTemplateEditReducer = globalPostReducer(
    SALES_REPORT_TEMPLATE_EDIT.START,
    SALES_REPORT_TEMPLATE_EDIT.SUCCESS,
    SALES_REPORT_TEMPLATE_EDIT.FAIL,
    SALES_REPORT_TEMPLATE_EDIT.RESET
);
export const salesReportSimpleListReducer = globalGetReducer(
    SALES_REPORT_SIMPLE_LIST.START,
    SALES_REPORT_SIMPLE_LIST.SUCCESS,
    SALES_REPORT_SIMPLE_LIST.FAIL
);
export const salesReportConfigureReducer = globalPostReducer(
    SALES_REPORT_CONFIGURE.START,
    SALES_REPORT_CONFIGURE.SUCCESS,
    SALES_REPORT_CONFIGURE.FAIL,
    SALES_REPORT_CONFIGURE.RESET
);
export const salesReportDetailsProductsReducer = globalGetReducer(
    SALES_REPORT_DETAILS_PRODUCTS.START,
    SALES_REPORT_DETAILS_PRODUCTS.SUCCESS,
    SALES_REPORT_DETAILS_PRODUCTS.FAIL
);
export const salesReportDetailsDateReducer = globalGetReducer(
    SALES_REPORT_DETAILS_DATE.START,
    SALES_REPORT_DETAILS_DATE.SUCCESS,
    SALES_REPORT_DETAILS_DATE.FAIL
);
export const salesReportDetailsListReducer = globalGetReducer(
    SALES_REPORT_DETAILS_LIST.START,
    SALES_REPORT_DETAILS_LIST.SUCCESS,
    SALES_REPORT_DETAILS_LIST.FAIL
);
export const salesReportRowDeleteReducer = globalPostReducer(
    SALES_REPORT_ROW_DELETE.START,
    SALES_REPORT_ROW_DELETE.SUCCESS,
    SALES_REPORT_ROW_DELETE.FAIL,
    SALES_REPORT_ROW_DELETE.RESET
);
export const salesReportRowAddReducer = globalPostReducer(
    SALES_REPORT_ROW_ADD.START,
    SALES_REPORT_ROW_ADD.SUCCESS,
    SALES_REPORT_ROW_ADD.FAIL,
    SALES_REPORT_ROW_ADD.RESET
);
export const salesReportRowListReducer = globalGetReducer(
    SALES_REPORT_ROW_LIST.START,
    SALES_REPORT_ROW_LIST.SUCCESS,
    SALES_REPORT_ROW_LIST.FAIL
);
export const salesWeeksOnHandConfigReducer = globalPostReducer(
    SALES_WEEKS_ON_HAND_CONFIG.START,
    SALES_WEEKS_ON_HAND_CONFIG.SUCCESS,
    SALES_WEEKS_ON_HAND_CONFIG.FAIL,
    SALES_WEEKS_ON_HAND_CONFIG.RESET
);
export const salesWeeksOnHandConfigDetailsReducer = globalGetReducer(
    SALES_WEEKS_ON_HAND_CONFIG_DETAILS.START,
    SALES_WEEKS_ON_HAND_CONFIG_DETAILS.SUCCESS,
    SALES_WEEKS_ON_HAND_CONFIG_DETAILS.FAIL
);
export const salesSelloutAllowanceDetailsReducer = globalGetReducer(
    SALES_SELLOUT_ALLOWANCE_DETAILS.START,
    SALES_SELLOUT_ALLOWANCE_DETAILS.SUCCESS,
    SALES_SELLOUT_ALLOWANCE_DETAILS.FAIL
);
export const salesSelloutAllowanceConfigReducer = globalPostReducer(
    SALES_SELLOUT_ALLOWANCE_CONFIG.START,
    SALES_SELLOUT_ALLOWANCE_CONFIG.SUCCESS,
    SALES_SELLOUT_ALLOWANCE_CONFIG.FAIL,
    SALES_SELLOUT_ALLOWANCE_CONFIG.RESET
);
export const salesReportRefreshReducer = globalPostReducer(
    SALES_REPORT_REFRESH.START,
    SALES_REPORT_REFRESH.SUCCESS,
    SALES_REPORT_REFRESH.FAIL,
    SALES_REPORT_REFRESH.RESET
);
export const salesReportGenerateDetailsReducer = globalGetReducer(
    SALES_REPORT_GENERATE_DETAILS.START,
    SALES_REPORT_GENERATE_DETAILS.SUCCESS,
    SALES_REPORT_GENERATE_DETAILS.FAIL
);
export const salesMarginAddReducer = globalPostReducer(
    SALES_MARGIN_ADD.START,
    SALES_MARGIN_ADD.SUCCESS,
    SALES_MARGIN_ADD.FAIL,
    SALES_MARGIN_ADD.RESET
);
export const salesMarginDetailsReducer = globalGetReducer(
    SALES_MARGIN_DETAILS.START,
    SALES_MARGIN_DETAILS.SUCCESS,
    SALES_MARGIN_DETAILS.FAIL
);
export const salesMarginGlobalDetailsReducer = globalGetReducer(
    SALES_MARGIN_GLOBAL_DETAILS.START,
    SALES_MARGIN_GLOBAL_DETAILS.SUCCESS,
    SALES_MARGIN_GLOBAL_DETAILS.FAIL
);
export const salesMarginListReducer = globalGetReducer(SALES_MARGIN_LIST.START, SALES_MARGIN_LIST.SUCCESS, SALES_MARGIN_LIST.FAIL);
export const salesMarginDeleteReducer = globalPostReducer(
    SALES_MARGIN_DELETE.START,
    SALES_MARGIN_DELETE.SUCCESS,
    SALES_MARGIN_DELETE.FAIL,
    SALES_MARGIN_DELETE.RESET
);
export const salesCreditNoteAddReducer = globalPostReducer(
    SALES_CREDIT_NOTE_ADD.START,
    SALES_CREDIT_NOTE_ADD.SUCCESS,
    SALES_CREDIT_NOTE_ADD.FAIL,
    SALES_CREDIT_NOTE_ADD.RESET
);
export const salesCreditNoteGlobalDetailsReducer = globalGetReducer(
    SALES_CREDIT_NOTE_GLOBAL_DETAILS.START,
    SALES_CREDIT_NOTE_GLOBAL_DETAILS.SUCCESS,
    SALES_CREDIT_NOTE_GLOBAL_DETAILS.FAIL
);
export const salesMarginEditReducer = globalPostReducer(
    SALES_MARGIN_EDIT.START,
    SALES_MARGIN_EDIT.SUCCESS,
    SALES_MARGIN_EDIT.FAIL,
    SALES_MARGIN_EDIT.RESET
);
export const salesCreditNoteEditReducer = globalPostReducer(
    SALES_CREDIT_NOTE_EDIT.START,
    SALES_CREDIT_NOTE_EDIT.SUCCESS,
    SALES_CREDIT_NOTE_EDIT.FAIL,
    SALES_CREDIT_NOTE_EDIT.RESET
);
export const salesCreditNoteDetailsReducer = globalGetReducer(
    SALES_CREDIT_NOTE_DETAILS.START,
    SALES_CREDIT_NOTE_DETAILS.SUCCESS,
    SALES_CREDIT_NOTE_DETAILS.FAIL
);
export const salesCreditNoteListReducer = globalGetReducer(
    SALES_CREDIT_NOTE_LIST.START,
    SALES_CREDIT_NOTE_LIST.SUCCESS,
    SALES_CREDIT_NOTE_LIST.FAIL
);
export const salesCreditNoteDeleteReducer = globalPostReducer(
    SALES_CREDIT_NOTE_DELETE.START,
    SALES_CREDIT_NOTE_DELETE.SUCCESS,
    SALES_CREDIT_NOTE_DELETE.FAIL,
    SALES_CREDIT_NOTE_DELETE.RESET
);
export const salesReportCostsAddReducer = globalPostReducer(
    SALES_REPORT_COSTS_ADD.START,
    SALES_REPORT_COSTS_ADD.SUCCESS,
    SALES_REPORT_COSTS_ADD.FAIL,
    SALES_REPORT_COSTS_ADD.RESET
);
export const salesReportDetailsNetValueReducer = globalPostReducer(
    SALES_REPORT_DETAILS_NET_VALUE.START,
    SALES_REPORT_DETAILS_NET_VALUE.SUCCESS,
    SALES_REPORT_DETAILS_NET_VALUE.FAIL,
    SALES_REPORT_DETAILS_NET_VALUE.RESET
);
