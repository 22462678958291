export const initialState = {
    loading: true,
    data: [],
    error: null,
    success: false
};

export const createInitialState = (extraFields = {}) => ({
    loading: false,
    data: null,
    error: null,
    success: false,
    ...extraFields
});
