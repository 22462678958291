import { combineReducers } from 'redux';
import {
    averageSelloutAllowanceReducer,
    bestDerivativeSellingProductsReducer,
    bestSellingProductsReducer,
    chartAmountReportReducer,
    chartCategoryReportReducer,
    chartDeliveriesCompaniesReducer,
    chartSellValueRaportReducer,
    countDeliveryReducer,
    countProductsReducer,
    countReceivedOrdersReducer,
    countSelloutAmountReducer,
    countSelloutValueReducer,
    lastDeliveriesReducer,
    lastOrdersReducer,
    popularProductsReducer,
    productWohCalculateReducer,
    productWohSoaCalculateReducer,
    salesSpikesListReducer,
    sellAmountReducer,
    sellValueRaportCompaniesReducer,
    selloutSumCalculateReducer,
    selloutValueReducer,
    soaAddReducer,
    statisticsAddReducer,
    statisticsBestPerformSkuReducer,
    statisticsCalculateAmountSellReducer,
    statisticsCalculateMomReducer,
    statisticsCalculateQoqReducer,
    statisticsCalculateStockOnHandReducer,
    statisticsCalculateWowReducer,
    statisticsCalculateXoxReducer,
    statisticsCalculateYoyReducer,
    statisticsDashboardDefaultReducer,
    statisticsDashboardSessionsListReducer,
    statisticsDashboardSimpleListReducer,
    statisticsGenerateReducer,
    statisticsReportAbcdReducer,
    statisticsSelloutAllowanceCalculateDetailsReducer,
    statisticsSetDashboardDefaultReducer,
    statisticsWeeksOnHandDetailsReducer,
    targetMainReducer,
    targetsAddReducer,
    targetsDeleteReducer,
    targetsDetailsReducer,
    targetsEditReducer,
    targetsListReducer,
    targetsRefreshReducer,
    targetsTypeListReducer,
    worstSellingProductsReducer
} from './statisticsReducers';

const statisticsReducer = combineReducers({
    add: statisticsAddReducer,
    dashboardDefault: statisticsDashboardDefaultReducer,
    generate: statisticsGenerateReducer,
    setDashboardDefault: statisticsSetDashboardDefaultReducer,
    dashboardSimpleList: statisticsDashboardSimpleListReducer,
    dashboardSessionsList: statisticsDashboardSessionsListReducer,
    selloutAllowanceCalculateDetails: statisticsSelloutAllowanceCalculateDetailsReducer,
    weeksOnHandDetails: statisticsWeeksOnHandDetailsReducer,
    targetsList: targetsListReducer,
    selloutSumCalculate: selloutSumCalculateReducer,
    targetsAdd: targetsAddReducer,
    targetsDetails: targetsDetailsReducer,
    targetsEdit: targetsEditReducer,
    targetsDelete: targetsDeleteReducer,
    bestSellingProducts: bestSellingProductsReducer,
    worstSellingProducts: worstSellingProductsReducer,
    bestDerivativeSellingProducts: bestDerivativeSellingProductsReducer,
    targetsTypeList: targetsTypeListReducer,
    countReceivedOrders: countReceivedOrdersReducer,
    countDelivery: countDeliveryReducer,
    countSelloutValue: countSelloutValueReducer,
    countSelloutAmount: countSelloutAmountReducer,
    countProducts: countProductsReducer,
    chartDeliveriesCompanies: chartDeliveriesCompaniesReducer,
    targetMain: targetMainReducer,
    lastOrders: lastOrdersReducer,
    lastDeliveries: lastDeliveriesReducer,
    averageSelloutAllowance: averageSelloutAllowanceReducer,
    selloutValue: selloutValueReducer,
    sellAmount: sellAmountReducer,
    chartSellValueRaport: chartSellValueRaportReducer,
    chartCategoryReport: chartCategoryReportReducer,
    chartAmountReport: chartAmountReportReducer,
    popularProducts: popularProductsReducer,
    sellValueRaportCompanies: sellValueRaportCompaniesReducer,
    salesSpikesList: salesSpikesListReducer,
    productWohCalculate: productWohCalculateReducer,
    targetsRefresh: targetsRefreshReducer,
    reportAbcd: statisticsReportAbcdReducer,
    calculateXox: statisticsCalculateXoxReducer,
    calculateWow: statisticsCalculateWowReducer,
    calculateMom: statisticsCalculateMomReducer,
    calculateQoq: statisticsCalculateQoqReducer,
    calculateYoy: statisticsCalculateYoyReducer,
    statisticsCalculateStockOnHand: statisticsCalculateStockOnHandReducer,
    statisticsCalculateAmountSell: statisticsCalculateAmountSellReducer,
    productWohSoaCalculate: productWohSoaCalculateReducer,
    soaAdd: soaAddReducer,
    bestPerformSku: statisticsBestPerformSkuReducer
});

export default statisticsReducer;
