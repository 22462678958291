import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogContentText, DialogActions, Button, styled, Skeleton } from '@mui/material';
import BootstrapDialogTitle from './BootstrapDialogTitle';

const StandardPopup = ({
    close,
    open,
    fullWidth = true,
    maxWidth = 'md',
    title,
    loading = false,
    content,
    icon1,
    icon2,
    label1,
    label2,
    request1,
    request2
}) => {
    return (
        <BootstrapDialog onClose={close} aria-labelledby="customized-dialog-title" open={open} fullWidth={fullWidth} maxWidth={maxWidth}>
            <BootstrapDialogTitle id="customized-dialog-title" onClose={close}>
                {title}
            </BootstrapDialogTitle>
            <DialogContent dividers>
                <DialogContentText id="alert-dialog-description">
                    {loading ? (
                        <>
                            <Skeleton variant="text" width={300} height={20} />
                            <Skeleton variant="text" width={250} height={16} />
                        </>
                    ) : (
                        content
                    )}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {loading ? (
                    <>
                        <Skeleton variant="rectangular" width={100} height={36} />
                        <Skeleton variant="rectangular" width={100} height={36} />
                    </>
                ) : (
                    <>
                        <Button variant="contained" onClick={request1} endIcon={icon1} color="primary">
                            {label1}
                        </Button>
                        <Button variant="contained" onClick={request2} endIcon={icon2} color="primary">
                            {label2}
                        </Button>
                    </>
                )}
            </DialogActions>
        </BootstrapDialog>
    );
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2)
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1)
    }
}));

StandardPopup.propTypes = {
    close: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    fullWidth: PropTypes.bool,
    maxWidth: PropTypes.string,
    title: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    content: PropTypes.node.isRequired,
    icon1: PropTypes.node.isRequired,
    icon2: PropTypes.node.isRequired,
    label1: PropTypes.string.isRequired,
    label2: PropTypes.string.isRequired,
    request1: PropTypes.func.isRequired,
    request2: PropTypes.func.isRequired
};

export default StandardPopup;
