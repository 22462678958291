import { lazy } from 'react';
import Loadable from '../../components/Loadable';

const SalesList = Loadable(lazy(() => import('../../pages/sales/list')));
const ReportDetails = Loadable(lazy(() => import('../../pages/sales/report/details')));
const SalesReportAdd = Loadable(lazy(() => import('../../pages/sales/report/add')));
const SalesTemplateAdd = Loadable(lazy(() => import('../../pages/sales/template/add')));
const ReportTemplateDetails = Loadable(lazy(() => import('../../pages/sales/template/details')));
const SalesTemplateEdit = Loadable(lazy(() => import('../../pages/sales/template/edit')));
const SalesReportEdit = Loadable(lazy(() => import('../../pages/sales/report/edit')));
const ReportList = Loadable(lazy(() => import('../../pages/sales/report/list')));
const TemplateList = Loadable(lazy(() => import('../../pages/sales/template/list')));
const SalesAlertList = Loadable(lazy(() => import('../../pages/sales/alert/list')));
const ReportInformation = Loadable(lazy(() => import('../../pages/sales/report/details/information')));
const ReportCosts = Loadable(lazy(() => import('../../pages/sales/report/details/costs')));
const ReportAlert = Loadable(lazy(() => import('../../pages/sales/report/details/alert')));
const TemplateInformation = Loadable(lazy(() => import('../../pages/sales/template/details/information')));
const CompareReportList = Loadable(lazy(() => import('../../pages/sales/compareReport/list')));

export const SalesRoutes = [
    {
        path: 'sales',
        children: [
            {
                path: 'list',
                element: <SalesList />,
                children: [
                    { path: 'report', element: <ReportList /> },
                    { path: 'template', element: <TemplateList /> },
                    { path: 'alert', element: <SalesAlertList /> },
                    { path: 'compare-report', element: <CompareReportList /> }
                ]
            },
            {
                path: 'report/add',
                element: <SalesReportAdd />
            },
            {
                path: 'report/:id/edit',
                element: <SalesReportEdit />
            },
            {
                path: 'report/:id/details',
                element: <ReportDetails />,
                children: [
                    { path: 'information', element: <ReportInformation /> },
                    { path: 'costs', element: <ReportCosts /> },
                    { path: 'alert', element: <ReportAlert /> }
                ]
            },
            {
                path: 'template/:id/details',
                element: <ReportTemplateDetails />,
                children: [{ path: 'information', element: <TemplateInformation /> }]
            },
            {
                path: 'template/add',
                element: <SalesTemplateAdd />
            },
            {
                path: 'template/:id/edit',
                element: <SalesTemplateEdit />
            }
        ]
    }
];
