import { createSlice } from '@reduxjs/toolkit';
import { createInitialState } from '../../../utils/initialState';
import { createSubReducer } from '../../../utils/createReducer';
import { warehouseAlertEdit, warehouseAlertGlobalDetails, warehouseAmountHistoryChart } from '../../../actions/warehouse/actions';

const initialState = {
    amountHistoryChart: createInitialState({ loading: true }),
    alertGlobalDetails: createInitialState({ loading: true }),
    alertEdit: createInitialState()
};

const warehouseSlice = createSlice({
    name: 'warehouse',
    initialState,
    reducers: {
        resetWarehouseState: (state, action) => {
            const { key } = action.payload;
            state[key] = initialState[key];
        }
    },
    extraReducers: (builder) => {
        const actionsMap = {
            amountHistoryChart: warehouseAmountHistoryChart,
            alertGlobalDetails: warehouseAlertGlobalDetails,
            alertEdit: warehouseAlertEdit
        };

        Object.keys(actionsMap).forEach((key) => {
            createSubReducer(builder, actionsMap[key], key);
        });
    }
});

export const { resetWarehouseState } = warehouseSlice.actions;
export default warehouseSlice.reducer;
