import { LICENSE_ADD_USER, LICENSE_CURRENT_DETAILS, LICENSE_LIST } from '../../../actions/license/licenseActionsType';
import { globalGetReducer, globalPostReducer } from '../../helpers';

export const licenseListReducer = globalGetReducer(LICENSE_LIST.START, LICENSE_LIST.SUCCESS, LICENSE_LIST.FAIL);
export const licenseAddUserReducer = globalPostReducer(
    LICENSE_ADD_USER.START,
    LICENSE_ADD_USER.SUCCESS,
    LICENSE_ADD_USER.FAIL,
    LICENSE_ADD_USER.RESET
);
export const licenseCurrentDetailsReducer = globalGetReducer(
    LICENSE_CURRENT_DETAILS.START,
    LICENSE_CURRENT_DETAILS.SUCCESS,
    LICENSE_CURRENT_DETAILS.FAIL
);
