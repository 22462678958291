import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box, Card, Divider, Grid, Stack, Typography } from '@mui/material';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import { IconChevronRight } from '@tabler/icons-react';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import HomeIcon from '@mui/icons-material/Home';
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import config from '../../config';
import { gridSpacing } from '../../layout/constant';
import FavoritePage from '../favorites';

const linkSX = {
    display: 'flex',
    color: 'grey.900',
    textDecoration: 'none',
    alignContent: 'center',
    alignItems: 'center'
};

const Breadcrumbs = ({ card, divider, icon, icons, maxItems, navigation, rightAlign, title, titleBottom, identifier, ...others }) => {
    const [main, setMain] = useState();
    const [item, setItem] = useState();

    const theme = useTheme();
    const { pathname, search } = useLocation();

    const url = pathname + search;

    const iconStyle = {
        marginRight: theme.spacing(0.75),
        marginTop: `-${theme.spacing(0.25)}`,
        width: '1rem',
        height: '1rem',
        color: 'primary.main'
    };

    const getCollapse = (menu) => {
        if (menu.children) {
            menu.children.filter((collapse) => {
                if (collapse.type && (collapse.type === 'collapse' || collapse.type === 'breadcrumbs')) {
                    getCollapse(collapse);
                    if (pathname + search === config.basename + collapse.url) {
                        setMain(menu);
                        setItem(collapse);
                    }
                } else if (collapse.type && collapse.type === 'item') {
                    if (collapse.url.includes('{{identifier}}')) {
                        let updatedUrlString = collapse.url.replace('{{identifier}}', identifier);
                        if (pathname === config.basename + updatedUrlString) {
                            setMain(menu);
                            setItem(collapse);
                        }
                    } else {
                        if (pathname === config.basename + collapse.url) {
                            setMain(menu);
                            setItem(collapse);
                        }
                    }
                }
                return false;
            });
        }
    };

    useEffect(() => {
        navigation?.items?.map((menu) => {
            if (menu.type && menu.type === 'group') {
                getCollapse(menu);
            }
            return false;
        });
    });

    const separatorIcon = <IconChevronRight stroke={2} size="1rem" />;

    let mainContent;
    let itemContent;
    let breadcrumbContent = <Typography />;
    let itemTitle = '';
    let CollapseIcon;
    let ItemIcon;

    // collapse item
    if (main && (main.type === 'collapse' || main.type === 'breadcrumbs')) {
        CollapseIcon = main.icon ? main.icon : AccountTreeTwoToneIcon;
        mainContent = (
            <Typography component={Link} to={main.url} variant="subtitle1" sx={linkSX}>
                {icons && <CollapseIcon style={iconStyle} />}
                {main.title}
            </Typography>
        );
    }

    // items
    if (item && (item.type === 'item' || item.type === 'breadcrumbs')) {
        itemTitle = item.title;

        ItemIcon = item.icon ? item.icon : AccountTreeTwoToneIcon;
        itemContent = (
            <Typography
                variant="subtitle1"
                sx={{
                    display: 'flex',
                    textDecoration: 'none',
                    alignContent: 'center',
                    alignItems: 'center',
                    color: 'grey.500'
                }}
            >
                {icons && <ItemIcon style={iconStyle} />}
                {itemTitle}
            </Typography>
        );

        // main
        if (item.breadcrumbs !== false) {
            breadcrumbContent = (
                <Card
                    sx={{
                        marginBottom: card === false ? 0 : theme.spacing(gridSpacing),
                        border: card === false ? 'none' : '1px solid',
                        borderRadius: 2,
                        borderColor: theme.palette.mode === 'dark' ? theme.palette.divider : theme.palette.grey.A800,
                        backgroundColor: theme.palette.mode === 'dark' ? 'rgb(30, 30, 30)' : 'white',
                        backgroundImage: 'none',
                        boxShadow: 'inherit',
                        ':hover': {
                            boxShadow: 'inherit'
                        },
                        '& pre': {
                            m: 0,
                            p: '16px !important',
                            fontFamily: theme.typography.fontFamily,
                            fontSize: '0.75rem'
                        }
                    }}
                    {...others}
                >
                    <Box sx={{ p: 2, pl: card === false ? 0 : 2 }}>
                        <Grid
                            container
                            direction={rightAlign ? 'row' : 'column'}
                            justifyContent={rightAlign ? 'space-between' : 'flex-start'}
                            alignItems={rightAlign ? 'center' : 'flex-start'}
                            spacing={1}
                        >
                            {title && !titleBottom && (
                                <Grid item>
                                    <Stack spacing={1} direction="row" alignItems="center" justifyContent="center">
                                        <Typography variant="subtitle1">{item.title}</Typography>
                                        <FavoritePage url={url} />
                                    </Stack>
                                </Grid>
                            )}
                            <Grid item>
                                <MuiBreadcrumbs
                                    sx={{ '& .MuiBreadcrumbs-separator': { width: 16, ml: 1.25, mr: 1.25 } }}
                                    aria-label="breadcrumb"
                                    maxItems={maxItems || 8}
                                    separator={separatorIcon}
                                >
                                    <Typography component={Link} to="/dashboard/overview" color="inherit" variant="subtitle1" sx={linkSX}>
                                        {icons && <HomeTwoToneIcon sx={iconStyle} />}
                                        {icon && <HomeIcon sx={{ ...iconStyle, mr: 0 }} />}
                                        {!icon && 'Dashboard'}
                                    </Typography>
                                    {mainContent}
                                    {itemContent}
                                </MuiBreadcrumbs>
                            </Grid>
                            {title && titleBottom && (
                                <Grid item>
                                    <Typography variant="h3" sx={{ fontWeight: 500 }}>
                                        {item.title}
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                    {card === false && divider !== false && <Divider sx={{ borderColor: 'primary.main', mb: gridSpacing }} />}
                </Card>
            );
        }
    }

    return breadcrumbContent;
};

Breadcrumbs.propTypes = {
    card: PropTypes.bool,
    divider: PropTypes.bool,
    icon: PropTypes.bool,
    icons: PropTypes.bool,
    maxItems: PropTypes.number,
    navigation: PropTypes.object,
    rightAlign: PropTypes.bool,
    separator: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    title: PropTypes.bool,
    titleBottom: PropTypes.bool,
    identifier: PropTypes.string
};

export default Breadcrumbs;
