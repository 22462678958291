import { createSlice } from '@reduxjs/toolkit';
import { createInitialState } from '../../../utils/initialState';
import { createSubReducer } from '../../../utils/createReducer';
import {
    siteUrlFavouriteAdd,
    siteUrlFavouriteCheck,
    siteUrlFavouriteList,
    siteUrlList,
    siteUrlRecentView
} from '../../../actions/siteUrl/actions';

const initialState = {
    list: createInitialState({ data: [] }),
    favouriteList: createInitialState({ data: [] }),
    favouriteAdd: createInitialState(),
    favouriteCheck: createInitialState({ loading: true }),
    recentView: createInitialState()
};

const siteUrlSlice = createSlice({
    name: 'siteUrl',
    initialState,
    reducers: {
        resetSiteUrlState: (state, action) => {
            const { key } = action.payload;
            state[key] = initialState[key];
        }
    },
    extraReducers: (builder) => {
        const actionsMap = {
            list: siteUrlList,
            favouriteList: siteUrlFavouriteList,
            favouriteAdd: siteUrlFavouriteAdd,
            favouriteCheck: siteUrlFavouriteCheck,
            recentView: siteUrlRecentView
        };

        Object.keys(actionsMap).forEach((key) => {
            createSubReducer(builder, actionsMap[key], key);
        });
    }
});

export const { resetSiteUrlState } = siteUrlSlice.actions;
export default siteUrlSlice.reducer;
