import {
    AUTH_CHECK_STATE,
    FORGOT_PASSWORD,
    LOGIN,
    LOGOUT,
    OTP_DISABLE,
    OTP_ENABLE,
    OTP_VALIDATE,
    OTP_VERIFY,
    REGISTER,
    RESET_PASSWORD,
    USER_CHANGE_PASSWORD,
    USER_GOOGLE_DETAILS,
    USER_GOOGLE_LOGIN,
    USER_GOOGLE_START
} from '../../../actions/auth/authActionsType';
import { authReducer, globalPostReducer, globalReducer } from '../../helpers';

export const registerReducer = globalPostReducer(REGISTER.START, REGISTER.SUCCESS, REGISTER.FAIL, REGISTER.RESET);
export const loginReducer = authReducer(LOGIN.START, LOGIN.SUCCESS, LOGIN.FAIL, LOGIN.RESET);
export const logoutReducer = globalPostReducer(LOGOUT.START, LOGOUT.SUCCESS, LOGOUT.FAIL, LOGOUT.RESET);
export const forgotPasswordReducer = globalPostReducer(
    FORGOT_PASSWORD.START,
    FORGOT_PASSWORD.SUCCESS,
    FORGOT_PASSWORD.FAIL,
    FORGOT_PASSWORD.RESET
);
export const resetPasswordReducer = globalPostReducer(
    RESET_PASSWORD.START,
    RESET_PASSWORD.SUCCESS,
    RESET_PASSWORD.FAIL,
    RESET_PASSWORD.RESET
);

export const otpEnableReducer = globalPostReducer(OTP_ENABLE.START, OTP_ENABLE.SUCCESS, OTP_ENABLE.FAIL, OTP_ENABLE.RESET);
export const otpDisableReducer = globalPostReducer(OTP_DISABLE.START, OTP_DISABLE.SUCCESS, OTP_DISABLE.FAIL, OTP_DISABLE.RESET);
export const otpVerifyReducer = globalPostReducer(OTP_VERIFY.START, OTP_VERIFY.SUCCESS, OTP_VERIFY.FAIL, OTP_VERIFY.RESET);
export const otpValidateReducer = globalPostReducer(OTP_VALIDATE.START, OTP_VALIDATE.SUCCESS, OTP_VALIDATE.FAIL, OTP_VALIDATE.RESET);

export const authCheckStateReducer = globalReducer(
    AUTH_CHECK_STATE.START,
    AUTH_CHECK_STATE.SUCCESS,
    AUTH_CHECK_STATE.FAIL,
    AUTH_CHECK_STATE.RESET
);

export const userChangePasswordReducer = globalPostReducer(
    USER_CHANGE_PASSWORD.START,
    USER_CHANGE_PASSWORD.SUCCESS,
    USER_CHANGE_PASSWORD.FAIL,
    USER_CHANGE_PASSWORD.RESET
);
export const userGoogleStartReducer = globalPostReducer(
    USER_GOOGLE_START.START,
    USER_GOOGLE_START.SUCCESS,
    USER_GOOGLE_START.FAIL,
    USER_GOOGLE_START.RESET
);
export const userGoogleDetailsReducer = globalPostReducer(
    USER_GOOGLE_DETAILS.START,
    USER_GOOGLE_DETAILS.SUCCESS,
    USER_GOOGLE_DETAILS.FAIL,
    USER_GOOGLE_DETAILS.RESET
);
export const userGoogleLoginReducer = globalPostReducer(
    USER_GOOGLE_LOGIN.START,
    USER_GOOGLE_LOGIN.SUCCESS,
    USER_GOOGLE_LOGIN.FAIL,
    USER_GOOGLE_LOGIN.RESET
);
