import { createSlice } from '@reduxjs/toolkit';
import { createInitialState } from '../../../utils/initialState';
import { createSubReducer } from '../../../utils/createReducer';
import { soaAdd, soaCalculate, soaChartGenerate, soaDetails, soaEdit, soaList, soaStrategyApply } from '../../../actions/soa/actions';

const initialState = {
    chartGenerate: createInitialState({ loading: true }),
    list: createInitialState({ data: [] }),
    details: createInitialState({ loading: true }),
    edit: createInitialState(),
    calculate: createInitialState({ loading: true }),
    add: createInitialState(),
    strategyApply: createInitialState()
};

const soaSlice = createSlice({
    name: 'soa',
    initialState,
    reducers: {
        resetSoaState: (state, action) => {
            const { key } = action.payload;
            state[key] = initialState[key];
        }
    },
    extraReducers: (builder) => {
        const actionsMap = {
            chartGenerate: soaChartGenerate,
            list: soaList,
            details: soaDetails,
            edit: soaEdit,
            calculate: soaCalculate,
            add: soaAdd,
            strategyApply: soaStrategyApply
        };

        Object.keys(actionsMap).forEach((key) => {
            createSubReducer(builder, actionsMap[key], key);
        });
    }
});

export const { resetSoaState } = soaSlice.actions;
export default soaSlice.reducer;
