import {
    DELIVERY_COMPANY_ADD,
    DELIVERY_COMPANY_DELETE,
    DELIVERY_COMPANY_DETAILS,
    DELIVERY_COMPANY_EDIT,
    DELIVERY_COMPANY_LIST,
    DELIVERY_ORDER_ADD,
    DELIVERY_ORDER_DELETE,
    DELIVERY_ORDER_DETAILS,
    DELIVERY_ORDER_EDIT,
    DELIVERY_ORDER_HISTORY_LIST,
    DELIVERY_ORDER_LIST,
    DELIVERY_ORDER_SIMPLE_LIST,
    PRODUCTS_SIMPLE_LIST,
    WAREHOUSE_ALERT_DELETE,
    WAREHOUSE_ALERT_EDIT,
    WAREHOUSE_ALERT_GLOBAL_DETAILS,
    WAREHOUSE_ALERT_PRODUCT,
    WAREHOUSE_AMOUNT_HISTORY_CHART,
    WAREHOUSE_AMOUNT_IMPORT,
    WAREHOUSE_AMOUNT_IMPORT_FILE,
    WAREHOUSE_AMOUNT_RAPORT_EXPORT,
    WAREHOUSE_DELIVERY_ADD,
    WAREHOUSE_DELIVERY_DELETE,
    WAREHOUSE_DELIVERY_EDIT,
    WAREHOUSE_DELIVERY_IMPORT_FILE,
    WAREHOUSE_DELIVERY_LIST,
    WAREHOUSE_DELIVERY_PRODUCT_LIST,
    WAREHOUSE_FORECAST_PRODUCT_AMOUNT,
    WAREHOUSE_LOW_AMOUNT_REPORT_EXPORT,
    WAREHOUSE_NOTIFICATION_ADD,
    WAREHOUSE_NOTIFICATION_PRODUCT_LIST,
    WAREHOUSE_PRODUCT_AMOUNT_LIST
} from '../../../actions/warehouse/warehouseActionsType';
import { globalGetReducer, globalPostReducer, globalReducer } from '../../helpers';

export const warehouseNotificationAddReducer = globalPostReducer(
    WAREHOUSE_NOTIFICATION_ADD.START,
    WAREHOUSE_NOTIFICATION_ADD.SUCCESS,
    WAREHOUSE_NOTIFICATION_ADD.FAIL,
    WAREHOUSE_NOTIFICATION_ADD.RESET
);

export const warehouseNotificationProductListReducer = globalGetReducer(
    WAREHOUSE_NOTIFICATION_PRODUCT_LIST.START,
    WAREHOUSE_NOTIFICATION_PRODUCT_LIST.SUCCESS,
    WAREHOUSE_NOTIFICATION_PRODUCT_LIST.FAIL
);
export const warehouseDeliveryAddReducer = globalPostReducer(
    WAREHOUSE_DELIVERY_ADD.START,
    WAREHOUSE_DELIVERY_ADD.SUCCESS,
    WAREHOUSE_DELIVERY_ADD.FAIL,
    WAREHOUSE_DELIVERY_ADD.RESET
);
export const warehouseDeliveryProductListReducer = globalGetReducer(
    WAREHOUSE_DELIVERY_PRODUCT_LIST.START,
    WAREHOUSE_DELIVERY_PRODUCT_LIST.SUCCESS,
    WAREHOUSE_DELIVERY_PRODUCT_LIST.FAIL
);
export const warehouseDeliveryDeleteReducer = globalPostReducer(
    WAREHOUSE_DELIVERY_DELETE.START,
    WAREHOUSE_DELIVERY_DELETE.SUCCESS,
    WAREHOUSE_DELIVERY_DELETE.FAIL,
    WAREHOUSE_DELIVERY_DELETE.RESET
);
export const warehouseDeliveryListReducer = globalGetReducer(
    WAREHOUSE_DELIVERY_LIST.START,
    WAREHOUSE_DELIVERY_LIST.SUCCESS,
    WAREHOUSE_DELIVERY_LIST.FAIL
);
export const warehouseDeliveryEditReducer = globalPostReducer(
    WAREHOUSE_DELIVERY_EDIT.START,
    WAREHOUSE_DELIVERY_EDIT.SUCCESS,
    WAREHOUSE_DELIVERY_EDIT.FAIL,
    WAREHOUSE_DELIVERY_EDIT.RESET
);
export const warehouseAmountRaportExportReducer = globalPostReducer(
    WAREHOUSE_AMOUNT_RAPORT_EXPORT.START,
    WAREHOUSE_AMOUNT_RAPORT_EXPORT.SUCCESS,
    WAREHOUSE_AMOUNT_RAPORT_EXPORT.FAIL,
    WAREHOUSE_AMOUNT_RAPORT_EXPORT.RESET
);
export const warehouseAlertProductReducer = globalGetReducer(
    WAREHOUSE_ALERT_PRODUCT.START,
    WAREHOUSE_ALERT_PRODUCT.SUCCESS,
    WAREHOUSE_ALERT_PRODUCT.FAIL
);
export const warehouseAmountImportReducer = globalPostReducer(
    WAREHOUSE_AMOUNT_IMPORT.START,
    WAREHOUSE_AMOUNT_IMPORT.SUCCESS,
    WAREHOUSE_AMOUNT_IMPORT.FAIL,
    WAREHOUSE_AMOUNT_IMPORT.RESET
);
export const warehouseAmountImportFileReducer = globalPostReducer(
    WAREHOUSE_AMOUNT_IMPORT_FILE.START,
    WAREHOUSE_AMOUNT_IMPORT_FILE.SUCCESS,
    WAREHOUSE_AMOUNT_IMPORT_FILE.FAIL,
    WAREHOUSE_AMOUNT_IMPORT_FILE.RESET
);
export const warehouseDeliveryImportFileReducer = globalPostReducer(
    WAREHOUSE_DELIVERY_IMPORT_FILE.START,
    WAREHOUSE_DELIVERY_IMPORT_FILE.SUCCESS,
    WAREHOUSE_DELIVERY_IMPORT_FILE.FAIL,
    WAREHOUSE_DELIVERY_IMPORT_FILE.RESET
);
export const warehouseProductAmountListReducer = globalGetReducer(
    WAREHOUSE_PRODUCT_AMOUNT_LIST.START,
    WAREHOUSE_PRODUCT_AMOUNT_LIST.SUCCESS,
    WAREHOUSE_PRODUCT_AMOUNT_LIST.FAIL
);
export const warehouseAmountHistoryChartReducer = globalReducer(
    WAREHOUSE_AMOUNT_HISTORY_CHART.START,
    WAREHOUSE_AMOUNT_HISTORY_CHART.SUCCESS,
    WAREHOUSE_AMOUNT_HISTORY_CHART.FAIL,
    WAREHOUSE_AMOUNT_HISTORY_CHART.RESET
);
export const warehouseForecastProductAmountReducer = globalReducer(
    WAREHOUSE_FORECAST_PRODUCT_AMOUNT.START,
    WAREHOUSE_FORECAST_PRODUCT_AMOUNT.SUCCESS,
    WAREHOUSE_FORECAST_PRODUCT_AMOUNT.FAIL,
    WAREHOUSE_FORECAST_PRODUCT_AMOUNT.RESET
);

export const productsSimpleListReducer = globalGetReducer(
    PRODUCTS_SIMPLE_LIST.START,
    PRODUCTS_SIMPLE_LIST.SUCCESS,
    PRODUCTS_SIMPLE_LIST.FAIL
);

export const deliveryCompanyAddReducer = globalPostReducer(
    DELIVERY_COMPANY_ADD.START,
    DELIVERY_COMPANY_ADD.SUCCESS,
    DELIVERY_COMPANY_ADD.FAIL,
    DELIVERY_COMPANY_ADD.RESET
);

export const deliveryCompanyListReducer = globalGetReducer(
    DELIVERY_COMPANY_LIST.START,
    DELIVERY_COMPANY_LIST.SUCCESS,
    DELIVERY_COMPANY_LIST.FAIL
);
export const deliveryCompanyDeleteReducer = globalPostReducer(
    DELIVERY_COMPANY_DELETE.START,
    DELIVERY_COMPANY_DELETE.SUCCESS,
    DELIVERY_COMPANY_DELETE.FAIL,
    DELIVERY_COMPANY_DELETE.RESET
);
export const deliveryCompanyDetailsReducer = globalGetReducer(
    DELIVERY_COMPANY_DETAILS.START,
    DELIVERY_COMPANY_DETAILS.SUCCESS,
    DELIVERY_COMPANY_DETAILS.FAIL
);
export const deliveryCompanyEditReducer = globalPostReducer(
    DELIVERY_COMPANY_EDIT.START,
    DELIVERY_COMPANY_EDIT.SUCCESS,
    DELIVERY_COMPANY_EDIT.FAIL,
    DELIVERY_COMPANY_EDIT.RESET
);
export const deliveryOrderListReducer = globalGetReducer(DELIVERY_ORDER_LIST.START, DELIVERY_ORDER_LIST.SUCCESS, DELIVERY_ORDER_LIST.FAIL);
export const deliveryOrderAddReducer = globalPostReducer(
    DELIVERY_ORDER_ADD.START,
    DELIVERY_ORDER_ADD.SUCCESS,
    DELIVERY_ORDER_ADD.FAIL,
    DELIVERY_ORDER_ADD.RESET
);
export const deliveryOrderSimpleListReducer = globalGetReducer(
    DELIVERY_ORDER_SIMPLE_LIST.START,
    DELIVERY_ORDER_SIMPLE_LIST.SUCCESS,
    DELIVERY_ORDER_SIMPLE_LIST.FAIL
);
export const deliveryOrderDetailsReducer = globalGetReducer(
    DELIVERY_ORDER_DETAILS.START,
    DELIVERY_ORDER_DETAILS.SUCCESS,
    DELIVERY_ORDER_DETAILS.FAIL
);
export const deliveryOrderEditReducer = globalPostReducer(
    DELIVERY_ORDER_EDIT.START,
    DELIVERY_ORDER_EDIT.SUCCESS,
    DELIVERY_ORDER_EDIT.FAIL,
    DELIVERY_ORDER_EDIT.RESET
);
export const deliveryOrderDeleteReducer = globalPostReducer(
    DELIVERY_ORDER_DELETE.START,
    DELIVERY_ORDER_DELETE.SUCCESS,
    DELIVERY_ORDER_DELETE.FAIL,
    DELIVERY_ORDER_DELETE.RESET
);
export const deliveryOrderHistoryListReducer = globalGetReducer(
    DELIVERY_ORDER_HISTORY_LIST.START,
    DELIVERY_ORDER_HISTORY_LIST.SUCCESS,
    DELIVERY_ORDER_HISTORY_LIST.FAIL
);
export const warehouseAlertGlobalDetailsReducer = globalGetReducer(
    WAREHOUSE_ALERT_GLOBAL_DETAILS.START,
    WAREHOUSE_ALERT_GLOBAL_DETAILS.SUCCESS,
    WAREHOUSE_ALERT_GLOBAL_DETAILS.FAIL
);
export const warehouseAlertEditReducer = globalPostReducer(
    WAREHOUSE_ALERT_EDIT.START,
    WAREHOUSE_ALERT_EDIT.SUCCESS,
    WAREHOUSE_ALERT_EDIT.FAIL,
    WAREHOUSE_ALERT_EDIT.RESET
);
export const warehouseAlertDeleteReducer = globalPostReducer(
    WAREHOUSE_ALERT_DELETE.START,
    WAREHOUSE_ALERT_DELETE.SUCCESS,
    WAREHOUSE_ALERT_DELETE.FAIL,
    WAREHOUSE_ALERT_DELETE.RESET
);
export const warehouseLowAmountReportExportReducer = globalPostReducer(
    WAREHOUSE_LOW_AMOUNT_REPORT_EXPORT.START,
    WAREHOUSE_LOW_AMOUNT_REPORT_EXPORT.SUCCESS,
    WAREHOUSE_LOW_AMOUNT_REPORT_EXPORT.FAIL,
    WAREHOUSE_LOW_AMOUNT_REPORT_EXPORT.RESET
);
