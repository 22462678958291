import { createSlice } from '@reduxjs/toolkit';
import { createInitialState } from '../../../utils/initialState';
import { createSubReducer } from '../../../utils/createReducer';
import { pricingDelete, pricingList } from '../../../actions/pricing/actions';

const initialState = {
    list: createInitialState({ data: [] }),
    delete: createInitialState()
};

const pricingSlice = createSlice({
    name: 'pricing',
    initialState,
    reducers: {
        resetPricingState: (state, action) => {
            const { key } = action.payload;
            state[key] = initialState[key];
        }
    },
    extraReducers: (builder) => {
        const actionsMap = {
            list: pricingList,
            delete: pricingDelete
        };

        Object.keys(actionsMap).forEach((key) => {
            createSubReducer(builder, actionsMap[key], key);
        });
    }
});

export const { resetPricingState } = pricingSlice.actions;
export default pricingSlice.reducer;
