import { createThunkAction } from '../../thunks/actions';

export const warehouseAmountHistoryChart = createThunkAction(
    'warehouse/amount/history/chart',
    'api/warehouse/amount/history/chart',
    'post',
    false
);
export const warehouseAlertGlobalDetails = createThunkAction('warehouse/alert/globalDetails', 'warehouse/alerts/global/');
export const warehouseAlertEdit = createThunkAction('warehouse/alert/edit', (id) => `warehouse/alerts/detail/${id}/update/`, 'put');
