import { createThunkAction } from '../../thunks/actions';

export const siteUrlList = createThunkAction('siteUrl/list', 'system/site-routes/');
export const siteUrlFavouriteList = createThunkAction('siteUrl/favourite/list', 'system/site-routes/');
export const siteUrlFavouriteAdd = createThunkAction(
    'siteUrl/favourite/add',
    (url) => `system/site-routes/?url=${url}&is_favourite=1`,
    'put'
);
export const siteUrlRecentView = createThunkAction(
    'siteUrl/recentView',
    (url) => `system/site-routes/?url=${url}&is_favourite=0`,
    'put',
    true,
    false
);
export const siteUrlFavouriteCheck = createThunkAction('siteUrl/favourite/check', (url) => `system/site-routes/?url=${url}&is_favourite=1`);
