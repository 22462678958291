import { createSlice } from '@reduxjs/toolkit';
import { createInitialState } from '../../../utils/initialState';
import { createSubReducer } from '../../../utils/createReducer';
import {
    deliveryAdd,
    deliveryCompanyAdd,
    deliveryCompanyDelete,
    deliveryCompanyDetails,
    deliveryCompanyEdit,
    deliveryCompanyList,
    deliveryDelete,
    deliveryDetailsList,
    deliveryEdit,
    deliveryList,
    deliveryOrderDelete,
    deliveryOrderDetails,
    deliveryOrderList,
    deliveryOrderOptions
} from '../../../actions/delivery/actions';

const initialState = {
    list: createInitialState({ data: [] }),
    delete: createInitialState(),
    add: createInitialState(),
    edit: createInitialState(),
    companyList: createInitialState({ data: [] }),
    companyDelete: createInitialState(),
    companyAdd: createInitialState(),
    companyDetails: createInitialState({ loading: true }),
    companyEdit: createInitialState(),
    orderList: createInitialState({ data: [] }),
    orderDelete: createInitialState(),
    orderDetails: createInitialState({ loading: true }),
    orderOptions: createInitialState({ data: [] }),
    detailsList: createInitialState({ data: [] })
};

const deliverySlice = createSlice({
    name: 'delivery',
    initialState,
    reducers: {
        resetDeliveryState: (state, action) => {
            const { key } = action.payload;
            state[key] = initialState[key];
        }
    },
    extraReducers: (builder) => {
        const actionsMap = {
            list: deliveryList,
            delete: deliveryDelete,
            add: deliveryAdd,
            edit: deliveryEdit,
            companyList: deliveryCompanyList,
            companyDelete: deliveryCompanyDelete,
            companyAdd: deliveryCompanyAdd,
            companyDetails: deliveryCompanyDetails,
            companyEdit: deliveryCompanyEdit,
            orderList: deliveryOrderList,
            orderDelete: deliveryOrderDelete,
            orderDetails: deliveryOrderDetails,
            orderOptions: deliveryOrderOptions,
            detailsList: deliveryDetailsList
        };

        Object.keys(actionsMap).forEach((key) => {
            createSubReducer(builder, actionsMap[key], key);
        });
    }
});

export const { resetDeliveryState } = deliverySlice.actions;
export default deliverySlice.reducer;
