import { createSlice } from '@reduxjs/toolkit';
import { createInitialState } from '../../../utils/initialState';
import { createSubReducer } from '../../../utils/createReducer';
import {
    salesAlertAdd,
    salesAlertDelete,
    salesAlertDetails,
    salesAlertEdit,
    salesAlertList,
    salesAlertReportDetails,
    salesAlertReportList,
    salesColumnOptions,
    salesReportColumnsList,
    salesReportColumnsOptions,
    salesReportCompare,
    salesReportDateOptions,
    salesReportDelete,
    salesReportImport,
    salesReportList,
    salesReportOptions,
    salesReportOptions2,
    salesReportTemplateAdd,
    salesReportTemplateDelete,
    salesReportTemplateDetails,
    salesReportTemplateEdit,
    salesReportTemplateList,
    salesSelloutConfig,
    salesSelloutDetails,
    salesSpikesList,
    salesWohConfig,
    salesWohDetails
} from '../../../actions/sales/actions';

const initialState = {
    alertList: createInitialState({ data: [] }),
    alertAdd: createInitialState(),
    alertDetails: createInitialState({ loading: true }),
    alertEdit: createInitialState(),
    alertDelete: createInitialState(),
    alertReportDetails: createInitialState({ data: [] }),
    alertReportList: createInitialState({ data: [] }),
    spikesList: createInitialState({ data: [] }),
    reportOptions: createInitialState({ data: [] }),
    columnOptions: createInitialState({ data: [] }),
    reportList: createInitialState({ data: [] }),
    reportDelete: createInitialState(),
    reportImport: createInitialState(),
    reportTemplateList: createInitialState({ data: [] }),
    reportTemplateDelete: createInitialState(),
    reportColumnsOptions: createInitialState({ data: [] }),
    reportTemplateDetails: createInitialState({ loading: true }),
    reportTemplateEdit: createInitialState(),
    reportTemplateAdd: createInitialState(),
    wohDetails: createInitialState({ loading: true }),
    wohConfig: createInitialState(),
    selloutConfig: createInitialState(),
    selloutDetails: createInitialState({ loading: true }),
    reportColumnsList: createInitialState({ data: [] }),
    reportDateOptions: createInitialState({ data: [] }),
    reportCompare: createInitialState({ data: [] }),
    reportOptions2: createInitialState({ data: [] })
};

const salesSlice = createSlice({
    name: 'sales',
    initialState,
    reducers: {
        resetSalesState: (state, action) => {
            const { key } = action.payload;
            state[key] = initialState[key];
        }
    },
    extraReducers: (builder) => {
        const actionsMap = {
            alertList: salesAlertList,
            alertAdd: salesAlertAdd,
            alertDetails: salesAlertDetails,
            alertEdit: salesAlertEdit,
            alertDelete: salesAlertDelete,
            alertReportDetails: salesAlertReportDetails,
            alertReportList: salesAlertReportList,
            spikesList: salesSpikesList,
            reportOptions: salesReportOptions,
            columnOptions: salesColumnOptions,
            reportList: salesReportList,
            reportDelete: salesReportDelete,
            reportImport: salesReportImport,
            reportTemplateList: salesReportTemplateList,
            reportTemplateDelete: salesReportTemplateDelete,
            reportTemplateDetails: salesReportTemplateDetails,
            reportTemplateEdit: salesReportTemplateEdit,
            reportTemplateAdd: salesReportTemplateAdd,
            reportColumnsOptions: salesReportColumnsOptions,
            wohDetails: salesWohDetails,
            wohConfig: salesWohConfig,
            selloutConfig: salesSelloutConfig,
            selloutDetails: salesSelloutDetails,
            reportColumnsList: salesReportColumnsList,
            reportDateOptions: salesReportDateOptions,
            reportCompare: salesReportCompare,
            reportOptions2: salesReportOptions2
        };

        Object.keys(actionsMap).forEach((key) => {
            createSubReducer(builder, actionsMap[key], key);
        });
    }
});

export const { resetSalesState } = salesSlice.actions;
export default salesSlice.reducer;
