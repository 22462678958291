import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import DrawerHeaderStyled from './DrawerHeaderStyled';
import Logo from '../../../../components/ui-component/Logo';
import { ButtonBase } from '@mui/material';
import minimalLogo from '../../../../assets/images/logo.png';

const DrawerHeader = ({ open }) => {
    const theme = useTheme();

    return (
        <DrawerHeaderStyled theme={theme} open={open}>
            {open ? (
                <Logo />
            ) : (
                <ButtonBase sx={{ width: 35, height: 35 }}>
                    <img src={minimalLogo} alt="biobank logo" style={{ width: 'inherit' }} />
                </ButtonBase>
            )}
        </DrawerHeaderStyled>
    );
};

DrawerHeader.propTypes = {
    open: PropTypes.bool
};

export default DrawerHeader;
