import DescriptionTwoToneIcon from '@mui/icons-material/DescriptionTwoTone';
import ImageIcon from '@mui/icons-material/Image';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

export const getFileIcon = (type, color = 'primary') => {
    switch (type) {
        case 'png':
        case 'jpg':
        case 'jpeg':
            return <ImageIcon sx={{ color: color }} />;
        case 'zip':
        case 'rar':
            return <FolderZipIcon sx={{ color: color }} />;
        case 'txt':
            return <TextSnippetIcon sx={{ color: color }} />;
        case 'pdf':
            return <PictureAsPdfIcon sx={{ color: color }} />;
        default:
            return <DescriptionTwoToneIcon sx={{ color: color }} />;
    }
};
