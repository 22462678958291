import { lazy } from 'react';
import Loadable from '../../components/Loadable';

const Soa = Loadable(lazy(() => import('../../pages/soa')));
const SoaAdd = Loadable(lazy(() => import('../../pages/soa/add')));
const SoaList = Loadable(lazy(() => import('../../pages/soa/list')));

export const SoaRoutes = [
    {
        path: 'soa',
        children: [
            {
                path: 'overview',
                element: <Soa />,
                children: [{ path: 'list', element: <SoaList /> }]
            },
            {
                path: 'add',
                element: <SoaAdd />
            }
        ]
    }
];
