import { useEffect } from 'react';
import { useTour } from '@reactour/tour';
import { useLocation } from 'react-router';
import stepsConfig from './steps';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { matchPath } from '../../utils/hooks';
import { tourDetails, tourView } from '../../services/actions/tour/actions';
import { resetTourState } from '../../services/reducer/reducers/tour/slice';

const TourInfo = () => {
    const { setIsOpen, setSteps } = useTour();
    const { pathname, search } = useLocation();

    const dispatch = useDispatch();

    const { data } = useSelector((state) => state.tour.details);
    const { success } = useSelector((state) => state.tour.view);

    const delay = 1000;
    const url = pathname + search;

    const matchingConfig = stepsConfig.find((config) => matchPath(config.path, url));

    useEffect(() => {
        if (matchingConfig) {
            dispatch(tourDetails({ id: url }));
        }
    }, [dispatch, matchingConfig, url]);

    useEffect(() => {
        if (!data?.check && data?.url === url) {
            dispatch(tourView({ id: url }));
            const timer = setTimeout(() => {
                setSteps(matchingConfig.data);
                setIsOpen(true);
            }, delay);
            return () => clearTimeout(timer);
        }
    }, [data, dispatch, matchingConfig, setIsOpen, setSteps, url]);

    useEffect(() => {
        if (success) dispatch(resetTourState({ key: 'view' }));
    }, [dispatch, success]);

    return null;
};

export default TourInfo;
