import { lazy } from 'react';
import Loadable from '../../components/Loadable';

const DeliveryList = Loadable(lazy(() => import('../../pages/delivery/list')));
const DeliveryCompanyAdd = Loadable(lazy(() => import('../../pages/delivery/add')));
const DeliveryBaseList = Loadable(lazy(() => import('../../pages/delivery/list/base')));
const DeliveryCompanyList = Loadable(lazy(() => import('../../pages/delivery/list/company')));
const DeliveryAllList = Loadable(lazy(() => import('../../pages/delivery/list/order')));

export const DeliveryRoutes = [
    {
        path: 'delivery',
        children: [
            {
                path: 'list',
                element: <DeliveryList />,
                children: [
                    { path: 'base', element: <DeliveryBaseList /> },
                    { path: 'company', element: <DeliveryCompanyList /> },
                    { path: 'order', element: <DeliveryAllList /> }
                ]
            },
            {
                path: 'company/add',
                element: <DeliveryCompanyAdd />
            }
        ]
    }
];
