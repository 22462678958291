import React, { useEffect, useRef, useState } from 'react';
import {
    Avatar,
    Typography,
    Stack,
    Box,
    useTheme,
    ButtonBase,
    Popper,
    Paper,
    ClickAwayListener,
    List,
    ListItemButton,
    ListItemAvatar,
    ListItemText
} from '@mui/material';
import { IconCheck, IconSelector } from '@tabler/icons-react';
import Transitions from '../../../../components/ui-component/Transitions';
import { useDispatch, useSelector } from 'react-redux';
import { userAssignedCompanyList, userCompanyConnectionChange } from '../../../../services/actions/user/actions';
import { resetUserState } from '../../../../services/reducer/reducers/user/slice';
import { companySetDetails } from '../../../../services/actions/company/actions';

const DrawerFooter = () => {
    const [open, setOpen] = useState(false);

    const theme = useTheme();
    const dispatch = useDispatch();
    const anchorRef = useRef(null);

    const { data } = useSelector((state) => state.user2.assignedCompanyList);
    const { data: companyDetails } = useSelector((state) => state.company2.details);
    const { drawerOpen } = useSelector((state) => state.menu);

    const isCompanyChange = useSelector((state) => state.user2.companyConnectionChange.success);
    const companyChangeDetails = useSelector((state) => state.user2.companyConnectionChange.data);

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleChangeCompany = (vat) => {
        dispatch(userCompanyConnectionChange({ id: vat }));
        handleToggle();
    };

    useEffect(() => {
        dispatch(userAssignedCompanyList());
    }, [dispatch, isCompanyChange]);

    useEffect(() => {
        if (isCompanyChange) {
            dispatch(companySetDetails(companyChangeDetails));
            dispatch(resetUserState({ key: 'companyConnectionChange' }));
        }
    }, [companyChangeDetails, dispatch, isCompanyChange]);

    return (
        <Box p={drawerOpen && 1.5} sx={{ bgcolor: '#232f3e' }}>
            <ButtonBase
                disableRipple
                sx={{ width: '100%' }}
                aria-label="open profile"
                ref={anchorRef}
                aria-controls={open ? 'profile-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    sx={{
                        padding: '4px 8px',
                        border: drawerOpen && `1px solid`,
                        borderColor: '#73767B',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        width: '100%',
                        justifyContent: !drawerOpen && 'center'
                    }}
                >
                    <Avatar src={companyDetails.company_image} alt="company logo" variant="rounded" />
                    {drawerOpen && (
                        <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between" sx={{ width: '100%' }}>
                            <Stack
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                sx={{ color: 'rgb(238, 238, 238)' }}
                            >
                                <Typography variant="subtitle1">Workspace</Typography>
                                <Typography variant="subtitle2" noWrap sx={{ maxWidth: 120 }}>
                                    {companyDetails.name}
                                </Typography>
                            </Stack>
                            <IconSelector fontSize="small" stroke={1.5} size="1.2rem" style={{ marginLeft: '8px', color: '#73767B' }} />
                        </Stack>
                    )}
                </Stack>
            </ButtonBase>
            <Popper
                placement={'top'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 2]
                            }
                        }
                    ]
                }}
                sx={{ position: 'fixed !important' }}
            >
                {({ TransitionProps }) => (
                    <Transitions type="fade" in={open} {...TransitionProps}>
                        <Paper
                            sx={{
                                boxShadow: theme.customShadows.z1,
                                width: '235px'
                            }}
                        >
                            <ClickAwayListener onClickAway={handleClose}>
                                <List sx={{ padding: 1, gap: '4px !important', display: 'flex', flexDirection: 'column' }}>
                                    {data.map((item, index) => (
                                        <React.Fragment key={index}>
                                            <ListItemButton
                                                sx={{ padding: '6px 16px' }}
                                                selected={item.is_current}
                                                onClick={() => handleChangeCompany(item.vat)}
                                            >
                                                <ListItemAvatar sx={{ minWidth: 42 }}>
                                                    <Avatar src={item.company_image} variant="rounded" sx={{ width: 32, height: 32 }} />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={
                                                        <Typography variant="subtitle1" noWrap>
                                                            {item.name}
                                                        </Typography>
                                                    }
                                                />
                                                {item.is_current && (
                                                    <Box>
                                                        <IconCheck stroke={1.5} style={{ color: theme.palette.primary.main }} />
                                                    </Box>
                                                )}
                                            </ListItemButton>
                                        </React.Fragment>
                                    ))}
                                </List>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </Box>
    );
};

export default DrawerFooter;
