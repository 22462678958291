import { createSlice } from '@reduxjs/toolkit';
import {
    marketWatcherProductDetails,
    marketWatcherProductEdit,
    marketWatcherProductEnable,
    marketWatcherProductList
} from '../../../actions/marketWatcher/actions';
import { createInitialState } from '../../../utils/initialState';
import { createSubReducer } from '../../../utils/createReducer';

const initialState = {
    productDetails: createInitialState({ loading: true }),
    productEdit: createInitialState(),
    productList: createInitialState({ data: [] }),
    productEnable: createInitialState()
};

const marketWatcherSlice = createSlice({
    name: 'marketWatcher',
    initialState,
    reducers: {
        resetMarketWatcherState: (state, action) => {
            const { key } = action.payload;
            state[key] = initialState[key];
        }
    },
    extraReducers: (builder) => {
        const actionsMap = {
            productDetails: marketWatcherProductDetails,
            productEdit: marketWatcherProductEdit,
            productList: marketWatcherProductList,
            productEnable: marketWatcherProductEnable
        };

        Object.keys(actionsMap).forEach((key) => {
            createSubReducer(builder, actionsMap[key], key);
        });
    }
});

export const { resetMarketWatcherState } = marketWatcherSlice.actions;
export default marketWatcherSlice.reducer;
