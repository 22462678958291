import PropTypes from 'prop-types';
import { Dialog, DialogContent, Skeleton, styled } from '@mui/material';
import BootstrapDialogTitle from './BootstrapDialogTitle';

const CorePopup = ({ close, open, fullWidth = true, maxWidth = 'md', title, loading = false, content, isPadding = true }) => {
    return (
        <BootstrapDialog onClose={close} aria-labelledby="customized-dialog-title" open={open} fullWidth={fullWidth} maxWidth={maxWidth}>
            <BootstrapDialogTitle id="customized-dialog-title" onClose={close}>
                {title}
            </BootstrapDialogTitle>
            <DialogContent sx={{ padding: !isPadding && '0 !important' }} dividers>
                {loading ? (
                    <>
                        <Skeleton variant="text" width={300} height={20} />
                        <Skeleton variant="text" width={250} height={16} />
                    </>
                ) : (
                    content
                )}
            </DialogContent>
        </BootstrapDialog>
    );
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2)
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1)
    }
}));

CorePopup.propTypes = {
    close: PropTypes.func,
    open: PropTypes.bool,
    fullWidth: PropTypes.bool,
    maxWidth: PropTypes.string,
    title: PropTypes.string,
    loading: PropTypes.bool,
    content: PropTypes.node
};

export default CorePopup;
