import { createThunkAction, createThunkExternalAction } from '../../thunks/actions';

export const dynamicUrlCheck = createThunkExternalAction('dynamicUrl/check', (url) => url);
export const dynamicUrlAdd = createThunkAction('dynamicUrl/add', 'api/dynamicUrl/add', 'post', false);
export const dynamicUrlModelOptions = createThunkAction('dynamicUrl/model/options', 'api/dynamicUrl/model/options', 'get', false);
export const dynamicUrlSeparatorOptions = createThunkAction(
    'dynamicUrl/separator/options',
    (model) => `api/dynamicUrl/separator/${model}/options`,
    'get',
    false
);
export const dynamicUrlListOptions = createThunkAction('dynamicUrl/list/options', 'api/dynamicUrl/list/options', 'get', false);
export const dynamicUrlActionOptions = createThunkAction(
    'dynamicUrl/action/options',
    (model) => `api/dynamicUrl/action/${model}/options`,
    'get',
    false
);
export const dynamicUrlActionDetails = createThunkExternalAction('dynamicUrl/action/details', (url) => url);
