import { createRequestTypes } from '../actionsTypes';

export const USER_COMPANY_GET_LIST = createRequestTypes('USER_COMPANY_GET_LIST');
export const USER_COMPANY_EDIT = createRequestTypes('USER_COMPANY_EDIT');
export const USER_ASSIGNED_COMPANY_LIST = createRequestTypes('USER_ASSIGNED_COMPANY_LIST');
export const USER_COMPANY_CHANGE = createRequestTypes('USER_COMPANY_CHANGE');
export const USER_COMPANY_DETAILS = createRequestTypes('USER_COMPANY_DETAILS');
export const USER_DETAILS = createRequestTypes('USER_DETAILS');
export const USER_DETAILS_EDIT = createRequestTypes('USER_DETAILS_EDIT');
export const USER_IMAGE_ADD = createRequestTypes('USER_IMAGE_ADD');
export const USER_COMPANY_IMAGE_ADD = createRequestTypes('USER_COMPANY_IMAGE_ADD');
export const USER_COMPANY_CONNECTIONS_LIST = createRequestTypes('USER_COMPANY_CONNECTIONS_LIST');
export const USER_COMPANY_CONNECTION_ADD = createRequestTypes('USER_COMPANY_CONNECTION_ADD');
export const USER_COMPANY_CONNECTION_DELETE = createRequestTypes('USER_COMPANY_CONNECTION_DELETE');
