import { createThunkAction, createThunkFileAction, thunkFileDownloadAction } from '../../thunks/actions';

export const coleList = createThunkAction('cole/list', 'api/cole/list', 'get', false);
export const coleDelete = createThunkAction('cole/delete', 'dynamic-report/template/delete/', 'delete');
export const coleAdd = createThunkAction('cole/add', 'dynamic-report/template/add/', 'post');
export const coleDetails = createThunkAction('cole/details', (id) => `dynamic-report/template/details/${id}/`);
export const coleEdit = createThunkAction('cole/edit', (id) => `dynamic-report/template/details/${id}/update/`, 'put');
export const coleDynamicList = createThunkAction('cole/dynamic/list', 'api/cole/dynamic/list', 'get', false);
export const coleOptionsList = createThunkAction('cole/options/list', 'system/simple-serializer/dynamic-reports-templates/');
export const coleDynamicImport = createThunkFileAction('cole/dynamic/import', 'dynamic-report/import/', 'post');
export const coleDynamicDownload = thunkFileDownloadAction('cole/dynamic/download', (id) => `dynamic-report/download/${id}/`);
export const coleDynamicDetails = createThunkAction('cole/dynamic/details', (id) => `dynamic-report/detail/${id}/`);
