import React, { useEffect, useState } from 'react';
import { Box, IconButton, styled, useTheme } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { IconInfoSquareRounded } from '@tabler/icons-react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { systemSlugDetails } from '../../../services/actions/system/actions';

const CustomWidthTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        minWidth: 150
    }
});

const SlugInfo = ({ slug }) => {
    const [slugDataInformation, setSlugDataInformation] = useState(null);

    const dispatch = useDispatch();
    const theme = useTheme();

    const { data: slugData } = useSelector((state) => state.system.slugDetails);

    useEffect(() => {
        if (slugData && slugData.title === slug) {
            setSlugDataInformation(slugData);
        }
    }, [slug, slugData]);

    useEffect(() => {
        if (slug) {
            dispatch(systemSlugDetails({ id: slug }));
        }
    }, [dispatch, slug]);

    return (
        <CustomWidthTooltip
            title={
                <>
                    <div dangerouslySetInnerHTML={{ __html: slugDataInformation?.description }} />
                    {slugDataInformation?.is_news_url && (
                        <Box
                            sx={{
                                position: 'absolute',
                                bottom: 0,
                                right: 0
                            }}
                        >
                            <IconButton aria-label="redirect to details" size="small" color="info">
                                <KeyboardDoubleArrowRightIcon />
                            </IconButton>
                        </Box>
                    )}
                </>
            }
            arrow
        >
            <IconInfoSquareRounded size="1.35rem" style={{ cursor: 'pointer', color: theme.palette.info.main }} />
        </CustomWidthTooltip>
    );
};

export default SlugInfo;
