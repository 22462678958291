import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';

const MinimalLayout = () => {
    return (
        <>
            <Box sx={{ display: 'flex', width: '100%' }}>
                <Box component="main" sx={{ width: '100%' }}>
                    <Outlet />
                </Box>
            </Box>
        </>
    );
};

export default MinimalLayout;
