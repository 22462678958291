import { Formik } from 'formik';
import React, { useEffect } from 'react';
import SearchableSelect from '../../../../components/ui-component/select/SearchableSelect';
import { Box, Grid } from '@mui/material';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { dynamicUrlActionOptions, dynamicUrlCheck, dynamicUrlListOptions } from '../../../../services/actions/dynamicUrl/actions';
import { useSelector } from 'react-redux';
import BaseInput from '../../../../components/ui-component/inputs/BaseInput';
import LoadingButton from '../../../../components/ui-component/buttons/LoadingButton';

const DynamicUrlFilterForm = ({ setFilter, setToken }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { data: listOptions, loading: isLoadingListOptions } = useSelector((state) => state.dynamicUrl.listOptions);

    const completeForm = (form) => {
        setFilter(form);
        setToken(form.token);
        dispatch(dynamicUrlActionOptions({ id: form.listName.model }));
        dispatch(
            dynamicUrlCheck({
                id: form.listName.url,
                isAuth: form.authMethod === 'token',
                token: form.token
            })
        );
    };

    useEffect(() => {
        dispatch(dynamicUrlListOptions());
    }, [dispatch]);

    return (
        <Formik
            enableReinitialize
            initialValues={{
                listName: { value: null, label: null },
                authMethod: 'none',
                token: ''
            }}
            validationSchema={Yup.object().shape({
                listName: Yup.object({
                    value: Yup.string().required(t('input.validation.listName'))
                }),
                token: Yup.string().when('authMethod', {
                    is: 'token',
                    then: () => Yup.string().required(t('input.validation.token'))
                })
            })}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                try {
                    setStatus({ success: false });
                    setSubmitting(false);
                    completeForm(values);
                } catch (err) {
                    console.error(err);
                    setStatus({ success: false });
                    setErrors({ submit: err.message });
                    setSubmitting(false);
                }
            }}
        >
            {({ handleSubmit, setFieldValue, values, isSubmitting }) => (
                <form noValidate onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <SearchableSelect
                                label={t('input.label.listName')}
                                name="listName"
                                options={listOptions}
                                isLoading={isLoadingListOptions}
                                handleChange={(e) => {
                                    setFieldValue('authMethod', e.auth_method);
                                }}
                            />
                        </Grid>
                        {values.authMethod === 'token' && (
                            <Grid item xs={12}>
                                <BaseInput label={t('input.label.token')} name={'token'} inputType="text" />
                            </Grid>
                        )}
                    </Grid>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'end',
                            mt: 2
                        }}
                    >
                        <LoadingButton label="Get" isLoading={isSubmitting} />
                    </Box>
                </form>
            )}
        </Formik>
    );
};

export default DynamicUrlFilterForm;
