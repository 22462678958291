import { createThunkAction } from '../../thunks/actions';

export const soaChartGenerate = createThunkAction('soa/chart/generate', 'sales/simple-chart/generate/', 'post');
export const soaList = createThunkAction('soa/list', 'api/soa/list', 'get', false);
export const soaDetails = createThunkAction('soa/details', (id) => `statistics/soa/detail/${id}/`, 'get');
export const soaEdit = createThunkAction('soa/edit', (id) => `statistics/soa/detail/${id}/update/`, 'put');
export const soaCalculate = createThunkAction(
    'soa/calculate',
    (product, vat) => `statistics/product/${product}/weeks-on-hand/calculate/?from_what_company=${vat}`
);
export const soaAdd = createThunkAction('soa/add', 'statistics/soa/add/', 'post');
export const soaStrategyApply = createThunkAction('soa/strategyApply', (id) => `pricing/soa/apply-strategy/${id}/`, 'put');
