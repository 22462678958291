import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './customScrollbar.css';

export default function ScrollBar({ children, containerRef, options, style, ...other }) {
    return (
        <PerfectScrollbar containerRef={containerRef} options={options} style={style} {...other}>
            {children}
        </PerfectScrollbar>
    );
}

ScrollBar.propTypes = {
    children: PropTypes.node,
    sx: PropTypes.object
};
