// ThemeMode.js
import React from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Card,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    Radio,
    Stack,
    Typography,
    Collapse
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSelector, useDispatch } from 'react-redux';
import { SET_THEME_MODE } from '../../../../../../services/actions/customization/customizationType';
import { IconSun } from '@tabler/icons-react';

const ThemeMode = () => {
    const mode = useSelector((state) => state.customization.mode);
    const customization = useSelector((state) => state.customization);

    const dispatch = useDispatch();

    const handleModeChange = (event) => {
        const themeMode = event.target.value;
        const updatedCustomization = { ...customization, mode: themeMode };
        localStorage.setItem('customization', JSON.stringify(updatedCustomization));
        dispatch({ type: SET_THEME_MODE, mode: themeMode });
    };

    return (
        <Accordion elevation={0} sx={{ borderBottom: '1px solid', borderColor: 'divider', margin: 0 }} disableGutters>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2d-content" id="panel2d-header">
                <Stack direction="row" spacing={2} alignItems="center">
                    <IconButton color="primary" aria-label="settings toggler">
                        <IconSun />
                    </IconButton>
                    <Stack>
                        <Typography variant="subtitle1">Theme Mode</Typography>
                        <Typography variant="caption">Choose light or dark mode</Typography>
                    </Stack>
                </Stack>
            </AccordionSummary>
            <Collapse in={true}>
                <AccordionDetails>
                    <FormGroup row aria-label="payment-card" role="radiogroup" onChange={handleModeChange}>
                        <Grid container spacing={1.75}>
                            <Grid item>
                                <FormControlLabel
                                    control={<Radio value="light" color="primary" size="small" checked={mode === 'light'} />}
                                    label={
                                        <Card elevation={0}>
                                            <Stack>
                                                <Typography variant="caption">Light</Typography>
                                            </Stack>
                                        </Card>
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={<Radio value="dark" color="primary" size="small" checked={mode === 'dark'} />}
                                    label={
                                        <Card elevation={0}>
                                            <Stack>
                                                <Typography variant="caption">Dark</Typography>
                                            </Stack>
                                        </Card>
                                    }
                                />
                            </Grid>
                        </Grid>
                    </FormGroup>
                </AccordionDetails>
            </Collapse>
        </Accordion>
    );
};

export default ThemeMode;
