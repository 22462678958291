import { createThunkAction } from '../../thunks/actions';

export const deliveryList = createThunkAction('delivery/list', 'api/warehouse/delivery/list', 'get', false);
export const deliveryDelete = createThunkAction('delivery/delete', 'warehouse/delivery/delete/', 'delete');
export const deliveryAdd = createThunkAction('delivery/add', 'warehouse/delivery/add/', 'post');
export const deliveryEdit = createThunkAction('delivery/edit', (id) => `warehouse/delivery/detail/${id}/update/`, 'put');
export const deliveryCompanyList = createThunkAction('delivery/company/list', 'api/sales/delivery/list/', 'get', false);
export const deliveryCompanyDelete = createThunkAction('delivery/company/delete', 'sales/delivery/delete/', 'delete');
export const deliveryCompanyAdd = createThunkAction('delivery/company/add', 'sales/delivery/add/', 'post');
export const deliveryCompanyDetails = createThunkAction('delivery/company/details', (id) => `sales/delivery/detail/${id}/`);
export const deliveryCompanyEdit = createThunkAction('delivery/company/edit', (id) => `sales/delivery/detail/${id}/update/`, 'put');
export const deliveryOrderList = createThunkAction('delivery/order/list', 'api/deliveries/list/', 'get', false);
export const deliveryOrderAdd = createThunkAction('delivery/order/add', 'deliveries/add/', 'post');
export const deliveryOrderOptions = createThunkAction('delivery/order/options', 'system/simple-serializer/deliveries/');
export const deliveryOrderDetails = createThunkAction('delivery/order/details', (identifier) => `deliveries/detail/${identifier}/`);
export const deliveryOrderEdit = createThunkAction('delivery/order/edit', (identifier) => `deliveries/detail/${identifier}/update/`, 'put');
export const deliveryOrderDelete = createThunkAction('delivery/order/delete', 'deliveries/delete/', 'delete');
export const deliveryOrderHistoryList = createThunkAction(
    'delivery/order/historyList',
    (identifier) => `deliveries/detail/${identifier}/history/`
);
export const deliveryDetailsList = createThunkAction('delivery/details/list', (id) => `warehouse/delivery/detail/${id}/`);
