export const findOption = (value, array = []) => {
    return array.find((option) => option.value === value || option.label === value) || { value: null, label: null };
};

const escapeRegExp = (string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

export const matchPath = (configPath, currentPath) => {
    if (currentPath.includes('?action')) {
        return false;
    }

    const escapedConfigPath = escapeRegExp(configPath);
    const pattern = new RegExp(`^${escapedConfigPath.replace(/\\{\\{identifier\\}\\}/g, '[^/]+')}(\\?.*)?$`);
    return pattern.test(currentPath);
};
