import { createSlice } from '@reduxjs/toolkit';
import { createInitialState } from '../../../utils/initialState';
import { createSubReducer } from '../../../utils/createReducer';
import {
    statisticsAdd,
    statisticsDashboardDefault,
    statisticsGenerate,
    statisticsSetDashboardDefault,
    statisticsDashboardSessionsList,
    statisticsSelloutAllowanceCalculateDetails,
    statisticsWeeksOnHandDetails,
    selloutSumCalculate,
    countDelivery,
    countSelloutValue,
    countSelloutAmount,
    chartDeliveriesCompanies,
    averageSelloutAllowance,
    selloutValue,
    sellAmount,
    chartSellValueReport,
    chartCategoryReport,
    chartAmountReport,
    sellValueRaportCompanies,
    statisticsReportAbcd,
    statisticsCalculateXox,
    statisticsCalculateWow,
    statisticsCalculateMom,
    statisticsCalculateQoq,
    statisticsCalculateYoy,
    statisticsCalculateStockOnHand,
    statisticsCalculateAmountSell,
    soaAdd,
    statisticsBestPerformSku,
    statisticsDashboardTableAdd,
    statisticsDashboardTableDelete,
    statisticsDashboardTableDetails,
    statisticsDashboardTableEdit,
    statisticsDashboardTableGenerate,
    statisticsDashboardTableList,
    statisticsDashboardOptions
} from '../../../actions/statistics/actions';

const initialState = {
    add: createInitialState(),
    dashboardDefault: createInitialState({ loading: true }),
    generate: createInitialState(),
    setDashboardDefault: createInitialState(),
    dashboardOptions: createInitialState({ data: [] }),
    dashboardSessionsList: createInitialState({ data: [] }),
    selloutAllowanceCalculateDetails: createInitialState({ loading: true }),
    weeksOnHandDetails: createInitialState({ loading: true }),
    sumCalculate: createInitialState(),
    countDelivery: createInitialState({ loading: true }),
    countSelloutValue: createInitialState({ loading: true }),
    countSelloutAmount: createInitialState({ loading: true }),
    chartDeliveriesCompanies: createInitialState(),
    averageSelloutAllowance: createInitialState({ loading: true }),
    selloutValue: createInitialState({ loading: true }),
    sellAmount: createInitialState({ loading: true }),
    chartSellValueReport: createInitialState({ loading: true }),
    chartCategoryReport: createInitialState({ loading: true }),
    chartAmountReport: createInitialState({ loading: true }),
    sellValueRaportCompanies: createInitialState({ loading: true }),
    reportAbcd: createInitialState(),
    calculateXox: createInitialState({ loading: true }),
    calculateWow: createInitialState({ loading: true }),
    calculateMom: createInitialState({ loading: true }),
    calculateQoq: createInitialState({ loading: true }),
    calculateYoy: createInitialState({ loading: true }),
    calculateStockOnHand: createInitialState(),
    calculateAmountSell: createInitialState(),
    soaAdd: createInitialState(),
    bestPerformSku: createInitialState(),
    dashboardTableList: createInitialState({ data: [] }),
    dashboardTableAdd: createInitialState(),
    dashboardTableDetails: createInitialState({ loading: true }),
    dashboardTableDelete: createInitialState(),
    dashboardTableGenerate: createInitialState(),
    dashboardTableEdit: createInitialState()
};

const statisticsSlice = createSlice({
    name: 'statistics',
    initialState,
    reducers: {
        resetStatisticsState: (state, action) => {
            const { key } = action.payload;
            state[key] = initialState[key];
        }
    },
    extraReducers: (builder) => {
        const actionsMap = {
            add: statisticsAdd,
            dashboardDefault: statisticsDashboardDefault,
            generate: statisticsGenerate,
            setDashboardDefault: statisticsSetDashboardDefault,
            dashboardOptions: statisticsDashboardOptions,
            dashboardSessionsList: statisticsDashboardSessionsList,
            selloutAllowanceCalculateDetails: statisticsSelloutAllowanceCalculateDetails,
            weeksOnHandDetails: statisticsWeeksOnHandDetails,
            sumCalculate: selloutSumCalculate,
            countDelivery: countDelivery,
            countSelloutValue: countSelloutValue,
            countSelloutAmount: countSelloutAmount,
            chartDeliveriesCompanies: chartDeliveriesCompanies,
            averageSelloutAllowance: averageSelloutAllowance,
            selloutValue: selloutValue,
            sellAmount: sellAmount,
            chartSellValueReport: chartSellValueReport,
            chartCategoryReport: chartCategoryReport,
            chartAmountReport: chartAmountReport,
            sellValueRaportCompanies: sellValueRaportCompanies,
            reportAbcd: statisticsReportAbcd,
            calculateXox: statisticsCalculateXox,
            calculateWow: statisticsCalculateWow,
            calculateMom: statisticsCalculateMom,
            calculateQoq: statisticsCalculateQoq,
            calculateYoy: statisticsCalculateYoy,
            calculateStockOnHand: statisticsCalculateStockOnHand,
            calculateAmountSell: statisticsCalculateAmountSell,
            soaAdd: soaAdd,
            bestPerformSku: statisticsBestPerformSku,
            dashboardTableList: statisticsDashboardTableList,
            dashboardTableAdd: statisticsDashboardTableAdd,
            dashboardTableDetails: statisticsDashboardTableDetails,
            dashboardTableDelete: statisticsDashboardTableDelete,
            dashboardTableGenerate: statisticsDashboardTableGenerate,
            dashboardTableEdit: statisticsDashboardTableEdit
        };

        Object.keys(actionsMap).forEach((key) => {
            createSubReducer(builder, actionsMap[key], key);
        });
    }
});

export const { resetStatisticsState } = statisticsSlice.actions;
export default statisticsSlice.reducer;
