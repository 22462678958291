import { createThunkAction } from '../../thunks/actions';

export const marketWatcherProductDetails = createThunkAction(
    'market-watcher/product/details',
    (id) => `market-watcher/product-search-phase/details/${id}/`
);
export const marketWatcherProductEdit = createThunkAction(
    'market-watcher/product/edit',
    (id) => `market-watcher/product-search-phase/details/${id}/update/`,
    'put'
);
export const marketWatcherProductList = createThunkAction('market-watcher/product/list', 'market-watcher/product-search-phase/list/');
export const marketWatcherProductEnable = createThunkAction(
    'market-watcher/product/enable',
    (id) => `market-watcher/product-search-phase/product/${id}/google-service/`
);
