import {
    USER_ASSIGNED_COMPANY_LIST,
    USER_COMPANY_CHANGE,
    USER_COMPANY_CONNECTIONS_LIST,
    USER_COMPANY_CONNECTION_ADD,
    USER_COMPANY_CONNECTION_DELETE,
    USER_COMPANY_DETAILS,
    USER_COMPANY_EDIT,
    USER_COMPANY_GET_LIST,
    USER_COMPANY_IMAGE_ADD,
    USER_DETAILS,
    USER_DETAILS_EDIT,
    USER_IMAGE_ADD
} from '../../../actions/user/userActionsType';
import { globalGetReducer, globalPostReducer } from '../../helpers';

export const userCompanyGetListReducer = globalGetReducer(
    USER_COMPANY_GET_LIST.START,
    USER_COMPANY_GET_LIST.SUCCESS,
    USER_COMPANY_GET_LIST.FAIL
);

export const userCompanyEditReducer = globalPostReducer(
    USER_COMPANY_EDIT.START,
    USER_COMPANY_EDIT.SUCCESS,
    USER_COMPANY_EDIT.FAIL,
    USER_COMPANY_EDIT.RESET
);

export const userAssignedCompanyListReducer = globalGetReducer(
    USER_ASSIGNED_COMPANY_LIST.START,
    USER_ASSIGNED_COMPANY_LIST.SUCCESS,
    USER_ASSIGNED_COMPANY_LIST.FAIL
);

export const userCompanyChangeReducer = globalPostReducer(USER_COMPANY_CHANGE.START, USER_COMPANY_CHANGE.SUCCESS, USER_COMPANY_CHANGE.FAIL);

export const userCompanyDetailsReducer = globalGetReducer(
    USER_COMPANY_DETAILS.START,
    USER_COMPANY_DETAILS.SUCCESS,
    USER_COMPANY_DETAILS.FAIL
);

export const userDetailsReducer = globalGetReducer(USER_DETAILS.START, USER_DETAILS.SUCCESS, USER_DETAILS.FAIL);

export const userDetailsEditReducer = globalPostReducer(
    USER_DETAILS_EDIT.START,
    USER_DETAILS_EDIT.SUCCESS,
    USER_DETAILS_EDIT.FAIL,
    USER_DETAILS_EDIT.RESET
);

export const userImageAddReducer = globalPostReducer(
    USER_IMAGE_ADD.START,
    USER_IMAGE_ADD.SUCCESS,
    USER_IMAGE_ADD.FAIL,
    USER_IMAGE_ADD.RESET
);
export const userCompanyImageAddReducer = globalPostReducer(
    USER_COMPANY_IMAGE_ADD.START,
    USER_COMPANY_IMAGE_ADD.SUCCESS,
    USER_COMPANY_IMAGE_ADD.FAIL,
    USER_COMPANY_IMAGE_ADD.RESET
);
export const userCompanyConnectionsListReducer = globalGetReducer(
    USER_COMPANY_CONNECTIONS_LIST.START,
    USER_COMPANY_CONNECTIONS_LIST.SUCCESS,
    USER_COMPANY_CONNECTIONS_LIST.FAIL
);
export const userCompanyConnectionAddReducer = globalPostReducer(
    USER_COMPANY_CONNECTION_ADD.START,
    USER_COMPANY_CONNECTION_ADD.SUCCESS,
    USER_COMPANY_CONNECTION_ADD.FAIL,
    USER_COMPANY_CONNECTION_ADD.RESET
);
export const userCompanyConnectionDeleteReducer = globalPostReducer(
    USER_COMPANY_CONNECTION_DELETE.START,
    USER_COMPANY_CONNECTION_DELETE.SUCCESS,
    USER_COMPANY_CONNECTION_DELETE.FAIL,
    USER_COMPANY_CONNECTION_DELETE.RESET
);
