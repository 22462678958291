import React from 'react';
import { Tabs, Tab, Box, Chip } from '@mui/material';

const NoticiationTabs = ({ tabValue, setTab, tabState }) => {
    const tabs = [
        { value: 'all', label: 'All' },
        { value: 'priority', label: 'Priority' },
        { value: 'unread', label: 'Unread' }
    ];

    return (
        <Box sx={{ borderBottom: 1, borderTop: 1, borderColor: 'divider', backgroundColor: 'grey.50' }}>
            <Tabs value={tabValue} textColor="primary" indicatorColor="primary">
                {tabs.map((tab) => (
                    <Tab
                        key={tab.value}
                        sx={{ flexDirection: 'row', paddingRight: '0px', fontSize: '13px', color: 'grey.500' }}
                        label={
                            <>
                                {tab.label} <Chip label={tabState[tab.value].maxLength} size="small" sx={{ marginLeft: '10px' }} />
                            </>
                        }
                        value={tab.value}
                        onClick={() => setTab(tab.value)}
                    />
                ))}
            </Tabs>
        </Box>
    );
};

export default NoticiationTabs;
