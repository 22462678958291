import React, { useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import { Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import { ShortcutIcon } from '../../../../../utils/model-type';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { siteUrlFavouriteList } from '../../../../../services/actions/siteUrl/actions';
import { resetSiteUrlState } from '../../../../../services/reducer/reducers/siteUrl/slice';

const Shortcuts = () => {
    const dispatch = useDispatch();

    const { data: favoritesList } = useSelector((state) => state.siteUrl.favouriteList);
    const { success: isPageAddToFavourite } = useSelector((state) => state.siteUrl.favouriteAdd);
    const { success: isPageView } = useSelector((state) => state.siteUrl.recentView);

    useEffect(() => {
        dispatch(siteUrlFavouriteList({ params: { is_favourite: 1, is_json: 1 } }));
    }, [dispatch, isPageAddToFavourite, isPageView]);

    useEffect(() => {
        if (isPageAddToFavourite) dispatch(resetSiteUrlState({ key: 'favouriteAdd' }));
        if (isPageView) dispatch(resetSiteUrlState({ key: 'recentView' }));
    }, [dispatch, isPageAddToFavourite, isPageView]);

    return (
        <>
            {favoritesList?.favourite?.map((item, index) => (
                <React.Fragment key={index}>
                    <Tooltip title={item.title}>
                        <IconButton aria-label="favorite shortcuts" component={Link} to={item.url} sx={{ ml: 1 }}>
                            <ShortcutIcon model={item.model} group={item.group} />
                        </IconButton>
                    </Tooltip>
                </React.Fragment>
            ))}
        </>
    );
};

export default Shortcuts;
