import React from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Card,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    Radio,
    Stack,
    Typography,
    Collapse
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSelector, useDispatch } from 'react-redux';
import { SET_FONT_FAMILY } from '../../../../../../services/actions/customization/customizationType';
import { IconTypography } from '@tabler/icons-react';
import config from '../../../../../../config';

const fonts = {
    roboto: 'Roboto, sans-serif',
    inter: 'Inter, sans-serif',
    poppins: `Poppins, sans-serif`
};

const FontFamily = () => {
    const fontFamily = useSelector((state) => state.customization.fontFamily);
    const customization = useSelector((state) => state.customization);

    const dispatch = useDispatch();

    const handleModeChange = (event) => {
        const fontFamily = event.target.value;

        const updatedCustomization = { ...customization, fontFamily: fontFamily };
        localStorage.setItem('customization', JSON.stringify(updatedCustomization));
        dispatch({ type: SET_FONT_FAMILY, fontFamily: fontFamily });
    };

    return (
        <Accordion elevation={0} sx={{ borderBottom: '1px solid', borderColor: 'divider', margin: 0 }} disableGutters>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2d-content" id="panel2d-header">
                <Stack direction="row" spacing={2} alignItems="center">
                    <IconButton color="primary" aria-label="settings toggler">
                        <IconTypography />
                    </IconButton>
                    <Stack>
                        <Typography variant="subtitle1">Font family</Typography>
                        <Typography variant="caption">Choose font</Typography>
                    </Stack>
                </Stack>
            </AccordionSummary>
            <Collapse in={true}>
                <AccordionDetails>
                    <FormGroup row aria-label="payment-card" role="radiogroup" onChange={handleModeChange}>
                        <Grid container spacing={1.75}>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Radio
                                            value={config.fontFamily}
                                            color="primary"
                                            size="small"
                                            checked={fontFamily === config.fontFamily}
                                        />
                                    }
                                    label={
                                        <Card elevation={0}>
                                            <Stack>
                                                <Typography variant="caption">Default</Typography>
                                            </Stack>
                                        </Card>
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Radio value={fonts.poppins} color="primary" size="small" checked={fontFamily === fonts.poppins} />
                                    }
                                    label={
                                        <Card elevation={0}>
                                            <Stack>
                                                <Typography variant="caption">Poppins</Typography>
                                            </Stack>
                                        </Card>
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Radio value={fonts.roboto} color="primary" size="small" checked={fontFamily === fonts.roboto} />
                                    }
                                    label={
                                        <Card elevation={0}>
                                            <Stack>
                                                <Typography variant="caption">Roboto</Typography>
                                            </Stack>
                                        </Card>
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Radio value={fonts.inter} color="primary" size="small" checked={fontFamily === fonts.inter} />
                                    }
                                    label={
                                        <Card elevation={0}>
                                            <Stack>
                                                <Typography variant="caption">Inter</Typography>
                                            </Stack>
                                        </Card>
                                    }
                                />
                            </Grid>
                        </Grid>
                    </FormGroup>
                </AccordionDetails>
            </Collapse>
        </Accordion>
    );
};

export default FontFamily;
