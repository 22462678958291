import { createRequestTypes } from '../actionsTypes';

export const COUNTRIES_LIST = createRequestTypes('COUNTRIES_LIST');
export const CITIES_LIST = createRequestTypes('CITIES_LIST');
export const POST_CODE_LIST = createRequestTypes('POST_CODE_LIST');
export const STREET_LIST = createRequestTypes('STREET_LIST');
export const SYSTEM_TEMPLATE_FILE_DOWNLOAD = createRequestTypes('SYSTEM_TEMPLATE_FILE_DOWNLOAD');
export const SYSTEM_TEMPLATE_FILE_IMPORT = createRequestTypes('SYSTEM_TEMPLATE_FILE_IMPORT');
export const SYSTEM_EXPORT_FILE_DOWNLOAD = createRequestTypes('SYSTEM_EXPORT_FILE_DOWNLOAD');
export const SITE_ROUTES_LIST = createRequestTypes('SITE_ROUTES_LIST');
export const SITE_ROUTES_FAVOURITES_LIST = createRequestTypes('SITE_ROUTES_FAVOURITES_LIST');
export const SITE_RECENT_VIEW = createRequestTypes('SITE_RECENT_VIEW');
export const SITE_ADD_TO_FAVOURITE = createRequestTypes('SITE_ADD_TO_FAVOURITE');
export const HELP_CENTER_LIST = createRequestTypes('HELP_CENTER_LIST');
export const HELP_CENTER_DETAILS = createRequestTypes('HELP_CENTER_DETAILS');
export const SLUG_INFORMATION = createRequestTypes('SLUG_INFORMATION');
export const TOUR_SITE_INFO = createRequestTypes('TOUR_SITE_INFO');
export const TOUR_SITE_VIEW = createRequestTypes('TOUR_SITE_VIEW');
export const SITE_FAVOURITE_CHECK = createRequestTypes('SITE_FAVOURITE_CHECK');
export const HELP_CENTER_CHECK_URL = createRequestTypes('HELP_CENTER_CHECK_URL');
export const SYSTEM_TASK_LIST = createRequestTypes('SYSTEM_TASK_LIST');
export const SYSTEM_TASK_REFRESH = createRequestTypes('SYSTEM_TASK_REFRESH');
