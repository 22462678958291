import { Box, useMediaQuery } from '@mui/material';
import Profile from './Profile';
import Notification from './Notification';
import MobileSection from './MobileSection';
import Search from './Search';
import Shortcuts from './Shortcuts';
import HelpCenter from './HelpCenter';
import Customization from './Customization';

const HeaderContent = () => {
    const matchesSm = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    return (
        <>
            {!matchesSm && (
                <>
                    <Search />
                    <Shortcuts />
                </>
            )}
            <Box sx={{ width: '100%', ml: 1 }} />

            <HelpCenter />
            <Notification />
            <Customization />
            {!matchesSm && <Profile />}
            {matchesSm && <MobileSection />}
        </>
    );
};

export default HeaderContent;
