import { Navigate } from 'react-router';
import HelpCenterDetails from '../../pages/static/HelpCenter/details';
import { NavMotion } from '../../components/NavMotion';
import { LayoutGuard } from '../guard';

const PublicRoutes = {
    path: '',
    element: (
        <NavMotion>
            <LayoutGuard />
        </NavMotion>
    ),
    children: [
        {
            path: '/',
            element: <Navigate to="/login" />
        },
        {
            path: 'help-center/:slug',
            element: <HelpCenterDetails />
        }
    ]
};

export default PublicRoutes;
