import { lazy } from 'react';
import Loadable from '../../components/Loadable';

const CompanyDetails = Loadable(lazy(() => import('../../pages/company/details')));
const Information = Loadable(lazy(() => import('../../pages/company/details/Information')));
const Pricing = Loadable(lazy(() => import('../../pages/company/details/Pricing')));
const CreditNote = Loadable(lazy(() => import('../../pages/company/details/CreditNote')));
const MarginList = Loadable(lazy(() => import('../../pages/company/details/Margin')));
const ProductIdentifier = Loadable(lazy(() => import('../../pages/company/details/ProductIdentifier')));

export const CompanyRoutes = [
    {
        path: 'company',
        children: [
            {
                path: ':id/details',
                element: <CompanyDetails />,
                children: [
                    { path: 'information', element: <Information /> },
                    { path: 'pricing', element: <Pricing /> },
                    { path: 'margin', element: <MarginList /> },
                    { path: 'credit', element: <CreditNote /> },
                    { path: 'product-identifier', element: <ProductIdentifier /> }
                ]
            }
        ]
    }
];
