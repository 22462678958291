import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { helpCenterDetails } from '../../../../services/actions/other/otherActions';
import { useParams } from 'react-router';
import { Box, Container, Divider, Stack, Typography, useTheme } from '@mui/material';
import MainCard from '../../../../components/ui-component/cards/MainCard';
import { useSelector } from 'react-redux';
import { formatDate } from './../../../../utils/date';

const HelpCenterDetails = () => {
    const dispatch = useDispatch();
    const { slug } = useParams();
    const theme = useTheme();

    const { data, loading } = useSelector((state) => state.other.helpCenterDetails);

    useEffect(() => {
        dispatch(helpCenterDetails(slug));
    }, [dispatch, slug]);

    if (loading) {
        return 'Loading...';
    }

    return (
        <Box
            sx={{
                [theme.breakpoints.up(0)]: {
                    paddingTop: 6,
                    paddingBottom: 6
                },
                [theme.breakpoints.up('md')]: {
                    paddingTop: 10,
                    paddingBottom: 10
                }
            }}
        >
            <Container maxWidth="lg">
                <MainCard>
                    <Stack direction="column" sx={{ paddingBottom: '40px', textAlign: 'center', gap: '24px' }}>
                        <Typography variant="h2">{data.title}</Typography>
                        <Typography variant="h5">
                            <div dangerouslySetInnerHTML={{ __html: data.short_text }} />
                        </Typography>
                    </Stack>
                    <Divider sx={{ mt: 1, mb: 6 }} />
                    <Typography variant="subtitle2">Data created: {formatDate(data.date_created)}</Typography>
                    {data.image && (
                        <Box sx={{ my: 2 }}>
                            <img src={data.image} alt={data.image_alt} style={{ maxWidth: '100%' }} />
                        </Box>
                    )}

                    <div dangerouslySetInnerHTML={{ __html: data.content }} />
                </MainCard>
            </Container>
        </Box>
    );
};

export default HelpCenterDetails;
