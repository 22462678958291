import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { showNotification } from '../../../utils/notification';
import { resetAuthState } from '../../reducer/reducers/auth/slice';
import { createAuthThunk, createThunkAction } from '../../thunks/actions';
import { parse } from 'date-fns';
import { requestAction, requestWithoutToken2 } from '../../thunks';
import { userSetDetails } from '../user/actions';
import { companySetDetails } from '../company/actions';
import { resetUserState } from '../../reducer/reducers/user/slice';
import { resetCompanyState } from '../../reducer/reducers/company/slice';

export const checkAuthTimeout = (expirationTime) => (dispatch) => {
    setTimeout(() => {
        dispatch(logout());
        showNotification('Session expired. Please log in again.', 'error');
    }, expirationTime * 1000);
};

export const login = createAsyncThunk('auth/login', async (data, { dispatch, rejectWithValue }) => {
    try {
        const response = await requestWithoutToken2('users/login/', 'post', data);
        showNotification('Success', 'success');

        const { token, expiry } = response.data;
        if (token) {
            const { user, company, otp_enabled } = response.data;

            const expirationDate = parse(expiry, 'dd-MM-yyyy HH:mm', new Date());
            const formatExpirationDate = new Date(expirationDate ?? new Date().getTime() + 3600000);

            localStorage.setItem('token', token);
            localStorage.setItem('expirationDate', formatExpirationDate);

            const timeDifference = (formatExpirationDate - new Date()) / 1000;
            dispatch(checkAuthTimeout(timeDifference));
            dispatch(userSetDetails(user));
            dispatch(companySetDetails(company));
            dispatch(otpSetBool(otp_enabled));
            dispatch(authSetToken(token));
        }

        return response.data;
    } catch (error) {
        showNotification(error.response?.data?.error || 'Error occurred', 'error');
        return rejectWithValue(error.response?.data?.error || error.message);
    }
});

export const checkToken = createThunkAction('checkToken', 'users/check/token/');

export const authCheckState = createAsyncThunk('auth/checkState', async ({ urlToken }, { dispatch }) => {
    const token = urlToken || localStorage.getItem('token');

    if (!token) {
        dispatch(logout());
        return;
    } else if (!urlToken) {
        const expirationDate = new Date(localStorage.getItem('expirationDate'));
        if (expirationDate <= new Date()) {
            dispatch(logout());
            showNotification('Session expired. Please log in again.', 'error');
            return;
        }
    }

    try {
        const response = await dispatch(checkToken({ token: token }));
        const data = response.payload;
        if (data.is_token_valid) {
            const { expiry, user, company, otp_enabled, token } = data;
            const currentExpirationDate = parse(expiry, 'dd-MM-yyyy HH:mm', new Date());

            localStorage.setItem('token', token);
            localStorage.setItem('expirationDate', currentExpirationDate);

            const timeDifference = (currentExpirationDate - new Date()) / 1000;
            dispatch(checkAuthTimeout(timeDifference));
            dispatch(userSetDetails(user));
            dispatch(companySetDetails(company));
            dispatch(authSetToken(token));
            dispatch(otpSetBool(otp_enabled));

            return { user, company };
        } else {
            dispatch(logout());
            showNotification('Please log in again.', 'error');
        }
    } catch (error) {
        dispatch(logout());
        showNotification('Error verifying token. Please log in again.', 'error');
    }
});

export const authSetToken = createAction('auth/setToken');
export const otpSetBool = createAction('auth/setBool');

export const logout = createAsyncThunk('auth/logout', async (_, { dispatch, rejectWithValue }) => {
    const token = localStorage.getItem('token');

    try {
        if (token) {
            const response = await requestAction('users/logout/', 'post', {}, true);

            localStorage.removeItem('token');
            localStorage.removeItem('expirationDate');

            dispatch(resetUserState({ key: 'details' }));
            dispatch(resetCompanyState({ key: 'company' }));
            dispatch(resetAuthState({ key: 'token' }));
            showNotification('Successfully logged out', 'success');

            return response.data;
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.error || error.message);
    } finally {
        localStorage.removeItem('token');
        localStorage.removeItem('expirationDate');

        dispatch(resetUserState({ key: 'details' }));
        dispatch(resetCompanyState({ key: 'company' }));
        dispatch(resetAuthState({ key: 'token' }));
    }
});

export const register = createAuthThunk('auth/register', 'users/register/user/', 'post');
export const forgotPassword = createAuthThunk('auth/forgotPassword', 'users/password-mail/', 'post');
export const resetPassword = createAuthThunk('auth/resetPassword', (uidb64, token) => `users/password-reset/${uidb64}/${token}/`, 'post');
export const otpEnable = createThunkAction('auth/otpEnable', 'users/otp/enable/', 'post');
export const otpDisable = createThunkAction('auth/otpDisable', 'users/otp/disable/', 'post');
export const otpVerify = createThunkAction('auth/otpVerify', 'users/otp/verify/', 'post');
export const otpValidate = createAuthThunk('auth/otpValidate', 'users/otp/validate/', 'post');
export const userChangePassword = createThunkAction('auth/changePassword', 'users/change/password/', 'post');
export const userGoogleStart = createAuthThunk('auth/googleStart', 'users/google/login/');
export const userGoogleDetails = createAuthThunk('auth/googleDetails', (code) => `users/google/login/callback/?code=${code}`);
export const userGoogleLogin = createAuthThunk('auth/googleLogin', 'users/google/login/handle/', 'post');
