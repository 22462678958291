import { createSlice } from '@reduxjs/toolkit';
import { createInitialState } from '../../../utils/initialState';
import { createSubReducer } from '../../../utils/createReducer';
import {
    dynamicUrlActionDetails,
    dynamicUrlActionOptions,
    dynamicUrlAdd,
    dynamicUrlCheck,
    dynamicUrlListOptions,
    dynamicUrlModelOptions,
    dynamicUrlSeparatorOptions
} from '../../../actions/dynamicUrl/actions';

const initialState = {
    check: createInitialState(),
    add: createInitialState(),
    modelOptions: createInitialState({ data: [] }),
    separatorOptions: createInitialState({ data: [] }),
    listOptions: createInitialState({ data: [] }),
    actionOptions: createInitialState({ data: [] }),
    actionDetails: createInitialState()
};

const dynamicUrlSlice = createSlice({
    name: 'dynamicUrl',
    initialState,
    reducers: {
        resetDynamicUrlState: (state, action) => {
            const { key } = action.payload;
            state[key] = initialState[key];
        }
    },
    extraReducers: (builder) => {
        const actionsMap = {
            check: dynamicUrlCheck,
            add: dynamicUrlAdd,
            modelOptions: dynamicUrlModelOptions,
            separatorOptions: dynamicUrlSeparatorOptions,
            listOptions: dynamicUrlListOptions,
            actionOptions: dynamicUrlActionOptions,
            actionDetails: dynamicUrlActionDetails
        };

        Object.keys(actionsMap).forEach((key) => {
            createSubReducer(builder, actionsMap[key], key);
        });
    }
});

export const { resetDynamicUrlState } = dynamicUrlSlice.actions;
export default dynamicUrlSlice.reducer;
