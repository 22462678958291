import React, { useEffect, useMemo, useState } from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button } from '@mui/material';
import ActionTextButton from '../../ui-component/buttons/ActionTextButton';
import ActionTableButton from '../../ui-component/buttons/ActionTableButton';
import { renderPopups, usePopupManager } from '../../../utils/popup';
import { IconTrash } from '@tabler/icons-react';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ExportForm from '../../export/forms/ExportForm';
import SubRowTable from './SubRowTable';

const initialPopupState = {
    state: {
        delete: false,
        export: false
    }
};

const popupConfig = {
    delete: {
        model: 'standard',
        title: 'Usuń zasób',
        maxWidth: 'sm'
    },
    export: {
        title: 'Export',
        content: (props) => <ExportForm {...props} />,
        maxWidth: 'sm'
    }
};

const FetchSubTable = ({
    fetchAction,
    fetchSelector,
    columns = [],
    rowActions,
    deleteSelector,
    resetDeleteAction,
    openPopup,
    refreshTriggers,
    initialColumnVisibility,
    enableRowSelection,
    toolbarActions,
    groupDelete,
    deleteAction,
    model,
    isExport,
    subRowSelector,
    subRowColumns
}) => {
    const [popupState, triggerPopup, closePopup, popupData] = usePopupManager(initialPopupState);

    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);
    const [rowSelection, setRowSelection] = useState({});

    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10
    });

    const dispatch = useDispatch();
    const { data, loading, error } = useSelector(fetchSelector);
    const deleteState = useSelector(deleteSelector || (() => null));
    const isDelete = deleteState?.success || false;

    const shouldRefetch = useMemo(() => refreshTriggers.some((trigger) => trigger === true), [refreshTriggers]);

    useEffect(() => {
        setIsRefetching(true);

        const params = {
            start: `${pagination.pageIndex * pagination.pageSize}`,
            size: pagination.pageSize,
            filters: JSON.stringify(columnFilters ?? []),
            globalFilter: globalFilter ?? '',
            sorting: JSON.stringify(sorting ?? [])
        };

        dispatch(
            fetchAction({
                params: params
            })
        );
    }, [columnFilters, globalFilter, pagination.pageIndex, pagination.pageSize, sorting, isDelete, shouldRefetch, dispatch, fetchAction]);

    useEffect(() => {
        if (data && !loading) {
            setRowCount(data.meta?.totalRowCount || 0);
        }
        setIsRefetching(false);
    }, [data, loading]);

    useEffect(() => {
        if (isDelete && resetDeleteAction) {
            dispatch(resetDeleteAction());
            if (groupDelete) {
                closePopup('delete');
                setRowSelection({});
            }
        }
    }, [dispatch, isDelete, resetDeleteAction]);

    const memoizedColumns = useMemo(() => columns, [columns]);

    const popupProps = {
        request: () => dispatch(deleteAction({ formData: popupData.delete })),
        model: model,
        data: popupData.export
    };

    const table = useMaterialReactTable({
        columns: memoizedColumns,
        data: data.data ?? [],
        enableRowSelection: enableRowSelection,
        manualFiltering: true,
        manualPagination: true,
        manualSorting: true,
        muiToolbarAlertBannerProps: error
            ? {
                  color: 'error',
                  children: 'Error loading data'
              }
            : undefined,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        onRowSelectionChange: setRowSelection,
        rowCount,
        state: {
            columnFilters,
            globalFilter,
            isLoading: loading,
            pagination,
            showAlertBanner: error,
            showProgressBars: isRefetching,
            sorting,
            rowSelection
        },
        initialState: { columnVisibility: initialColumnVisibility },
        positionActionsColumn: 'last',
        enableRowActions: rowActions,
        renderRowActions: ({ row }) => rowActions && <ActionTableButton data={rowActions(row, openPopup)} />,
        renderTopToolbarCustomActions: ({ table }) => (
            <Box
                sx={{
                    display: 'flex',
                    gap: '16px',
                    padding: '8px',
                    flexWrap: 'wrap'
                }}
            >
                {toolbarActions.map((item, index) => (
                    <React.Fragment key={index}>
                        {item.isGroup ? (
                            <ActionTextButton data={item.data} variant="outlined" size="medium" label={item.label} param={table} />
                        ) : (
                            <Button
                                variant="outlined"
                                onClick={() => item.action(table)}
                                startIcon={item.icon}
                                disabled={item.isDisabled(table)}
                            >
                                {item.label}
                            </Button>
                        )}
                    </React.Fragment>
                ))}
                {isExport && (
                    <ActionTextButton
                        variant="outlined"
                        size="medium"
                        label="Export"
                        data={[
                            {
                                label: 'Export selected',
                                icon: <FileDownloadIcon />,
                                action: () => triggerPopup('export', table.getSelectedRowModel().rows),
                                isDisabled: !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                            },
                            {
                                label: 'Export all',
                                icon: <FileDownloadIcon />,
                                action: () => triggerPopup('export', [])
                            }
                        ]}
                    />
                )}
                {groupDelete && (
                    <Button
                        variant="outlined"
                        startIcon={<IconTrash />}
                        disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
                        onClick={() =>
                            triggerPopup(
                                'delete',
                                table.getSelectedRowModel().rows.map((item) => item.original.identifier)
                            )
                        }
                    >
                        Delete
                    </Button>
                )}
            </Box>
        ),
        renderDetailPanel: ({ row }) =>
            row.original[subRowSelector] ? <SubRowTable data={row.original[subRowSelector]} columns={subRowColumns} /> : null
    });

    return (
        <>
            <MaterialReactTable table={table} />
            {renderPopups(popupState, closePopup, popupConfig, popupProps)}
        </>
    );
};

export default FetchSubTable;
