import { thunkActionDjango, thunkActionNoToken, thunkFileActionDjango, thunkFileDownloadActionDjango } from '../../thunks/thunkActions';
import {
    CITIES_LIST,
    COUNTRIES_LIST,
    HELP_CENTER_CHECK_URL,
    HELP_CENTER_DETAILS,
    HELP_CENTER_LIST,
    POST_CODE_LIST,
    SITE_ADD_TO_FAVOURITE,
    SITE_FAVOURITE_CHECK,
    SITE_RECENT_VIEW,
    SITE_ROUTES_FAVOURITES_LIST,
    SITE_ROUTES_LIST,
    SLUG_INFORMATION,
    STREET_LIST,
    SYSTEM_EXPORT_FILE_DOWNLOAD,
    SYSTEM_TASK_LIST,
    SYSTEM_TASK_REFRESH,
    SYSTEM_TEMPLATE_FILE_DOWNLOAD,
    SYSTEM_TEMPLATE_FILE_IMPORT,
    TOUR_SITE_INFO,
    TOUR_SITE_VIEW
} from './otherActionsType';

export const countriesList = () => thunkActionDjango(`system/simple-serializer/countries/`, COUNTRIES_LIST);
export const citiesList = (params) => thunkActionDjango(`system/simple-serializer/cities/`, CITIES_LIST, 'get', null, params);
export const postCodeList = (params) => thunkActionDjango(`system/simple-serializer/postal_code/`, POST_CODE_LIST, 'get', null, params);
export const streetList = (params) => thunkActionDjango(`system/simple-serializer/streets/`, STREET_LIST, 'get', null, params);
export const systemTemplateFileDownload = (model, fileName) =>
    thunkFileDownloadActionDjango(`system/file-import/template/${model}/download/`, SYSTEM_TEMPLATE_FILE_DOWNLOAD, 'get', null, fileName);
export const systemTemplateFileImport = (model, data) =>
    thunkFileActionDjango(`system/file-import/?xlsx=1&is_import=1&import_method=${model}`, SYSTEM_TEMPLATE_FILE_IMPORT, 'post', data);
export const systemExportFileDownload = (model, data, fileName) =>
    thunkFileDownloadActionDjango(`system/file-export/${model}/download/`, SYSTEM_EXPORT_FILE_DOWNLOAD, 'post', data, fileName);
export const siteRoutesList = (params) => thunkActionDjango(`system/site-routes/`, SITE_ROUTES_LIST, 'get', null, params);
export const siteRoutesFavouritesList = (params) =>
    thunkActionDjango(`system/site-routes/`, SITE_ROUTES_FAVOURITES_LIST, 'get', null, params);
export const siteRecentView = (url) => thunkActionDjango(`system/site-routes/?url=${url}&is_favourite=0`, SITE_RECENT_VIEW, 'put');
export const siteAddToFavourite = (url) => thunkActionDjango(`system/site-routes/?url=${url}&is_favourite=1`, SITE_ADD_TO_FAVOURITE, 'put');
export const helpCenterList = () => thunkActionNoToken(`help/news/public/list/?tag=instruction`, HELP_CENTER_LIST);
export const helpCenterDetails = (slug) => thunkActionNoToken(`help/news/public/detail/${slug}/`, HELP_CENTER_DETAILS);
export const slugInformation = (slug) => thunkActionDjango(`help/domain-dictionary/${slug}/`, SLUG_INFORMATION);
export const tourSiteInfo = (url) => thunkActionDjango(`system/site-routes/?url=${url}&is_read=1`, TOUR_SITE_INFO);
export const tourSiteView = (url) => thunkActionDjango(`system/site-routes/?url=${url}&is_read=1`, TOUR_SITE_VIEW, 'put');
export const siteFavouriteCheck = (url) => thunkActionDjango(`system/site-routes/?url=${url}&is_favourite=1`, SITE_FAVOURITE_CHECK);
export const helpCenterCheckUrl = (url) => thunkActionDjango(`help/help-page/?help_page_url=${url}`, HELP_CENTER_CHECK_URL);
export const systemTaskList = () => thunkActionDjango(`system/async-task/list/`, SYSTEM_TASK_LIST);
export const systemTaskRefresh = (form) => thunkActionDjango(`system/async-task/run/`, SYSTEM_TASK_REFRESH, 'post', form);
