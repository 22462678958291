import { lazy } from 'react';
import Loadable from '../../components/Loadable';

const MarketWatcherList = Loadable(lazy(() => import('../../pages/marketWatcher/list')));
const MarketWatcherProductList = Loadable(lazy(() => import('../../pages/marketWatcher/list/product')));

export const MarketWatcherRoutes = [
    {
        path: 'market-watcher',
        children: [
            {
                path: 'list',
                element: <MarketWatcherList />,
                children: [{ path: 'product', element: <MarketWatcherProductList /> }]
            }
        ]
    }
];
