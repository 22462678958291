import React, { useState } from 'react';
import { Box, Container, Stack, IconButton, Typography, useTheme } from '@mui/material';
import Slider from 'react-slick';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import 'slick-carousel/slick/slick.css';
import styled from 'styled-components';
import 'slick-carousel/slick/slick-theme.css';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { formatDate } from '../../../utils/date';

const StyledSlider = styled(Slider)`
    .slick-next:before {
        content: '';
    }
    .slick-prev:before {
        content: '';
    }
    .slick-prev,
    .slick-next {
        background-color: #001d66;
        width: 36px;
        height: 36px;
        color: white;
        z-index: 9;
    }
`;

const SlickArrowLeft = ({ currentSlide, ...props }) => (
    <IconButton
        {...props}
        className={'slick-prev slick-arrow' + (currentSlide === 0 ? ' slick-disabled' : '')}
        aria-hidden="true"
        aria-disabled={currentSlide === 0 ? true : false}
        type="button"
    >
        <ArrowBackIosNewIcon color="white" fontSize="small" />
    </IconButton>
);
const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <IconButton
        {...props}
        className={'slick-next slick-arrow' + (currentSlide === slideCount - 1 ? ' slick-disabled' : '')}
        aria-hidden="true"
        aria-disabled={currentSlide === slideCount - 1 ? true : false}
        type="button"
    >
        <ArrowForwardIosIcon color="white" fontSize="small" />
    </IconButton>
);

const MainSlider = () => {
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const [fade, setFade] = useState(false);
    const theme = useTheme();

    const { data, loading } = useSelector((state) => state.other.helpCenterList);

    const handleAfterChange = (index) => {
        setFade(true);
        setTimeout(() => {
            setCurrentSlideIndex(index);
            setFade(false);
        }, 300);
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        nextArrow: <SlickArrowRight />,
        prevArrow: <SlickArrowLeft />,
        afterChange: handleAfterChange
    };

    if (loading) {
        return 'Loading...';
    }

    return (
        <Box
            sx={{
                paddingTop: '80px',
                paddingBottom: '80px',
                position: 'relative',
                [theme.breakpoints.up('md')]: {
                    paddingTop: '120px'
                }
            }}
        >
            <Container
                maxWidth="lg"
                sx={{
                    position: 'relative',
                    zIndex: 9
                }}
            >
                <Stack spacing={2}>
                    <StyledSlider {...settings}>
                        {data.map((slide, index) => (
                            <div key={index}>
                                <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ display: 'flex' }}>
                                    <span
                                        style={{
                                            overflow: 'hidden',
                                            position: 'relative',
                                            verticalAlign: 'bottom',
                                            display: 'inline-block',
                                            flexGrow: '1',
                                            height: '500px'
                                        }}
                                    >
                                        <Box
                                            component="img"
                                            src={slide.image}
                                            alt={slide.image_alt}
                                            sx={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',
                                                verticalAlign: 'bottom'
                                            }}
                                        />
                                    </span>
                                    <Stack
                                        spacing={2}
                                        sx={{
                                            bgcolor: 'white',
                                            [theme.breakpoints.up('sm')]: {
                                                padding: 8,
                                                width: '100%',
                                                maxWidth: 396
                                            },
                                            [theme.breakpoints.up(0)]: {
                                                padding: 4
                                            }
                                        }}
                                    >
                                        <Typography variant="caption" color="grey.500">
                                            {formatDate(slide.date_created)}
                                        </Typography>
                                        <Typography
                                            variant="h3"
                                            component={Link}
                                            to={`/help-center/${slide.news_url}`}
                                            style={{ textDecoration: 'none' }}
                                        >
                                            {slide.title}
                                        </Typography>

                                        <Typography variant="body1" color="grey.600">
                                            <div dangerouslySetInnerHTML={{ __html: slide.short_text }} />
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </div>
                        ))}
                    </StyledSlider>
                </Stack>
            </Container>
            <Box
                sx={{
                    overflow: 'hidden',
                    position: 'absolute',
                    verticalAlign: 'bottom',
                    display: 'inline-block',
                    top: 0,
                    width: '100%',
                    height: '100%',
                    opacity: fade ? 0 : 1,
                    transition: 'opacity 0.9s ease-in-out',
                    '&::before': {
                        content: '""',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        zIndex: 1,
                        position: 'absolute',
                        background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 75%)'
                    }
                }}
            >
                <Box
                    component="img"
                    alt="post cover"
                    src={data[currentSlideIndex].image}
                    sx={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        verticalAlign: 'bottom'
                    }}
                />
            </Box>
        </Box>
    );
};

export default MainSlider;
