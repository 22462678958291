import { Warning } from '@mui/icons-material';
import { Avatar, Badge, Box, IconButton, Stack, Typography, styled } from '@mui/material';
import { formatDistance } from 'date-fns';
import React from 'react';
import { Link } from 'react-router-dom';
import { getModelIcon, getModelTypeColor, getModelTypeIcon } from '../../../../../../../utils/model-type';
import CheckIcon from '@mui/icons-material/Check';

const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`
}));

const DefaultCard = ({ text, date_created, is_priority, content_type, is_read, identifier, operation_type, handleMarkAsRead }) => {
    const handleMarkAsReadClick = () => {
        handleMarkAsRead(identifier);
    };

    return (
        <>
            <Box
                display="flex"
                alignItems="center"
                sx={{
                    padding: '10px 10px 10px 0px',
                    alignItems: 'flex-start',
                    width: '100%'
                }}
            >
                <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    badgeContent={
                        <SmallAvatar sx={{ bgcolor: getModelTypeColor(operation_type.toLowerCase()) }}>
                            {getModelTypeIcon(operation_type.toLowerCase())}
                        </SmallAvatar>
                    }
                >
                    <Avatar sx={{ bgcolor: 'primary.main' }}>{getModelIcon(content_type.toLowerCase())}</Avatar>
                </Badge>
                <Box ml={2}>
                    <Typography variant="body1">{text}</Typography>
                    <Stack direction="row" spacing={1.5} alignItems="center">
                        <Typography variant="body2">
                            {formatDistance(new Date(date_created), new Date(), {
                                addSuffix: true
                            })}
                        </Typography>
                        <Box
                            sx={{
                                display: 'inline-flex',
                                width: '4px',
                                height: '4px',
                                backgroundColor: 'grey.400',
                                borderRadius: '72px'
                            }}
                        />
                        <Typography
                            variant="body2"
                            component={Link}
                            color="primary"
                            fontWeight={600}
                            sx={{
                                textDecoration: 'none',
                                '&:hover': {
                                    textDecoration: 'underline'
                                }
                            }}
                        >
                            {content_type}
                        </Typography>
                        {!is_read && (
                            <>
                                <Box
                                    sx={{
                                        display: 'inline-flex',
                                        width: '4px',
                                        height: '4px',
                                        backgroundColor: 'grey.400',
                                        borderRadius: '72px'
                                    }}
                                />
                                <IconButton aria-label="mark as read" size="small" onClick={handleMarkAsReadClick}>
                                    <CheckIcon fontSize="inherit" color="success" />
                                </IconButton>
                            </>
                        )}
                    </Stack>
                </Box>
            </Box>
            {is_priority && (
                <Box sx={{ padding: '10px 10px 10px 0px' }}>
                    <IconButton size="small" color="warning">
                        <Warning />
                    </IconButton>
                </Box>
            )}
        </>
    );
};

export default DefaultCard;
