export const favoritesPage = {
    dashboard: [
        {
            title: 'Dashboard overview',
            url: '/dashboard/overview',
            model: 'info',
            description: 'Provides a general overview of the dashboard with key metrics and insights.'
        },
        {
            title: 'Dashboard orders',
            url: '/dashboard/orders',
            model: 'order',
            description: 'Displays order statistics and management options.'
        },
        {
            title: 'Dashboard notification',
            url: '/dashboard/notification',
            model: 'notification',
            description: 'Shows recent notifications and alerts for the dashboard.'
        },
        {
            title: 'Dashboard analysis',
            url: '/dashboard/analysis',
            model: 'analysis',
            description: 'Presents detailed analysis and reports based on dashboard data.'
        },
        {
            title: 'Dashboard statistics',
            url: '/dashboard/statistics',
            model: 'statistics',
            description: 'Contains various statistics related to the dashboard performance.'
        }
    ],
    product: [
        {
            title: 'Products list',
            url: '/product/list/base',
            model: 'product',
            description: 'Lists all products available in the inventory.'
        },
        {
            title: 'Special identifier list',
            url: '/product/list/special-identifier',
            model: 'product',
            description: 'Displays a list of products with special identifiers for quick access and categorization.'
        }
    ],
    delivery: [
        {
            title: 'Warehouse delivery',
            url: '/delivery/list/base',
            model: 'warehouse',
            description: 'Manages and tracks deliveries to the warehouse.'
        },
        {
            title: 'Company delivery',
            url: '/delivery/list/company',
            model: 'order',
            description: 'Oversees deliveries made by the company to customers.'
        },
        {
            title: 'Order delivery',
            url: '/delivery/list/order',
            model: 'order',
            description: "Tracks individual orders' delivery statuses, detailing the journey from warehouse to the customer."
        }
    ],
    status: [
        {
            title: 'Status delivery',
            url: '/status?model=warehouse_delivery',
            model: 'status',
            description: 'Provides the current status of warehouse deliveries.'
        }
    ],
    chat: [
        {
            title: 'Chat',
            url: '/chat?type=all',
            model: 'chat',
            description: 'Accesses the chat system for communication with team members.'
        }
    ],
    statistics: [
        {
            title: 'Statistics overview',
            url: '/statistics/overview',
            model: 'statistics',
            description: 'Provides a comprehensive summary of key statistical metrics and performance indicators.'
        },
        {
            title: 'Profit analysis',
            url: '/statistics/profit-analysis',
            model: 'statistics',
            description: 'Analyzes profit margins and financial performance to inform strategic decisions.'
        },
        {
            title: 'Create own tables',
            url: '/statistics/dashboard',
            model: 'statistics',
            description: 'Allows users to create custom tables for analyzing profit margins and performance.'
        }
    ],
    sales: [
        {
            title: 'Sales report',
            url: '/sales/list/report',
            model: 'report',
            description: 'Generates and views sales reports.'
        },
        {
            title: 'Sales template',
            url: '/sales/list/template',
            model: 'template',
            description: 'Provides templates for creating sales reports.'
        },
        {
            title: 'Sales alert',
            url: '/sales/list/alert',
            model: 'notification',
            description: 'Displays alerts related to sales activities, notifying users about important updates and actions.'
        }
    ],
    account: [
        {
            title: 'Account information',
            url: '/account/settings/profile',
            model: 'account',
            description: 'Manages personal account information and settings.'
        },
        {
            title: 'Account security',
            url: '/account/settings/security',
            model: 'security',
            description: 'Handles security settings and options for the account.'
        },
        {
            title: 'Account notification',
            url: '/account/settings/notification',
            model: 'notification',
            description: 'Configures notification settings for the account.'
        },
        {
            title: 'Organization information',
            url: '/account/settings/information',
            model: 'info',
            description: 'Contains information about the organization.'
        },
        {
            title: 'License information',
            url: '/account/settings/license',
            model: 'license',
            description: 'Displays license information and management options.'
        },
        {
            title: 'Connections information',
            url: '/account/settings/connections',
            model: 'connections',
            description: 'Manages connections and integrations with other services.'
        }
    ],
    targets: [
        {
            title: 'Targets List',
            url: '/targets',
            model: 'targets',
            description: 'Displays the current status of warehouse delivery targets.'
        }
    ],
    costs: [
        {
            title: 'Costs List',
            url: '/costs',
            model: 'costs',
            description: 'Shows the current costs associated with warehouse operations.'
        }
    ],
    order: [
        {
            title: 'Orders List',
            url: '/order',
            model: 'order',
            description: 'Lists the current status of warehouse orders.'
        }
    ],
    help: [
        {
            title: 'Help Center',
            url: '/help-center',
            model: 'info',
            description: 'Provides assistance and information on warehouse operations.'
        }
    ],
    forecast: [
        {
            title: 'Forecast List',
            url: '/forecast',
            model: 'forecast',
            description: 'Displays a list of upcoming forecasts, including predictions and trend analyses.'
        }
    ],
    soa: [
        {
            title: 'SOA List',
            url: '/soa/overview/list',
            model: 'soa',
            description:
                'Displays a comprehensive list of Service-Oriented Architecture (SOA) components, including detailed overviews, configurations, and associated metadata.'
        }
    ],
    // marketWatcher: [
    //     {
    //         title: 'Market watcher product list',
    //         url: '/market-watcher/list/product',
    //         model: 'marketWatcher',
    //         description:
    //             'Displays a detailed list of market watcher products, including analytics, trends, and monitoring features for market dynamics.'
    //     }
    // ],
    customReports: [
        {
            title: 'Own Reports',
            url: '/cole/list/template',
            model: 'report',
            description:
                'Displays a list of custom reports created by the user, including templates and predefined configurations for data analysis.'
        },
        {
            title: 'Imported Reports',
            url: '/cole/list/dynamic',
            model: 'report',
            description:
                'Displays a list of reports imported from external sources, including dynamic data integrations and real-time updates.'
        }
    ]
};
