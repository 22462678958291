import { combineReducers } from 'redux';
import {
    userAssignedCompanyListReducer,
    userCompanyChangeReducer,
    userCompanyConnectionAddReducer,
    userCompanyConnectionDeleteReducer,
    userCompanyConnectionsListReducer,
    userCompanyDetailsReducer,
    userCompanyEditReducer,
    userCompanyGetListReducer,
    userCompanyImageAddReducer,
    userDetailsEditReducer,
    userDetailsReducer,
    userImageAddReducer
} from './userReducers';

const userReducer = combineReducers({
    userCompanyList: userCompanyGetListReducer,
    userCompanyEdit: userCompanyEditReducer,
    userAssignedCompanyList: userAssignedCompanyListReducer,
    userCompanyChange: userCompanyChangeReducer,
    userCompanyDetails: userCompanyDetailsReducer,
    userDetails: userDetailsReducer,
    userDetailsEdit: userDetailsEditReducer,
    userImageAdd: userImageAddReducer,
    userCompanyImageAdd: userCompanyImageAddReducer,
    userCompanyConnectionsList: userCompanyConnectionsListReducer,
    userCompanyConnectionAdd: userCompanyConnectionAddReducer,
    userCompanyConnectionDelete: userCompanyConnectionDeleteReducer
});

export default userReducer;
