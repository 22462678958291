import { createSlice } from '@reduxjs/toolkit';
import { createInitialState } from '../../../utils/initialState';
import { createSubReducer } from '../../../utils/createReducer';
import {
    coleAdd,
    coleDelete,
    coleDetails,
    coleDynamicDetails,
    coleDynamicImport,
    coleDynamicList,
    coleEdit,
    coleList,
    coleOptionsList
} from '../../../actions/cole/actions';

const initialState = {
    list: createInitialState({ data: [] }),
    delete: createInitialState(),
    add: createInitialState(),
    details: createInitialState({ loading: true }),
    edit: createInitialState(),
    dynamicList: createInitialState({ data: [] }),
    options: createInitialState({ data: [] }),
    dynamicImport: createInitialState(),
    dynamicDetails: createInitialState({ loading: true })
};

const coleSlice = createSlice({
    name: 'cole',
    initialState,
    reducers: {
        resetColeState: (state, action) => {
            const { key } = action.payload;
            state[key] = initialState[key];
        }
    },
    extraReducers: (builder) => {
        const actionsMap = {
            list: coleList,
            delete: coleDelete,
            add: coleAdd,
            details: coleDetails,
            edit: coleEdit,
            dynamicList: coleDynamicList,
            options: coleOptionsList,
            dynamicImport: coleDynamicImport,
            dynamicDetails: coleDynamicDetails
        };

        Object.keys(actionsMap).forEach((key) => {
            createSubReducer(builder, actionsMap[key], key);
        });
    }
});

export const { resetColeState } = coleSlice.actions;
export default coleSlice.reducer;
