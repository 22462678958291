import Button from '@mui/material/Button';
import MainCard from '../ui-component/cards/MainCard';
import { Link } from 'react-router-dom';
import FetchTable from './types/FetchTable';
import BaseTable from './types/BaseTable';
import CoreTable from './types/CoreTable';
import FetchSubTable from './types/FetchSubTable';
import DragTable from './types/DragTable';
import FetchDetailsTable from './types/FetchDetailsTable';
import LocalTable from './types/LocalTable';

const Table = ({
    type = 'fetch',
    title,
    action,
    selector,
    columns,
    addPath,
    rowActions,
    deleteSelector,
    resetDeleteAction,
    data = [],
    isLoading,
    dataSelector = null,
    addHandle,
    openPopup,
    exportXlsxAction,
    exportPdfAction,
    isExport = false,
    refreshTriggers = [],
    identifier = '',
    expandRowParameter = '',
    expandColumns = [],
    initialColumnVisibility = {},
    enableRowSelection = false,
    toolbarActions = [],
    deleteAction,
    groupDelete = false,
    slug,
    model = '',
    subRowSelector = '',
    subRowColumns = [],
    isRowOrdering,
    subRowDetails,
    otherHandle,
    otherRowProps,
    filters = []
}) => {
    let TableComponent;

    switch (type) {
        case 'fetch':
            TableComponent = FetchTable;
            break;
        case 'base':
            TableComponent = BaseTable;
            break;
        case 'core':
            TableComponent = CoreTable;
            break;
        case 'fetchSub':
            TableComponent = FetchSubTable;
            break;
        case 'drag':
            TableComponent = DragTable;
            break;
        case 'fetchDetails':
            TableComponent = FetchDetailsTable;
            break;
        case 'local':
            TableComponent = LocalTable;
            break;
        default:
            TableComponent = FetchTable;
            break;
    }

    return (
        <MainCard
            title={title}
            content={false}
            secondary={
                <>
                    {addPath && (
                        <Button variant="contained" component={Link} to={addPath}>
                            Add
                        </Button>
                    )}
                    {addHandle && (
                        <Button variant="contained" onClick={addHandle}>
                            Add
                        </Button>
                    )}
                    {otherHandle && otherHandle}
                </>
            }
            slug={slug}
        >
            <TableComponent
                fetchAction={action}
                fetchSelector={selector}
                deleteSelector={deleteSelector}
                columns={columns}
                rowActions={rowActions}
                resetDeleteAction={resetDeleteAction}
                data={data}
                isLoading={isLoading}
                dataSelector={dataSelector}
                openPopup={openPopup}
                exportXlsxAction={exportXlsxAction}
                exportPdfAction={exportPdfAction}
                isExport={isExport}
                refreshTriggers={refreshTriggers}
                identifier={identifier}
                expandRowParameter={expandRowParameter}
                expandColumns={expandColumns}
                initialColumnVisibility={initialColumnVisibility}
                enableRowSelection={enableRowSelection}
                toolbarActions={toolbarActions}
                deleteAction={deleteAction}
                groupDelete={groupDelete}
                model={model}
                subRowSelector={subRowSelector}
                subRowColumns={subRowColumns}
                isRowOrdering={isRowOrdering}
                subRowDetails={subRowDetails}
                otherRowProps={otherRowProps}
                filters={filters}
            />
        </MainCard>
    );
};

export default Table;
