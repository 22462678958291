import {
    PRICING_ADD,
    PRICING_DELETE,
    PRICING_EDIT,
    PRICING_HISTORY_LIST,
    PRICING_PRODUCT_DETAILS,
    PRICING_PRODUCT_LIST,
    PRODUCTS_LIST,
    PRODUCT_ADD,
    PRODUCT_BRAND_LIST,
    PRODUCT_CALCULATE_MOM,
    PRODUCT_CALCULATE_WOW,
    PRODUCT_CALCULATE_XOX,
    PRODUCT_CALCULATE_YOY,
    PRODUCT_CATEGORIES_LIST,
    PRODUCT_DELETE,
    PRODUCT_DETAILS,
    PRODUCT_EAN_LIST,
    PRODUCT_EDIT,
    PRODUCT_IDENTIFIER_LIST,
    PRODUCT_LICENSE_LIST,
    PRODUCT_NAME_LIST,
    PRODUCT_PRICING_HISTORY_LIST,
    PRODUCT_SKU_LIST,
    PRODUCT_SPECIAL_IDENTIFIER_ADD,
    PRODUCT_SPECIAL_IDENTIFIER_DELETE,
    PRODUCT_SPECIAL_IDENTIFIER_DETAILS,
    PRODUCT_SPECIAL_IDENTIFIER_EDIT,
    PRODUCT_SPECIAL_IDENTIFIER_LIST,
    SPECIAL_IDENTIFIER_LIST
} from '../../../actions/shop/shopActionsType';
import { globalGetReducer, globalPostReducer } from '../../helpers';
import { PRODUCT_CALCULATE_QOQ } from './../../../actions/shop/shopActionsType';

export const productsListReducer = globalGetReducer(PRODUCTS_LIST.START, PRODUCTS_LIST.SUCCESS, PRODUCTS_LIST.FAIL);
export const productAddReducer = globalPostReducer(PRODUCT_ADD.START, PRODUCT_ADD.SUCCESS, PRODUCT_ADD.FAIL, PRODUCT_ADD.RESET);
export const productDetailsReducer = globalGetReducer(PRODUCT_DETAILS.START, PRODUCT_DETAILS.SUCCESS, PRODUCT_DETAILS.FAIL);
export const productEditReducer = globalPostReducer(PRODUCT_EDIT.START, PRODUCT_EDIT.SUCCESS, PRODUCT_EDIT.FAIL, PRODUCT_EDIT.RESET);
export const productsLicenseListReducer = globalGetReducer(
    PRODUCT_LICENSE_LIST.START,
    PRODUCT_LICENSE_LIST.SUCCESS,
    PRODUCT_LICENSE_LIST.FAIL
);
export const productDeleteReducer = globalPostReducer(
    PRODUCT_DELETE.START,
    PRODUCT_DELETE.SUCCESS,
    PRODUCT_DELETE.FAIL,
    PRODUCT_DELETE.RESET
);
export const productCategoriesListReducer = globalGetReducer(
    PRODUCT_CATEGORIES_LIST.START,
    PRODUCT_CATEGORIES_LIST.SUCCESS,
    PRODUCT_CATEGORIES_LIST.FAIL
);

export const productSkuListReducer = globalGetReducer(PRODUCT_SKU_LIST.START, PRODUCT_SKU_LIST.SUCCESS, PRODUCT_SKU_LIST.FAIL);
export const productEanListReducer = globalGetReducer(PRODUCT_EAN_LIST.START, PRODUCT_EAN_LIST.SUCCESS, PRODUCT_EAN_LIST.FAIL);
export const productBrandListReducer = globalGetReducer(PRODUCT_BRAND_LIST.START, PRODUCT_BRAND_LIST.SUCCESS, PRODUCT_BRAND_LIST.FAIL);
export const productNameListReducer = globalGetReducer(PRODUCT_NAME_LIST.START, PRODUCT_NAME_LIST.SUCCESS, PRODUCT_NAME_LIST.FAIL);
export const productIdentifierListReducer = globalGetReducer(
    PRODUCT_IDENTIFIER_LIST.START,
    PRODUCT_IDENTIFIER_LIST.SUCCESS,
    PRODUCT_IDENTIFIER_LIST.FAIL
);
export const pricingAddReducer = globalPostReducer(PRICING_ADD.START, PRICING_ADD.SUCCESS, PRICING_ADD.FAIL, PRICING_ADD.RESET);
export const pricingProductListReducer = globalGetReducer(
    PRICING_PRODUCT_LIST.START,
    PRICING_PRODUCT_LIST.SUCCESS,
    PRICING_PRODUCT_LIST.FAIL
);
export const pricingDeleteReducer = globalPostReducer(
    PRICING_DELETE.START,
    PRICING_DELETE.SUCCESS,
    PRICING_DELETE.FAIL,
    PRICING_DELETE.RESET
);
export const pricingProductDetailsReducer = globalGetReducer(
    PRICING_PRODUCT_DETAILS.START,
    PRICING_PRODUCT_DETAILS.SUCCESS,
    PRICING_PRODUCT_DETAILS.FAIL
);
export const pricingEditReducer = globalPostReducer(PRICING_EDIT.START, PRICING_EDIT.SUCCESS, PRICING_EDIT.FAIL, PRICING_EDIT.RESET);
export const pricingHistoryListReducer = globalGetReducer(
    PRICING_HISTORY_LIST.START,
    PRICING_HISTORY_LIST.SUCCESS,
    PRICING_HISTORY_LIST.FAIL
);

export const productPricingHistoryListReducer = globalGetReducer(
    PRODUCT_PRICING_HISTORY_LIST.START,
    PRODUCT_PRICING_HISTORY_LIST.SUCCESS,
    PRODUCT_PRICING_HISTORY_LIST.FAIL
);
export const productCalculateXoxReducer = globalGetReducer(
    PRODUCT_CALCULATE_XOX.START,
    PRODUCT_CALCULATE_XOX.SUCCESS,
    PRODUCT_CALCULATE_XOX.FAIL
);
export const productCalculateWowReducer = globalGetReducer(
    PRODUCT_CALCULATE_WOW.START,
    PRODUCT_CALCULATE_WOW.SUCCESS,
    PRODUCT_CALCULATE_WOW.FAIL
);
export const productCalculateMomReducer = globalGetReducer(
    PRODUCT_CALCULATE_MOM.START,
    PRODUCT_CALCULATE_MOM.SUCCESS,
    PRODUCT_CALCULATE_MOM.FAIL
);
export const productCalculateQoqReducer = globalGetReducer(
    PRODUCT_CALCULATE_QOQ.START,
    PRODUCT_CALCULATE_QOQ.SUCCESS,
    PRODUCT_CALCULATE_QOQ.FAIL
);
export const productCalculateYoyReducer = globalGetReducer(
    PRODUCT_CALCULATE_YOY.START,
    PRODUCT_CALCULATE_YOY.SUCCESS,
    PRODUCT_CALCULATE_YOY.FAIL
);
export const productSpecialIdentifierListReducer = globalGetReducer(
    PRODUCT_SPECIAL_IDENTIFIER_LIST.START,
    PRODUCT_SPECIAL_IDENTIFIER_LIST.SUCCESS,
    PRODUCT_SPECIAL_IDENTIFIER_LIST.FAIL
);
export const productSpecialIdentifierAddReducer = globalPostReducer(
    PRODUCT_SPECIAL_IDENTIFIER_ADD.START,
    PRODUCT_SPECIAL_IDENTIFIER_ADD.SUCCESS,
    PRODUCT_SPECIAL_IDENTIFIER_ADD.FAIL,
    PRODUCT_SPECIAL_IDENTIFIER_ADD.RESET
);
export const productSpecialIdentifierDeleteReducer = globalPostReducer(
    PRODUCT_SPECIAL_IDENTIFIER_DELETE.START,
    PRODUCT_SPECIAL_IDENTIFIER_DELETE.SUCCESS,
    PRODUCT_SPECIAL_IDENTIFIER_DELETE.FAIL,
    PRODUCT_SPECIAL_IDENTIFIER_DELETE.RESET
);
export const productSpecialIdentifierEditReducer = globalPostReducer(
    PRODUCT_SPECIAL_IDENTIFIER_EDIT.START,
    PRODUCT_SPECIAL_IDENTIFIER_EDIT.SUCCESS,
    PRODUCT_SPECIAL_IDENTIFIER_EDIT.FAIL,
    PRODUCT_SPECIAL_IDENTIFIER_EDIT.RESET
);
export const productSpecialIdentifierDetailsReducer = globalPostReducer(
    PRODUCT_SPECIAL_IDENTIFIER_DETAILS.START,
    PRODUCT_SPECIAL_IDENTIFIER_DETAILS.SUCCESS,
    PRODUCT_SPECIAL_IDENTIFIER_DETAILS.FAIL,
    PRODUCT_SPECIAL_IDENTIFIER_DETAILS.RESET
);
export const specialIdentifierListReducer = globalGetReducer(
    SPECIAL_IDENTIFIER_LIST.START,
    SPECIAL_IDENTIFIER_LIST.SUCCESS,
    SPECIAL_IDENTIFIER_LIST.FAIL
);
