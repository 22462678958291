import {
    STATUS_ADD,
    STATUS_DELETE,
    STATUS_EDIT,
    STATUS_LIST,
    STATUS_ORDER_CHANGE,
    STATUS_SIMPLE_LIST
} from '../../../actions/status/statusActionsType';
import { globalGetReducer, globalPostReducer } from '../../helpers';

export const statusListReducer = globalGetReducer(STATUS_LIST.START, STATUS_LIST.SUCCESS, STATUS_LIST.FAIL);
export const statusSimpleListReducer = globalGetReducer(STATUS_SIMPLE_LIST.START, STATUS_SIMPLE_LIST.SUCCESS, STATUS_SIMPLE_LIST.FAIL);
export const statusAddReducer = globalPostReducer(STATUS_ADD.START, STATUS_ADD.SUCCESS, STATUS_ADD.FAIL, STATUS_ADD.RESET);
export const statusDeleteReducer = globalPostReducer(STATUS_DELETE.START, STATUS_DELETE.SUCCESS, STATUS_DELETE.FAIL, STATUS_DELETE.RESET);
export const statusEditReducer = globalPostReducer(STATUS_EDIT.START, STATUS_EDIT.SUCCESS, STATUS_EDIT.FAIL, STATUS_EDIT.RESET);
export const statusOrderChangeReducer = globalPostReducer(
    STATUS_ORDER_CHANGE.START,
    STATUS_ORDER_CHANGE.SUCCESS,
    STATUS_ORDER_CHANGE.FAIL,
    STATUS_ORDER_CHANGE.RESET
);
