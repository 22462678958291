import { createAction } from '@reduxjs/toolkit';
import { createThunkAction, createThunkFileAction } from '../../thunks/actions';

export const userAssignedCompanyList = createThunkAction('user/assigned/company/list', 'users/company/user-assigned/');
export const userCompanyConnectionChange = createThunkAction(
    'user/company/connection/change',
    (vat) => `users/company/detail/${vat}/change-to/`
);
export const userEdit = createThunkAction('user/details/edit', (id) => `users/details/${id}/update/`, 'put');
export const userImageAdd = createThunkFileAction('user/image/add', 'users/image/add/', 'post');
export const userCompanyImageAdd = createThunkFileAction('user/company/image/add', 'users/company/image/add/', 'post');
export const userCompanyConnectionList = createThunkAction('user/company/connections/list', 'users/company/connections/list/');
export const userCompanyConnectionAdd = createThunkAction('user/company/connection/add', 'users/company/connections/add/', 'post');
export const userCompanyConnectionDelete = createThunkAction(
    'user/company/connection/delete',
    (id) => `users/company/connections/detail/${id}/delete/`,
    'delete'
);
export const userSetDetails = createAction('user/setDetails');
export const userCompanyDetails = createThunkAction('user/company/details', (vat) => `users/company/detail/${vat}/`);
export const userCompanyEdit = createThunkAction('user/company/edit', (vat) => `users/company/detail/${vat}/update/`, 'put');
