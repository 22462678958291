export const dashboardOverview = {
    path: '/dashboard/overview',
    data: [
        {
            selector: '[data-tour="step-1"]',
            resizeObservables: ['[data-tour="step-1"]'],
            content: `Ok, let's start with the name of the Tour that is about to begin.`
        },
        {
            selector: '[data-tour="step-2"]',
            content: `Ok, let's start with the name of the Tour that is about to begin.`,
            position: 'right'
        },
        {
            selector: '[data-tour="step-3-1"]',
            content: `Ok, let's start with the name of the Tour that is about to begin.`,
            position: 'right'
        },
        {
            selector: '[data-tour="step-3-2"]',
            content: `Ok, let's start with the name of the Tour that is about to begin.`,
            position: 'right'
        },
        {
            selector: '[data-tour="step-3-3"]',
            content: `Ok, let's start with the name of the Tour that is about to begin.`,
            position: 'right'
        },
        {
            selector: '[data-tour="step-3-4"]',
            content: `Ok, let's start with the name of the Tour that is about to begin.`,
            position: 'right'
        },
        {
            selector: '[data-tour="step-4"]',
            content: `Ok, let's start with the name of the Tour that is about to begin.`,
            position: 'right'
        },
        {
            selector: '[data-tour="step-5"]',
            content: `Ok, let's start with the name of the Tour that is about to begin.`,
            position: 'right'
        },
        {
            selector: '[data-tour="step-6"]',
            content: `Ok, let's start with the name of the Tour that is about to begin.`,
            position: 'right'
        },
        {
            selector: '[data-tour="step-7"]',
            content: `Now, let's take a look at the description section.`,
            position: 'right'
        },
        {
            selector: '[data-tour="step-8"]',
            content: `Here you can see the available dates for the tour.`,
            position: 'right'
        },
        {
            selector: '[data-tour="step-9"]',
            content: `This section displays the tour pricing.`,
            position: 'right'
        },
        {
            selector: '[data-tour="step-10"]',
            content: `Here you can find the tour itinerary.`,
            position: 'right'
        },
        {
            selector: '[data-tour="step-11"]',
            content: `This part shows the customer reviews.`,
            position: 'right'
        },
        {
            selector: '[data-tour="step-12"]',
            content: `Check out the FAQ section for common questions.`,
            position: 'right'
        },
        {
            selector: '[data-tour="step-13"]',
            content: `Don't forget to look at the terms and conditions.`,
            position: 'right'
        },
        {
            selector: '[data-tour="step-14"]',
            content: `Here you can contact the tour operator for more information.`,
            position: 'right'
        },
        {
            selector: '[data-tour="step-15"]',
            content: `Here you can contact the tour operator for more information.`,
            position: 'right'
        }
    ]
};
