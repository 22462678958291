import { LoadingButton as MuiLoadingButton } from '@mui/lab';
import AnimateButton from './AnimateButton';

const LoadingButton = ({ isLoading, label = '' }) => {
    return (
        <AnimateButton>
            <MuiLoadingButton
                disableElevation
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                loading={isLoading}
                loadingPosition="end"
            >
                {label}
            </MuiLoadingButton>
        </AnimateButton>
    );
};

export default LoadingButton;
