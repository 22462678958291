import { createSlice } from '@reduxjs/toolkit';
import { createInitialState } from '../../../utils/initialState';
import { createSubReducer } from '../../../utils/createReducer';
import {
    targetAdd,
    targetDelete,
    targetDetails,
    targetEdit,
    targetList,
    targetMain,
    targetRefresh,
    targetTypeOptions
} from '../../../actions/target/actions';

const initialState = {
    list: createInitialState({ loading: true }),
    edit: createInitialState(),
    typeOptions: createInitialState({ data: [] }),
    main: createInitialState({ loading: true }),
    delete: createInitialState(),
    add: createInitialState(),
    details: createInitialState({ loading: true }),
    refresh: createInitialState()
};

const targetSlice = createSlice({
    name: 'target',
    initialState,
    reducers: {
        resetTargetState: (state, action) => {
            const { key } = action.payload;
            state[key] = initialState[key];
        }
    },
    extraReducers: (builder) => {
        const actionsMap = {
            list: targetList,
            add: targetAdd,
            details: targetDetails,
            edit: targetEdit,
            delete: targetDelete,
            typeOptions: targetTypeOptions,
            main: targetMain,
            refresh: targetRefresh
        };

        Object.keys(actionsMap).forEach((key) => {
            createSubReducer(builder, actionsMap[key], key);
        });
    }
});

export const { resetTargetState } = targetSlice.actions;
export default targetSlice.reducer;
