import { COMPANY_ADD, COMPANY_CHECK_VAT, COMPANY_SIMPLE_LIST, COMPANY_TAGS_LIST } from '../../../actions/company/companyActionsType';
import { globalGetReducer, globalPostReducer } from '../../helpers';

export const companyTagsListReducer = globalGetReducer(COMPANY_TAGS_LIST.START, COMPANY_TAGS_LIST.SUCCESS, COMPANY_TAGS_LIST.FAIL);
export const companyCheckVatReducer = globalGetReducer(COMPANY_CHECK_VAT.START, COMPANY_CHECK_VAT.SUCCESS, COMPANY_CHECK_VAT.FAIL);
export const companyAddReducer = globalPostReducer(COMPANY_ADD.START, COMPANY_ADD.SUCCESS, COMPANY_ADD.FAIL, COMPANY_ADD.RESET);
export const companySimpleListReducer = globalGetReducer(
    COMPANY_SIMPLE_LIST.START,
    COMPANY_SIMPLE_LIST.SUCCESS,
    COMPANY_SIMPLE_LIST.FAIL,
    COMPANY_SIMPLE_LIST.RESET
);
