import { lazy } from 'react';
import MinimalLayout from '../../layout/MinimalLayout';
import { ThemeGuard } from '../guard';
import Loadable from '../../components/Loadable';
import { NavMotion } from '../../components/NavMotion';

const ForgotPassword = Loadable(lazy(() => import('../../pages/authentication/ForgotPassword')));
const Login = Loadable(lazy(() => import('../../pages/authentication/Login')));
const OtpValidate = Loadable(lazy(() => import('../../pages/authentication/OtpValidate')));
const Register = Loadable(lazy(() => import('../../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../../pages/authentication/ResetPassword')));
const SuccessRegister = Loadable(lazy(() => import('../../pages/authentication/Status/SuccessRegister')));

const AuthenticationRoutes = {
    element: (
        <NavMotion>
            <ThemeGuard>
                <MinimalLayout />
            </ThemeGuard>
        </NavMotion>
    ),
    children: [
        {
            path: 'register',
            element: <Register />
        },
        {
            path: 'register/success',
            element: <SuccessRegister />
        },
        {
            path: 'login',
            element: <Login />
        },
        {
            path: 'forgot-password',
            element: <ForgotPassword />
        },
        {
            path: 'reset-password/:uidb64/:token',
            element: <ResetPassword />
        },
        {
            path: 'otp',
            element: <OtpValidate />
        }
    ]
};

export default AuthenticationRoutes;
