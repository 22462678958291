import { createSlice } from '@reduxjs/toolkit';
import { createInitialState } from '../../../utils/initialState';
import { createSubReducer } from '../../../utils/createReducer';
import { companyOptionsList, companySetDetails } from '../../../actions/company/actions';

const initialState = {
    options: createInitialState({ data: [] }),
    details: createInitialState()
};

const companySlice = createSlice({
    name: 'company',
    initialState,
    reducers: {
        resetCompanyState: (state, action) => {
            const { key } = action.payload;
            state[key] = initialState[key];
        }
    },
    extraReducers: (builder) => {
        const actionsMap = {
            options: companyOptionsList
        };

        builder.addCase(companySetDetails, (state, action) => {
            state.details.data = action.payload;
        });

        Object.keys(actionsMap).forEach((key) => {
            createSubReducer(builder, actionsMap[key], key);
        });
    }
});

export const { resetCompanyState } = companySlice.actions;
export default companySlice.reducer;
