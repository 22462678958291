import { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useTheme } from '@mui/material/styles';
import { Box, useMediaQuery } from '@mui/material';

import Drawer from './Drawer';
import Header from './Header';

import menuItems from '../../components/menu-items';
import Breadcrumbs from '../../components/ui-component/Breadcrumbs';
import { openDrawer } from '../../services/reducer/reducers/menuReducer';
import { navbarHeight } from '../constant';
import { TourProvider } from '@reactour/tour';
import TourInfo from '../../components/tour';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const MainLayout = () => {
    const theme = useTheme();
    const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
    const dispatch = useDispatch();

    const { id } = useParams();

    const { drawerOpen } = useSelector((state) => state.menu);

    const [open, setOpen] = useState(theme.drawer === 'default' ? drawerOpen : false);

    const disableBody = (target) => disableBodyScroll(target);
    const enableBody = (target) => enableBodyScroll(target);

    const handleDrawerToggle = () => {
        setOpen(!open);
        dispatch(openDrawer({ drawerOpen: !open }));
    };

    useEffect(() => {
        if (theme.drawer === 'default') {
            setOpen(!matchDownLG);
            dispatch(openDrawer({ drawerOpen: !matchDownLG }));
        }
    }, [dispatch, matchDownLG, theme.drawer]);

    useEffect(() => {
        if (open !== drawerOpen && theme.drawer === 'default') setOpen(drawerOpen);
    }, [drawerOpen, open, theme.drawer]);

    return (
        <>
            <TourProvider
                styles={{
                    popover: (base) => ({
                        ...base,
                        '--reactour-accent': theme.palette.primary.main,
                        borderRadius: '4px'
                    })
                }}
                padding={{
                    popover: [25, 0]
                }}
                onClickMask={({ setCurrentStep, setIsOpen }) => {
                    setCurrentStep(() => 0);
                    setIsOpen(false);
                }}
                afterOpen={disableBody}
                beforeClose={enableBody}
                scrollSmooth
                showDots={false}
            >
                <Box sx={{ display: 'flex', width: '100%' }}>
                    <Header open={open} handleDrawerToggle={handleDrawerToggle} />
                    <Drawer open={open} handleDrawerToggle={handleDrawerToggle} setOpen={setOpen} />
                    <Box
                        component="main"
                        sx={{ width: 'calc(100% - 260px)', flexGrow: 1, p: { xs: 2, sm: 3 }, marginTop: `${navbarHeight}px` }}
                    >
                        <Breadcrumbs navigation={menuItems} identifier={id} icon title rightAlign />
                        <Outlet />
                    </Box>
                </Box>
                <TourInfo />
            </TourProvider>
            <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />

            {/* <PriorityNotification /> */}
        </>
    );
};

export default MainLayout;
