import { combineReducers } from 'redux';
import {
    costsAddReducer,
    costsCategoryListReducer,
    costsDeleteReducer,
    costsDetailsReducer,
    costsEditReducer,
    costsHistoryListReducer,
    costsListReducer,
    costsNameListReducer,
    costsSimpleListReducer,
    costsUnitListReducer
} from './costsReducers';

const costsReducer = combineReducers({
    list: costsListReducer,
    add: costsAddReducer,
    details: costsDetailsReducer,
    edit: costsEditReducer,
    delete: costsDeleteReducer,
    historyList: costsHistoryListReducer,
    simpleList: costsSimpleListReducer,
    nameList: costsNameListReducer,
    unitList: costsUnitListReducer,
    categoryList: costsCategoryListReducer
});

export default costsReducer;
