import { useNavigate, useSearchParams } from 'react-router-dom';

export const useUpdateSearchParams = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const updateSearchParams = (params) => {
        for (const key in params) {
            if (params[key] === null) {
                searchParams.delete(key);
            } else {
                searchParams.set(key, params[key]);
            }
        }
        navigate({ search: searchParams.toString() });
    };

    return updateSearchParams;
};
