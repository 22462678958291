import { createRequestTypes } from '../actionsTypes';

export const COSTS_LIST = createRequestTypes('COSTS_LIST');
export const COSTS_ADD = createRequestTypes('COSTS_ADD');
export const COSTS_DETAILS = createRequestTypes('COSTS_DETAILS');
export const COSTS_EDIT = createRequestTypes('COSTS_EDIT');
export const COSTS_DELETE = createRequestTypes('COSTS_DELETE');
export const COSTS_HISTORY_LIST = createRequestTypes('COSTS_HISTORY_LIST');
export const COSTS_SIMPLE_LIST = createRequestTypes('COSTS_SIMPLE_LIST');
export const COSTS_NAME_LIST = createRequestTypes('COSTS_NAME_LIST');
export const COSTS_UNIT_LIST = createRequestTypes('COSTS_UNIT_LIST');
export const COSTS_CATEGORY_LIST = createRequestTypes('COSTS_CATEGORY_LIST');
