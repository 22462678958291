import { lazy } from 'react';
import Loadable from '../../components/Loadable';

const PricingAdd = Loadable(lazy(() => import('../../pages/product/add')));
const ProductAdd = Loadable(lazy(() => import('../../pages/product/add')));
const ProductDetails = Loadable(lazy(() => import('../../pages/product/details')));
const ProductEdit = Loadable(lazy(() => import('../../pages/product/edit')));
const ProductsList = Loadable(lazy(() => import('../../pages/product/list')));
const ProductListBase = Loadable(lazy(() => import('../../pages/product/list/base')));
const SpecialIdentifierList = Loadable(lazy(() => import('../../pages/product/list/specialIdentifier')));
const Information = Loadable(lazy(() => import('../../pages/product/details/information')));
const Notes = Loadable(lazy(() => import('../../components/notes2')));
const ProductNotification = Loadable(lazy(() => import('../../pages/product/details/notification')));
const Warehouse = Loadable(lazy(() => import('../../pages/product/details/warehouse')));
const Predictions = Loadable(lazy(() => import('../../pages/product/details/predictions')));
const Pricing = Loadable(lazy(() => import('../../pages/product/details/pricing')));
const Margin = Loadable(lazy(() => import('../../pages/product/details/margin')));
const Costs = Loadable(lazy(() => import('../../pages/product/details/costs')));
const CreditNote = Loadable(lazy(() => import('../../pages/product/details/creditNote')));
const CompanyIdentifier = Loadable(lazy(() => import('../../pages/product/details/companyIdentifier')));

export const ProductRoutes = [
    {
        path: 'product',
        children: [
            {
                path: 'list',
                element: <ProductsList />,
                children: [
                    { path: 'base', element: <ProductListBase /> },
                    { path: 'special-identifier', element: <SpecialIdentifierList /> }
                ]
            },
            {
                path: ':id/details',
                element: <ProductDetails />,
                children: [
                    { path: 'information', element: <Information /> },
                    { path: 'notes', element: <Notes model="product" /> },
                    { path: 'alerts', element: <ProductNotification /> },
                    { path: 'warehouse', element: <Warehouse /> },
                    { path: 'predictions', element: <Predictions /> },
                    { path: 'pricing', element: <Pricing /> },
                    { path: 'margin', element: <Margin /> },
                    { path: 'costs', element: <Costs /> },
                    { path: 'credit', element: <CreditNote /> },
                    { path: 'company-identifier', element: <CompanyIdentifier /> }
                ]
            },
            {
                path: 'add',
                element: <ProductAdd />
            },
            {
                path: ':id/edit',
                element: <ProductEdit />
            },
            {
                path: ':id/price/add',
                element: <PricingAdd />
            }
        ]
    }
];
