import { createSlice } from '@reduxjs/toolkit';
import { createInitialState } from '../../../utils/initialState';
import { createSubReducer } from '../../../utils/createReducer';
import {
    userAssignedCompanyList,
    userCompanyConnectionAdd,
    userCompanyConnectionChange,
    userCompanyConnectionDelete,
    userCompanyConnectionList,
    userCompanyDetails,
    userCompanyEdit,
    userCompanyImageAdd,
    userEdit,
    userImageAdd,
    userSetDetails
} from '../../../actions/user/actions';

const initialState = {
    details: createInitialState(),
    companyConnectionList: createInitialState({ loading: true, data: [] }),
    assignedCompanyList: createInitialState({ data: [] }),
    companyConnectionChange: createInitialState(),
    companyConnectionAdd: createInitialState(),
    companyConnectionDelete: createInitialState(),
    edit: createInitialState(),
    imageAdd: createInitialState(),
    companyImageAdd: createInitialState(),
    companyDetails: createInitialState({ loading: true }),
    companyEdit: createInitialState()
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        resetUserState: (state, action) => {
            const { key } = action.payload;
            state[key] = initialState[key];
        }
    },
    extraReducers: (builder) => {
        const actionsMap = {
            companyConnectionList: userCompanyConnectionList,
            assignedCompanyList: userAssignedCompanyList,
            companyConnectionChange: userCompanyConnectionChange,
            companyConnectionAdd: userCompanyConnectionAdd,
            companyConnectionDelete: userCompanyConnectionDelete,
            edit: userEdit,
            imageAdd: userImageAdd,
            companyImageAdd: userCompanyImageAdd,
            companyDetails: userCompanyDetails,
            companyEdit: userCompanyEdit
        };
        builder.addCase(userSetDetails, (state, action) => {
            state.details.data = action.payload;
        });

        Object.keys(actionsMap).forEach((key) => {
            createSubReducer(builder, actionsMap[key], key);
        });
    }
});

export const { resetUserState } = userSlice.actions;
export default userSlice.reducer;
