import {
    CITIES_LIST,
    COUNTRIES_LIST,
    HELP_CENTER_CHECK_URL,
    HELP_CENTER_DETAILS,
    HELP_CENTER_LIST,
    POST_CODE_LIST,
    SITE_ADD_TO_FAVOURITE,
    SITE_FAVOURITE_CHECK,
    SITE_RECENT_VIEW,
    SITE_ROUTES_FAVOURITES_LIST,
    SITE_ROUTES_LIST,
    SLUG_INFORMATION,
    STREET_LIST,
    SYSTEM_EXPORT_FILE_DOWNLOAD,
    SYSTEM_TASK_LIST,
    SYSTEM_TASK_REFRESH,
    SYSTEM_TEMPLATE_FILE_DOWNLOAD,
    SYSTEM_TEMPLATE_FILE_IMPORT,
    TOUR_SITE_INFO,
    TOUR_SITE_VIEW
} from '../../../actions/other/otherActionsType';
import { globalGetReducer, globalPostReducer } from '../../helpers';

export const countriesListReducer = globalGetReducer(COUNTRIES_LIST.START, COUNTRIES_LIST.SUCCESS, COUNTRIES_LIST.FAIL);
export const citiesListReducer = globalGetReducer(CITIES_LIST.START, CITIES_LIST.SUCCESS, CITIES_LIST.FAIL);
export const postCodeListReducer = globalGetReducer(POST_CODE_LIST.START, POST_CODE_LIST.SUCCESS, POST_CODE_LIST.FAIL);
export const streetListReducer = globalGetReducer(STREET_LIST.START, STREET_LIST.SUCCESS, STREET_LIST.FAIL);
export const systemTemplateFileDownloadReducer = globalGetReducer(
    SYSTEM_TEMPLATE_FILE_DOWNLOAD.START,
    SYSTEM_TEMPLATE_FILE_DOWNLOAD.SUCCESS,
    SYSTEM_TEMPLATE_FILE_DOWNLOAD.FAIL
);
export const systemTemplateFileImportReducer = globalPostReducer(
    SYSTEM_TEMPLATE_FILE_IMPORT.START,
    SYSTEM_TEMPLATE_FILE_IMPORT.SUCCESS,
    SYSTEM_TEMPLATE_FILE_IMPORT.FAIL,
    SYSTEM_TEMPLATE_FILE_IMPORT.RESET
);
export const systemExportFileDownloadReducer = globalPostReducer(
    SYSTEM_EXPORT_FILE_DOWNLOAD.START,
    SYSTEM_EXPORT_FILE_DOWNLOAD.SUCCESS,
    SYSTEM_EXPORT_FILE_DOWNLOAD.FAIL,
    SYSTEM_EXPORT_FILE_DOWNLOAD.RESET
);
export const siteRoutesListReducer = globalGetReducer(SITE_ROUTES_LIST.START, SITE_ROUTES_LIST.SUCCESS, SITE_ROUTES_LIST.FAIL);
export const siteRoutesFavouritesListReducer = globalGetReducer(
    SITE_ROUTES_FAVOURITES_LIST.START,
    SITE_ROUTES_FAVOURITES_LIST.SUCCESS,
    SITE_ROUTES_FAVOURITES_LIST.FAIL
);
export const siteRecentViewReducer = globalPostReducer(
    SITE_RECENT_VIEW.START,
    SITE_RECENT_VIEW.SUCCESS,
    SITE_RECENT_VIEW.FAIL,
    SITE_RECENT_VIEW.RESET
);
export const siteAddToFavouriteReducer = globalPostReducer(
    SITE_ADD_TO_FAVOURITE.START,
    SITE_ADD_TO_FAVOURITE.SUCCESS,
    SITE_ADD_TO_FAVOURITE.FAIL,
    SITE_ADD_TO_FAVOURITE.RESET
);
export const helpCenterListReducer = globalGetReducer(HELP_CENTER_LIST.START, HELP_CENTER_LIST.SUCCESS, HELP_CENTER_LIST.FAIL);
export const helpCenterDetailsReducer = globalGetReducer(HELP_CENTER_DETAILS.START, HELP_CENTER_DETAILS.SUCCESS, HELP_CENTER_DETAILS.FAIL);
export const slugInformationReducer = globalGetReducer(SLUG_INFORMATION.START, SLUG_INFORMATION.SUCCESS, SLUG_INFORMATION.FAIL);
export const tourSiteInfoReducer = globalGetReducer(TOUR_SITE_INFO.START, TOUR_SITE_INFO.SUCCESS, TOUR_SITE_INFO.FAIL);
export const tourSiteViewReducer = globalPostReducer(
    TOUR_SITE_VIEW.START,
    TOUR_SITE_VIEW.SUCCESS,
    TOUR_SITE_VIEW.FAIL,
    TOUR_SITE_VIEW.RESET
);
export const siteFavouriteCheckReducer = globalGetReducer(
    SITE_FAVOURITE_CHECK.START,
    SITE_FAVOURITE_CHECK.SUCCESS,
    SITE_FAVOURITE_CHECK.FAIL
);
export const helpCenterCheckUrlReducer = globalPostReducer(
    HELP_CENTER_CHECK_URL.START,
    HELP_CENTER_CHECK_URL.SUCCESS,
    HELP_CENTER_CHECK_URL.FAIL,
    HELP_CENTER_CHECK_URL.RESET
);
export const systemTaskListReducer = globalGetReducer(SYSTEM_TASK_LIST.START, SYSTEM_TASK_LIST.SUCCESS, SYSTEM_TASK_LIST.FAIL);
export const systemTaskRefreshReducer = globalPostReducer(
    SYSTEM_TASK_REFRESH.START,
    SYSTEM_TASK_REFRESH.SUCCESS,
    SYSTEM_TASK_REFRESH.FAIL,
    SYSTEM_TASK_REFRESH.RESET
);
