import { createThunkAction } from '../../thunks/actions';

export const statisticsAdd = createThunkAction('statistics/add', 'statistics/dashboard/add/', 'post');
export const statisticsDashboardDefault = createThunkAction('statistics/dashboard/default', 'statistics/dashboard/default/');
export const statisticsGenerate = createThunkAction(
    'statistics/generate',
    (identifier) => `statistics/dashboard/detail/${identifier}/generate/`,
    'get'
);
export const statisticsSetDashboardDefault = createThunkAction('statistics/setDashboardDefault', 'statistics/dashboard/default/', 'post');
export const statisticsDashboardOptions = createThunkAction(
    'statistics/dashboard/options',
    'system/simple-serializer/statistics-dashboards/'
);
export const statisticsDashboardSessionsList = createThunkAction(
    'statistics/dashboard/sessionsList',
    (identifier) => `statistics/dashboard/detail/${identifier}/sessions/`
);
export const statisticsSelloutAllowanceCalculateDetails = createThunkAction(
    'statistics/selloutAllowanceCalculateDetails',
    (identifier) => `statistics/product/${identifier}/sellout-allowance/calculate/`
);
export const statisticsWeeksOnHandDetails = createThunkAction(
    'statistics/weeksOnHandDetails',
    (identifier) => `statistics/product/${identifier}/weeks-on-hand/calculate/`
);
export const selloutSumCalculate = createThunkAction(
    'sellout/sumCalculate',
    (identifier) => `statistics/product/${identifier}/sellout-sum/calculate/`,
    'get'
);

export const countDelivery = createThunkAction('statistics/countDelivery', 'statistics/dashboard/data/count_of_delivery/');
export const countSelloutValue = createThunkAction(
    'statistics/countSelloutValue',
    'statistics/dashboard/data/count_overall_sellout_value/'
);
export const countSelloutAmount = createThunkAction(
    'statistics/countSelloutAmount',
    'statistics/dashboard/data/count_overall_sellout_amount/'
);
export const chartDeliveriesCompanies = createThunkAction(
    'statistics/chartDeliveriesCompanies',
    'statistics/dashboard/charts/deliveries_to_companies/'
);
export const averageSelloutAllowance = createThunkAction(
    'statistics/averageSelloutAllowance',
    'statistics/dashboard/data/average_sellout_allowance/?class=overview'
);
export const selloutValue = createThunkAction(
    'statistics/selloutValue',
    'statistics/dashboard/data/gross_profit_to_last_quarter/?class=overview'
);
export const sellAmount = createThunkAction('statistics/sellAmount', 'statistics/dashboard/data/amount_with_last_quarter/?class=overview');
export const chartSellValueReport = createThunkAction(
    'statistics/chartSellValueReport',
    'statistics/dashboard/charts/sell_value_from_raports/'
);
export const chartCategoryReport = createThunkAction('statistics/chartCategoryReport', 'statistics/dashboard/charts/category_in_raports/');
export const chartAmountReport = createThunkAction('statistics/chartAmountReport', 'statistics/dashboard/charts/amount_from_raports/');
export const sellValueRaportCompanies = createThunkAction(
    'statistics/sellValueRaportCompanies',
    'statistics/dashboard/charts/sell_value_from_raports_to_companies/'
);

export const statisticsReportAbcd = createThunkAction('statistics/reportAbcd', 'statistics/raports/abcd/', 'post', false);
export const statisticsCalculateXox = createThunkAction(
    'statistics/calculateXox',
    (type) => `statistics/dashboard/data/calculate_xox/?class=overview&over_type=${type}`
);
export const statisticsCalculateWow = createThunkAction(
    'statistics/calculateWow',
    'statistics/dashboard/data/calculate_xox/?class=overview&over_type=wow'
);
export const statisticsCalculateMom = createThunkAction(
    'statistics/calculateMom',
    'statistics/dashboard/data/calculate_xox/?class=overview&over_type=mom'
);
export const statisticsCalculateQoq = createThunkAction(
    'statistics/calculateQoq',
    'statistics/dashboard/data/calculate_xox/?class=overview&over_type=qoq'
);
export const statisticsCalculateYoy = createThunkAction(
    'statistics/calculateYoy',
    'statistics/dashboard/data/calculate_xox/?class=overview&over_type=yoy'
);
export const statisticsCalculateStockOnHand = createThunkAction(
    'statistics/calculateStockOnHand',
    'statistics/dashboard/data/calculate_stock_on_hand/?class=overview'
);
export const statisticsCalculateAmountSell = createThunkAction(
    'statistics/calculateAmountSell',
    'statistics/dashboard/data/calculate_amount_sell/?class=overview'
);
export const soaAdd = createThunkAction('statistics/soaAdd', 'statistics/soa/add/', 'post');
export const statisticsBestPerformSku = createThunkAction(
    'statistics/bestPerformSku',
    'statistics/dashboard/data/best_performing_sku_statistics/?class=overview'
);
export const statisticsDashboardTableList = createThunkAction('statistics/dashboard/table/list', 'statistics/dashboard/table/list/');
export const statisticsDashboardTableAdd = createThunkAction('statistics/dashboard/table/add', 'statistics/dashboard/table/add/', 'post');
export const statisticsDashboardTableDetails = createThunkAction(
    'statistics/dashboard/table/details',
    (id) => `statistics/dashboard/table/detail/${id}/`
);
export const statisticsDashboardTableDelete = createThunkAction(
    'statistics/dashboard/table/delete',
    'statistics/dashboard/table/delete/',
    'delete'
);
export const statisticsDashboardTableGenerate = createThunkAction(
    'statistics/dashboard/table/generate',
    (id) => `statistics/dashboard/table/detail/${id}/generate/`,
    'post'
);
export const statisticsDashboardTableEdit = createThunkAction(
    'statistics/dashboard/table/edit',
    (id) => `statistics/dashboard/table/detail/${id}/update/`,
    'put'
);
