import { createThunkAction, createThunkFileAction } from '../../thunks/actions';

export const salesAlertList = createThunkAction('sales/alert/list', 'api/sales/raport-alert/list/', 'get', false);
export const salesAlertAdd = createThunkAction('sales/alert/add', 'sales/raport-alert/add/', 'post');
export const salesAlertDetails = createThunkAction('sales/alert/details', (id) => `sales/raport-alert/detail/${id}/`);
export const salesAlertEdit = createThunkAction('sales/alert/edit', (id) => `sales/raport-alert/detail/${id}/update/`, 'put');
export const salesAlertDelete = createThunkAction('sales/alert/delete', 'sales/raport-alert/delete/', 'delete');
export const salesAlertReportDetails = createThunkAction('sales/alert/report/details', (id) => `sales/raport-alert/check/${id}/`);
export const salesAlertReportList = createThunkAction('sales/alert/report/list', 'sales/raport-alert/check-multiple/');
export const salesSpikesList = createThunkAction('sales/spikes/list', 'statistics/dashboard/data/sales_spikes/?class=overview');
export const salesReportOptions = createThunkAction('sales/report/options', 'system/simple-serializer/sales-raport_type/');
export const salesColumnOptions = createThunkAction(
    'sales/column/options',
    (type) => `system/simple-serializer/sales-raports-columns/?raport_fk__raport_type=${type}`
);
export const salesReportList = createThunkAction('sales/report/list', 'api/sales/raports/list', 'get', false);
export const salesReportOptions2 = createThunkAction('sales/report/options2', 'system/simple-serializer/sales-raports/');
export const salesReportDelete = createThunkAction('sales/report/delete', 'sales/raport/delete/', 'delete');
export const salesReportColumnsList = createThunkAction(
    'sales/report/columns/list',
    'system/simple-serializer/sales-raports-columns/?raport_fk__isnull=0'
);
export const salesReportAdd = createThunkAction('sales/report/add', 'sales/raport/add/', 'post');
export const salesReportDetails = createThunkAction('sales/report/details', (identifier) => `sales/raport/detail/${identifier}/`);
export const salesReportImport = createThunkFileAction('sales/report/import', 'sales/raport/add/', 'post');
export const salesReportTemplateList = createThunkAction('sales/report/template/list', 'api/sales/raports/template/list', 'get', false);
export const salesReportTemplateDelete = createThunkAction('sales/report/template/delete', 'sales/raport/template/delete/', 'delete');
export const salesReportColumnsOptions = createThunkAction(
    'sales/report/columns/options',
    'system/simple-serializer/sales-raports-columns/?raport_fk__isnull=1'
);
export const salesReportTemplateAdd = createThunkAction('sales/report/template/add', 'sales/raport/template/add/', 'post');
export const salesReportTemplateDetails = createThunkAction(
    'sales/report/template/details',
    (identifier) => `sales/raport/template/detail/${identifier}/`
);
export const salesReportRowEdit = createThunkAction(
    'sales/report/row/edit',
    (identifier) => `sales/raport/rows/detail/${identifier}/update/`,
    'put'
);
export const salesReportTemplateEdit = createThunkAction(
    'sales/report/template/edit',
    (identifier) => `sales/raport/template/detail/${identifier}/update/`,
    'put'
);
export const salesReportSimpleList = createThunkAction('sales/report/simple/list', 'system/simple-serializer/sales-raports/');
export const salesWohDetails = createThunkAction('sales/woh/details', 'sales/weeks-on-hand/options/');
export const salesWohConfig = createThunkAction('sales/woh/config', 'sales/weeks-on-hand/options/', 'post');
export const salesSelloutConfig = createThunkAction('sales/sellout/config', 'sales/sellout/options/', 'post');
export const salesSelloutDetails = createThunkAction('sales/sellout/details', 'sales/sellout/options/');
export const salesReportDateOptions = createThunkAction('sales/report/date/options', 'sales/raport/date-values/', 'post', true, false);
export const salesReportCompare = createThunkAction('sales/report/compare', 'sales/raport/compare/', 'post');
