import { requestBlobDjango, requestDjango, requestExpress, requestMultipartDjango, requestWithoutToken } from '.';
import { fetchFail, fetchStart, fetchSuccess } from '../actions';
import { saveAs } from 'file-saver';
import { showNotification } from '../../utils/notification';

export const thunkActionDjango = (endpoint, types, method = 'get', data = null, params, isShowNotification = true) => {
    return (dispatch) => {
        dispatch(fetchStart(types));

        requestDjango(endpoint, method, data, params)
            .then((response) => {
                dispatch(fetchSuccess(types, response.data));
                if ((method === 'post' || method === 'put' || method === 'delete') && isShowNotification) {
                    const { is_alert_show, message, status } = response.data.meta.alert;
                    if (is_alert_show) {
                        showNotification(message, status);
                    }
                }
            })
            .catch((error) => {
                dispatch(fetchFail(types, error));

                if (error.response && error.response.data && error.response.data.meta && error.response.data.meta.alert) {
                    const { is_alert_show, message, status } = error.response.data.meta.alert;
                    if (is_alert_show) {
                        showNotification(message, status);
                    }
                } else {
                    console.error('Brak danych w odpowiedzi błędu:', error);
                }
            });
    };
};

export const thunkFileActionDjango = (endpoint, types, method = 'get', data = null, params) => {
    return (dispatch) => {
        dispatch(fetchStart(types));

        requestMultipartDjango(endpoint, method, data, params)
            .then((response) => {
                dispatch(fetchSuccess(types, response.data));
                if (method === 'post' || method === 'put' || method === 'delete') {
                    const { is_alert_show, message, status } = response.data.meta.alert;
                    if (is_alert_show) {
                        showNotification(message, status);
                    }
                }
            })
            .catch((error) => {
                dispatch(fetchFail(types, error));
                const { is_alert_show, message, status } = error.response.data.meta.alert;
                if (is_alert_show) {
                    showNotification(message, status);
                }
            });
    };
};

export const thunkFileDownloadActionDjango = (endpoint, types, method = 'get', data = null, fileName, params) => {
    return (dispatch) => {
        dispatch(fetchStart(types));

        requestBlobDjango(endpoint, method, data, params)
            .then((response) => {
                dispatch(fetchSuccess(types, response.data));

                const blob = new Blob([response.data], { type: response.headers['content-type'] });
                saveAs(blob, fileName);
            })
            .catch((error) => {
                dispatch(fetchFail(types, error));
                const { is_alert_show, message, status } = error.data.meta.alert;
                if (is_alert_show) {
                    showNotification(message, status);
                }
            });
    };
};

export const thunkActionExpress = (endpoint, types, method = 'get', data = null, params) => {
    return (dispatch) => {
        dispatch(fetchStart(types));

        requestExpress(endpoint, method, data, params)
            .then((response) => {
                dispatch(fetchSuccess(types, response.data));
            })
            .catch((error) => {
                dispatch(fetchFail(types, error));
            });
    };
};

export const thunkActionNoToken = (endpoint, types, method = 'get', data = null, params) => {
    return (dispatch) => {
        dispatch(fetchStart(types));

        requestWithoutToken(endpoint, method, data, params)
            .then((response) => {
                dispatch(fetchSuccess(types, response.data));
                if (method === 'post' || method === 'put' || method === 'delete') {
                    const { is_alert_show, message, status } = response.data.meta.alert;
                    if (is_alert_show) {
                        showNotification(message, status);
                    }
                }
            })
            .catch((error) => {
                dispatch(fetchFail(types, error));
            });
    };
};
