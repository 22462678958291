import { lazy } from 'react';
import Loadable from '../../components/Loadable';

const StatusList = Loadable(lazy(() => import('../../pages/status/list')));

export const StatusRoutes = [
    {
        path: 'status',
        children: [
            {
                path: '',
                element: <StatusList />
            }
        ]
    }
];
