import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import InputLabel from '@mui/material/InputLabel';
import { Stack, FormHelperText, useTheme, alpha } from '@mui/material';
import CreatableSelect from 'react-select/creatable';

const MultipleCreatableSelect = ({
    components,
    disabled,
    isLoading = false,
    isClearable = true,
    isSearchable = true,
    label,
    name,
    options = [],
    handleChange
}) => {
    const [focused, setFocused] = useState(false);
    const [field, meta, helpers] = useField(name);
    const { value } = field;
    const { touched, error } = meta;
    const { setValue, setTouched } = helpers;

    const theme = useTheme();
    const mode = theme.palette.mode;

    const styles = {
        valueContainer: (base) => ({
            ...base,
            minHeight: 39
        }),
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        menuList: (base) => ({
            ...base,
            background: mode === 'light' ? 'white' : 'black',
            backgroundImage: mode === 'dark' && 'linear-gradient(rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15))'
        }),
        control: (base, state) => ({
            ...base,
            backgroundColor: 'transparent',
            borderColor: touched && error?.value ? 'red' : mode === 'dark' ? theme.palette.grey[600] : theme.palette.grey[300],
            '&:hover': {
                borderColor: state.isFocused
                    ? touched && error
                        ? 'red'
                        : theme.palette.primary.light
                    : touched && error
                      ? 'red'
                      : theme.palette.grey[300]
            },
            boxShadow: state.isFocused ? `0 0 0 2px ${alpha(theme.palette.primary.main, 0.2)}` : 'none'
        }),
        option: (base, { isFocused, isSelected }) => {
            return {
                ...base,
                backgroundColor: isFocused
                    ? mode === 'dark'
                        ? 'rgba(255, 255, 255, 0.08)'
                        : theme.palette.grey[100]
                    : isSelected
                      ? mode === 'dark'
                          ? 'rgba(255, 255, 255, 0.16)'
                          : theme.palette.primary.lighter
                      : null,
                color: 'inherit'
            };
        }
    };

    const handleChangeEvent = (selectedOption) => {
        setValue(selectedOption || []);
        if (handleChange) handleChange(selectedOption || []);
    };

    const handleFocus = () => setFocused(true);
    const handleBlur = () => {
        setFocused(false);
        setTouched(true);
    };

    return (
        <Stack spacing={1} sx={{ width: '100%' }}>
            <InputLabel htmlFor={`${name}-creatable-select`} error={Boolean(touched && error)} sx={{ color: focused && 'primary.darker' }}>
                {label}
            </InputLabel>
            <CreatableSelect
                isMulti
                id={`${name}-creatable-select`}
                aria-label={`${name}-creatable-multiple-select`}
                className={`${name}-creatable-multiple-select`}
                components={components}
                isClearable={isClearable}
                isDisabled={disabled}
                isLoading={isLoading}
                isSearchable={isSearchable}
                menuPortalTarget={document.body}
                name={name}
                onBlur={handleBlur}
                onChange={handleChangeEvent}
                onFocus={handleFocus}
                options={options}
                styles={styles}
                value={value}
            />
            {touched && error && (
                <FormHelperText error id={`${name}-error`}>
                    {error}
                </FormHelperText>
            )}
        </Stack>
    );
};

MultipleCreatableSelect.propTypes = {
    components: PropTypes.any,
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    isClearable: PropTypes.bool,
    isSearchable: PropTypes.bool,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    handleChange: PropTypes.func
};

export default MultipleCreatableSelect;
