import { combineReducers } from 'redux';
import {
    chatRoomAddReducer,
    chatRoomDetailsReducer,
    chatRoomFileAddReducer,
    chatRoomFileDeleteReducer,
    chatRoomFileDownloadReducer,
    chatRoomFileListReducer,
    chatRoomListReducer,
    chatRoomMessagesListReducer,
    chatUsersListReducer
} from './chatReducers';

const chatReducer = combineReducers({
    chatUsersList: chatUsersListReducer,
    chatRoomList: chatRoomListReducer,
    chatRoomMessagesList: chatRoomMessagesListReducer,
    chatRoomDetails: chatRoomDetailsReducer,
    chatRoomAdd: chatRoomAddReducer,
    chatRoomFileAdd: chatRoomFileAddReducer,
    chatRoomFileList: chatRoomFileListReducer,
    chatRoomFileDownload: chatRoomFileDownloadReducer,
    chatRoomFileDelete: chatRoomFileDeleteReducer
});

export default chatReducer;
