import {
    IconAccessPoint,
    IconAnalyze,
    // IconBuildingStore,
    IconCoins,
    IconDashboard,
    IconLoader,
    IconMessageCircle,
    IconNotification,
    IconPackage,
    IconReportAnalytics,
    IconStack2,
    IconTableColumn,
    IconTarget,
    IconTimeline,
    IconTruckDelivery
} from '@tabler/icons-react';

const dashboard = {
    id: 'group-dashboard',
    title: 'Navigation',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard/overview',
            icon: IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'product',
            model: 'product',
            title: 'Products',
            tour: 'step-5',
            type: 'breadcrumbs',
            url: '/product/list/base',
            icon: IconStack2,
            breadcrumbs: true,
            children: [
                {
                    id: 'product-add',
                    title: 'Add product',
                    type: 'item',
                    url: `/product/add`,
                    breadcrumbs: true
                },
                {
                    id: 'product-edit',
                    title: 'Edit product',
                    type: 'item',
                    url: `/product/{{identifier}}/edit`,
                    breadcrumbs: true
                }
            ]
        },
        {
            id: 'product',
            model: 'product',
            title: 'Product special identifier list',
            type: 'breadcrumbs',
            url: '/product/list/special-identifier',
            breadcrumbs: true,
            hide: true
        },
        {
            id: 'delivery',
            title: 'Delivery',
            type: 'breadcrumbs',
            url: '/delivery/list/base',
            icon: IconTruckDelivery,
            breadcrumbs: true,
            tour: 'step-6'
        },
        {
            id: 'delivery',
            title: 'Company delivery',
            type: 'breadcrumbs',
            url: '/delivery/list/company',
            breadcrumbs: true,
            hide: true
        },
        {
            id: 'delivery',
            title: 'Order delivery',
            type: 'breadcrumbs',
            url: '/delivery/list/order',
            breadcrumbs: true,
            hide: true
        },
        {
            id: 'process-list',
            title: 'Account settings',
            type: 'breadcrumbs',
            url: '/account/settings/',
            breadcrumbs: true,
            hide: true,
            children: [
                {
                    id: 'personal-information',
                    title: 'Personal information',
                    type: 'item',
                    url: `/account/settings/profile`,
                    breadcrumbs: true
                },
                {
                    id: 'security',
                    title: 'Security',
                    type: 'item',
                    url: `/account/settings/security`,
                    breadcrumbs: true
                },
                {
                    id: 'notification',
                    title: 'Notification',
                    type: 'item',
                    url: `/account/settings/notification`,
                    breadcrumbs: true
                },
                {
                    id: 'notification',
                    title: 'Billing',
                    type: 'item',
                    url: `/account/settings/billing`,
                    breadcrumbs: true
                },
                {
                    id: 'license',
                    title: 'License',
                    type: 'item',
                    url: `/account/settings/license`,
                    breadcrumbs: true
                },
                {
                    id: 'sales',
                    title: 'Sales',
                    type: 'item',
                    url: `/account/settings/sales`,
                    breadcrumbs: true
                },
                {
                    id: 'organization-information',
                    title: 'Organization information',
                    type: 'item',
                    url: `/account/settings/information`,
                    breadcrumbs: true
                },
                {
                    id: 'connections',
                    title: 'Connections',
                    type: 'item',
                    url: `/account/settings/connections`,
                    breadcrumbs: true
                }
            ]
        },
        {
            id: 'status',
            title: 'Status',
            type: 'breadcrumbs',
            url: '/status?model=warehouse_delivery',
            icon: IconAccessPoint,
            breadcrumbs: true,
            tour: 'step-7'
        },
        {
            id: 'chat',
            title: 'Chat',
            type: 'breadcrumbs',
            url: '/chat?type=all',
            icon: IconMessageCircle,
            breadcrumbs: true,
            tour: 'step-8'
        },
        {
            id: 'statistics',
            title: 'Statistics',
            type: 'breadcrumbs',
            url: '/statistics/overview',
            icon: IconAnalyze,
            breadcrumbs: true,
            tour: 'step-9'
        },
        {
            id: 'statistics',
            title: 'Profit analysis',
            type: 'breadcrumbs',
            url: '/statistics/profit-analysis',
            breadcrumbs: true,
            hide: true
        },
        {
            id: 'statistics',
            title: 'Create own tables',
            type: 'breadcrumbs',
            url: '/statistics/dashboard',
            breadcrumbs: true,
            hide: true
        },
        {
            id: 'sales',
            title: 'Sales',
            type: 'breadcrumbs',
            url: '/sales/list/report',
            icon: IconReportAnalytics,
            breadcrumbs: true,
            tour: 'step-10'
        },
        {
            id: 'targets',
            title: 'Targets',
            type: 'breadcrumbs',
            url: '/targets',
            icon: IconTarget,
            breadcrumbs: true,
            tour: 'step-11'
        },
        {
            id: 'costs',
            title: 'Costs',
            type: 'breadcrumbs',
            url: '/costs',
            icon: IconCoins,
            breadcrumbs: true,
            tour: 'step-12'
        },
        {
            id: 'orders',
            title: 'Orders',
            type: 'breadcrumbs',
            url: '/order',
            icon: IconPackage,
            breadcrumbs: true,
            tour: 'step-13'
        },
        {
            id: 'sales',
            title: 'Sales templates',
            type: 'breadcrumbs',
            url: '/sales/list/template',
            breadcrumbs: true,
            hide: true
        },
        {
            id: 'sales',
            title: 'Sales alert',
            type: 'breadcrumbs',
            url: '/sales/list/alert',
            breadcrumbs: true,
            hide: true
        },
        {
            id: 'sales',
            title: 'Sales compare reports',
            type: 'breadcrumbs',
            url: '/sales/list/compare-report',
            breadcrumbs: true,
            hide: true
        },
        {
            id: 'help-center',
            title: 'Help center',
            type: 'breadcrumbs',
            url: '/help-center',
            icon: IconReportAnalytics,
            breadcrumbs: true,
            hide: true
        },
        {
            id: 'forecast',
            title: 'Forecast',
            type: 'breadcrumbs',
            url: '/forecast',
            icon: IconTimeline,
            breadcrumbs: true,
            tour: 'step-14'
        },
        {
            id: 'soa-overview',
            title: 'SOA',
            type: 'breadcrumbs',
            url: '/soa/overview/list',
            icon: IconLoader,
            breadcrumbs: true
        },
        // {
        //     id: 'market-watcher',
        //     title: 'Market watcher',
        //     type: 'breadcrumbs',
        //     url: '/market-watcher/list/product',
        //     icon: IconBuildingStore,
        //     breadcrumbs: true
        // },
        {
            id: 'sales-alert',
            title: 'Sales alerts',
            type: 'breadcrumbs',
            url: '/sales/list/alerts',
            icon: IconNotification,
            breadcrumbs: true,
            hide: true
        },
        {
            id: 'cole',
            title: 'Custom reports',
            type: 'breadcrumbs',
            url: '/cole/list/template',
            icon: IconTableColumn,
            breadcrumbs: true
        },
        {
            id: 'cole',
            title: 'Imported reports',
            type: 'breadcrumbs',
            url: '/cole/list/dynamic',
            breadcrumbs: true,
            hide: true
        }
    ]
};

export default dashboard;
