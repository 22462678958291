import { combineReducers } from 'redux';
import { licenseAddUserReducer, licenseCurrentDetailsReducer, licenseListReducer } from './licenseReducers';

const licenseReducer = combineReducers({
    licenseList: licenseListReducer,
    licenseAddUser: licenseAddUserReducer,
    currentDetails: licenseCurrentDetailsReducer
});

export default licenseReducer;
