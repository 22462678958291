import { lazy } from 'react';
import Loadable from '../../components/Loadable';
import DynamicUrlList from '../../pages/dynamicUrl/list';

const DynamicUrlAdd = Loadable(lazy(() => import('../../pages/dynamicUrl/add')));

export const DynamicUrlRoutes = [
    {
        path: 'dynamic-url',
        children: [
            {
                path: 'add',
                element: <DynamicUrlAdd />
            },
            {
                path: 'list',
                element: <DynamicUrlList />
            }
        ]
    }
];
