import { lazy } from 'react';
import Loadable from '../../components/Loadable';

const ForecastList = Loadable(lazy(() => import('../../pages/forecast/list')));

export const ForecastRoutes = [
    {
        path: 'forecast',
        children: [
            {
                path: '',
                element: <ForecastList />
            }
        ]
    }
];
