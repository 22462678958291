import { AccountRoutes } from './AccountRoutes';
import { DashboardRoutes } from './DashboardRoutes';
import { ProductRoutes } from './ProductRoutes';
import { DeliveryRoutes } from './DeliveryRoutes';
import { StatusRoutes } from './StatusRoutes';
import { ChatRoutes } from './ChatRoutes';
import { SalesRoutes } from './SalesRoutes';
import { CompanyRoutes } from './CompanyRoutes';
import { NotificationRoutes } from './NotificationRoutes';
import { TargetsRoutes } from './TargetsRoutes';
import { CostsRoutes } from './CostsRoutes';
import { OrderRoutes } from './OrderRoutes';
import { AuthGuard } from '../guard';
import MainLayout from '../../layout/MainLayout';
import { NavMotion } from '../../components/NavMotion';
import HelpCenter from '../../pages/static/HelpCenter';
import { ForecastRoutes } from './ForecastRoutes';
import { StatisticsRoutes } from './StatisticsRoutes';
import { SoaRoutes } from './SoaRoutes';
import { ColeRoutes } from './ColeRoutes';
import { MarketWatcherRoutes } from './MarketWatcherRoutes';
import { DynamicUrlRoutes } from './DynamicUrlRoutes';

const PrivateRoutes = {
    element: (
        <NavMotion>
            <AuthGuard>
                <MainLayout />
            </AuthGuard>
        </NavMotion>
    ),
    children: [
        ...DashboardRoutes,
        ...AccountRoutes,
        ...ProductRoutes,
        ...DeliveryRoutes,
        ...StatusRoutes,
        ...ChatRoutes,
        ...StatisticsRoutes,
        ...SoaRoutes,
        ...SalesRoutes,
        ...CompanyRoutes,
        ...NotificationRoutes,
        ...TargetsRoutes,
        ...CostsRoutes,
        ...OrderRoutes,
        ...ForecastRoutes,
        ...ColeRoutes,
        ...MarketWatcherRoutes,
        ...DynamicUrlRoutes,
        {
            path: 'help-center',
            element: <HelpCenter />
        },
        {
            path: '*',
            element: 'Not found'
        }
    ]
};

export default PrivateRoutes;
