import { createSlice } from '@reduxjs/toolkit';
import { createInitialState } from '../../../utils/initialState';
import { createSubReducer } from '../../../utils/createReducer';
import { forecastList, forecastOptionsList } from '../../../actions/forecast/actions';

const initialState = {
    list: createInitialState({ data: [] }),
    options: createInitialState({ data: [] })
};

const forecastSlice = createSlice({
    name: 'forecast',
    initialState,
    reducers: {
        resetForecastState: (state, action) => {
            const { key } = action.payload;
            state[key] = initialState[key];
        }
    },
    extraReducers: (builder) => {
        const actionsMap = {
            list: forecastList,
            options: forecastOptionsList
        };

        Object.keys(actionsMap).forEach((key) => {
            createSubReducer(builder, actionsMap[key], key);
        });
    }
});

export const { resetForecastState } = forecastSlice.actions;
export default forecastSlice.reducer;
