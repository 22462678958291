import {
    CHAT_ROOM_ADD,
    CHAT_ROOM_DETAILS,
    CHAT_ROOM_FILE_ADD,
    CHAT_ROOM_FILE_DELETE,
    CHAT_ROOM_FILE_DOWNLOAD,
    CHAT_ROOM_FILE_LIST,
    CHAT_ROOM_LIST,
    CHAT_ROOM_MESSAGES_LIST,
    CHAT_USERS_LIST
} from '../../../actions/chat/chatActionsType';
import { globalGetReducer, globalPostReducer } from '../../helpers';

export const chatUsersListReducer = globalGetReducer(CHAT_USERS_LIST.START, CHAT_USERS_LIST.SUCCESS, CHAT_USERS_LIST.FAIL);
export const chatRoomListReducer = globalGetReducer(CHAT_ROOM_LIST.START, CHAT_ROOM_LIST.SUCCESS, CHAT_ROOM_LIST.FAIL);
export const chatRoomMessagesListReducer = globalGetReducer(
    CHAT_ROOM_MESSAGES_LIST.START,
    CHAT_ROOM_MESSAGES_LIST.SUCCESS,
    CHAT_ROOM_MESSAGES_LIST.FAIL
);
export const chatRoomDetailsReducer = globalGetReducer(CHAT_ROOM_DETAILS.START, CHAT_ROOM_DETAILS.SUCCESS, CHAT_ROOM_DETAILS.FAIL);
export const chatRoomAddReducer = globalPostReducer(CHAT_ROOM_ADD.START, CHAT_ROOM_ADD.SUCCESS, CHAT_ROOM_ADD.FAIL, CHAT_ROOM_ADD.RESET);
export const chatRoomFileAddReducer = globalPostReducer(
    CHAT_ROOM_FILE_ADD.START,
    CHAT_ROOM_FILE_ADD.SUCCESS,
    CHAT_ROOM_FILE_ADD.FAIL,
    CHAT_ROOM_FILE_ADD.RESET
);
export const chatRoomFileListReducer = globalPostReducer(
    CHAT_ROOM_FILE_LIST.START,
    CHAT_ROOM_FILE_LIST.SUCCESS,
    CHAT_ROOM_FILE_LIST.FAIL,
    CHAT_ROOM_FILE_LIST.RESET
);
export const chatRoomFileDownloadReducer = globalGetReducer(
    CHAT_ROOM_FILE_DOWNLOAD.START,
    CHAT_ROOM_FILE_DOWNLOAD.SUCCESS,
    CHAT_ROOM_FILE_DOWNLOAD.FAIL
);
export const chatRoomFileDeleteReducer = globalPostReducer(
    CHAT_ROOM_FILE_DELETE.START,
    CHAT_ROOM_FILE_DELETE.SUCCESS,
    CHAT_ROOM_FILE_DELETE.FAIL,
    CHAT_ROOM_FILE_DELETE.RESET
);
