import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Palette from './palette';
import Typography from './typography';
import CustomShadows from './shadows';
import componentsOverride from './overrides';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { SET_THEME_CUSTOMIZATION } from '../services/actions/customization/customizationType';

export default function ThemeCustomization({ children }) {
    const customizationFromState = useSelector((state) => state.customization);
    const customizationFromLocalStorage = JSON.parse(localStorage.getItem('customization'));

    const dispatch = useDispatch();

    useEffect(() => {
        const customizationFromLocalStorage = JSON.parse(localStorage.getItem('customization'));
        if (customizationFromLocalStorage) {
            dispatch({ type: SET_THEME_CUSTOMIZATION, ...customizationFromLocalStorage });
        }
    }, [dispatch]);

    const customization = customizationFromLocalStorage || customizationFromState;

    const { mode, direction, drawer, fontFamily } = customization;

    const theme = Palette(mode, 'default');

    const themeTypography = Typography(fontFamily);
    const themeCustomShadows = useMemo(() => CustomShadows(theme), [theme]);

    const themeOptions = useMemo(
        () => ({
            breakpoints: {
                values: {
                    xs: 0,
                    sm: 768,
                    md: 1024,
                    lg: 1266,
                    xl: 1536
                }
            },
            direction: direction,
            mixins: {
                toolbar: {
                    minHeight: 60,
                    paddingTop: 8,
                    paddingBottom: 8
                }
            },
            palette: theme.palette,
            drawer: drawer,
            customShadows: themeCustomShadows,
            typography: themeTypography
        }),
        [direction, drawer, theme.palette, themeCustomShadows, themeTypography]
    );

    const themes = createTheme(themeOptions);
    themes.components = componentsOverride(themes);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes}>
                <CssBaseline />
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

ThemeCustomization.propTypes = {
    children: PropTypes.node
};
